// Dependencies
import React, { FC, useMemo } from 'react';
import { useTheme, useMediaQuery } from '@mui/material';

import { MobileHeader } from './Content/mobile';
import DesktopHeader from './Content/desktop';
// Interfaces
interface IHeaderProps {
  isCollapsed: boolean;
  setCollapse: (isCollapsed: boolean) => void;
}

const Header: FC<IHeaderProps> = ({ isCollapsed, setCollapse }) => {
  // States
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const header = useMemo(() => {
    if (isTablet) return <MobileHeader setCollapse={setCollapse} />;
    return <DesktopHeader isMobile={isTablet} isCollapsed={isCollapsed} setCollapse={setCollapse} />;
  }, [isTablet]);

  return <>{header}</>;
};

export default Header;
