import React, { FC, Fragment, useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CardHeader, CircularProgress, Typography } from '@mui/material';
import { IArtist } from '../../../shared/types';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowLeftIcon, PencilIcon } from '../../../assets/icons';

import * as S from './styles';
import { ArtistDetailProfilePage } from './Profile';
import { ArtistDetailGalleryPage } from './Gallery';
import { ROUTES } from '../../../constants';
import { ArtistApi } from '../../../apis/index';

export const ArtistDetailPage: FC = () => {
  //useState
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [artist, setArtist] = useState<IArtist>();
  const [isContractCreated, setContractCreated] = useState<boolean>(false);


  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const fetchArtistDetails = () => {
    if (!id) return;

    setIsLoading(true);
    ArtistApi.read(id)
      .then((res) => {
        setArtist(res.artist);
        setIsLoading(false);
      })
      .catch((err) => console.log(err))
      .finally();
  };
  //useEffect
  useEffect(() => {
    fetchArtistDetails();
  }, []);
  const handleClickBackBtn = () => {
    navigate(-1);
  };
  const handleClickEdit = () => {
    navigate(ROUTES.ARTIST.EDIT.replace(':id', `${id}`));
  };

  const contractExecuted = () => {
    setContractCreated(!isContractCreated);
  };



  return (
    <>
      <Card sx={{ mb: 8 }}>
        {
          id && isLoading ? (
            <Box height={300} display="flex" alignItems="center" justifyContent="center">
              <CircularProgress />
            </Box>
          ) :
            (artist &&
              <Fragment>
                <CardHeader
                  title={
                    <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleClickBackBtn}>
                      Back
                    </Button>
                  }
                  action={
                    <Button
                      variant="contained"
                      type="submit"
                      startIcon={<PencilIcon />}
                      onClick={handleClickEdit}
                    >
                      Edit artist profile
                    </Button>
                  }
                  sx={{ mb: 24 }}
                />
                <Typography mb={72} color="text.secondary" variant="title">
                  Artist info
                </Typography>
                <S.Profile>
                  <Avatar
                    src={`${artist?.thumbnail && artist?.thumbnail}`}
                    sx={{
                      width: 200,
                      height: 200,
                    }}
                  />
                  <Typography mt={20} variant="headline">
                    {artist?.name}
                  </Typography>
                  <Typography mt={34} color="success.main" variant="label">
                    {artist?.email}
                  </Typography>
                  <S.ProfileValue>
                    <S.ProfileValueContent>
                      <Typography color="text.secondary" variant="overline">
                        NTFs
                      </Typography>
                      <Typography mt={4} variant="title">
                        24
                      </Typography>
                    </S.ProfileValueContent>
                    <S.ProfileValueContent>
                      <Typography color="text.secondary" variant="overline">
                        SELLS
                      </Typography>
                      <Typography mt={4} variant="title">
                        256
                      </Typography>
                    </S.ProfileValueContent>
                    <S.ProfileValueContent>
                      <Typography color="text.secondary" variant="overline">
                        SHARES
                      </Typography>
                      <Typography mt={4} variant="title">
                        512
                      </Typography>
                    </S.ProfileValueContent>
                    <S.ProfileValueContent>
                      <Typography color="text.secondary" variant="overline">
                        LIKES
                      </Typography>
                      <Typography mt={4} variant="title">
                        1024
                      </Typography>
                    </S.ProfileValueContent>
                  </S.ProfileValue>
                </S.Profile>
                <ArtistDetailProfilePage details={artist} />
              </Fragment>
            )}
      </Card>
      <ArtistDetailGalleryPage isContractCreated={isContractCreated} contractExecuted={contractExecuted}/>
    </>
  );
};
