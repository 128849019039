import { Box } from '@mui/material';
import styled from 'styled-components';

export const Alert = styled(Box)`
  width: 464px;
  height: 88px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  margin-bottom: 8px;
  background: ${(props) => props.theme.palette.common.white};
  display: flex;
  align-items: center;
  padding: ${(props) => props.theme.spacing(8, 16)};
`;

export const Circle = styled(Box)<{ bColor: string }>`
  width: 72px;
  height: 72px;
  border-radius: 50%;

  background: ${(props) => props.bColor + '19'};
  display: flex;
  justify-content: center;
  align-items: center;
`;
