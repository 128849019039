// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Card,
  Chip,
  Grid,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

// Apis
import { OrdersApi } from '../../../apis';

// Global constants
import { ORDER_STATUS } from '../../../shared/enums';
import { StatusLabel } from '../List';
import { IOrderReadRes } from '../../../apis/orders.api';
import { getThumbnailImage } from '../../../utils/common';

// Export order-detail page
export const OrderDetailPage: FC = () => {
  // States
  const [order, setOrder] = useState<IOrderReadRes>();

  // Get navigate from hook
  const navigate = useNavigate();

  // Get order id from hook
  const { id } = useParams<{ id: string }>();

  // Back to handler
  const handleBack = () => {
    navigate(-1);
  };

  // Update order status handler
  const handleUpdateOrderStatus = (status: ORDER_STATUS) => {
    OrdersApi.updateOrderStatus(id, status)
      .then((res) => {
        setOrder(res.order);
      })
      .catch((err) => console.log(err));
  };

  // On id changed
  useEffect(() => {
    if (id) {
      OrdersApi.read(id)
        .then((res) => setOrder(res.order))
        .catch((err) => console.log(err));
    }
  }, [id]);

  const nft = (productId: string) => {
    const nft = order?.nfts.find((nft: any) => nft.productId == productId);
    if (nft) return nft;
    return undefined;
  };

  // Return order-detail page
  return (
    <Card sx={{ p: 2, pb: 4 }}>
      <Stack direction="row" justifyContent="space-between">
        <Button variant="text" size="medium" startIcon={<ArrowBack />} onClick={handleBack}>
          Back
        </Button>
        <Stack direction="row" spacing={2}>
          {[ORDER_STATUS.PROCESSED, ORDER_STATUS.CANCELLED].map((status, index) => (
            <Button
              key={index}
              variant="outlined"
              color={
                (status === ORDER_STATUS.CREATED && 'info') ||
                (status === ORDER_STATUS.CANCELLED && 'warning') ||
                (status === ORDER_STATUS.PROCESSED && 'success') ||
                'primary'
              }
              disabled={status === order?.status}
              onClick={() => handleUpdateOrderStatus(status)}
            >
              {StatusLabel[status]}
            </Button>
          ))}
        </Stack>
      </Stack>
      <Grid container spacing={4}>
        <Grid item xs={6} xl={4}>
          <TableContainer>
            <Typography p={2} variant="h6">
              User
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>First Name</TableCell>
                  <TableCell>{order?.firstName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Last Name</TableCell>
                  <TableCell>{order?.lastName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Email</TableCell>
                  <TableCell>{order?.email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>{order?.username}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>{order?.phoneNumber}</TableCell>
                </TableRow>
                {order?.taxamoId && <TableRow>
                  <TableCell>Taxamo identifier</TableCell>
                  <TableCell>{order?.taxamoId}</TableCell>
                </TableRow>}

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid xs={0} xl={2} />
        <Grid item xs={6} xl={4}>
          <TableContainer>
            <Typography p={2} variant="h6">
              Information
            </Typography>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>ID</TableCell>
                  <TableCell>{order?._id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status</TableCell>
                  <TableCell
                    sx={(theme) => ({
                      color: () => {
                        switch (order?.status) {
                          case ORDER_STATUS.CREATED: {
                            return theme.palette.info.main;
                          }

                          case ORDER_STATUS.CANCELLED: {
                            return theme.palette.warning.main;
                          }

                          case ORDER_STATUS.PROCESSED: {
                            return theme.palette.success.main;
                          }

                          default: {
                            return theme.palette.primary.main;
                          }
                        }
                      }
                    })}
                  >
                    {order?.status && StatusLabel[order.status]}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Note</TableCell>
                  <TableCell>{order?.note}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Payment method</TableCell>
                  <TableCell>
                    <Chip label={order?.paymentMethod} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Payment</TableCell>
                  <TableCell>
                    {order?.cryptoInfo?.amount} {order?.cryptoInfo?.currency}
                  </TableCell>
                </TableRow>
                {order?.taxamoInvoiceNumber && <TableRow>
                  <TableCell>Invoice number</TableCell>
                  <TableCell>{order?.taxamoInvoiceNumber}</TableCell>
                </TableRow>}

              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid xs={0} xl={2} />
        <Grid item xs={12}>
          <TableContainer>
            <Typography p={2} variant="h6">
              Products
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Thumbnail</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Description</TableCell>
                  <TableCell align="right">Type</TableCell>
                  <TableCell align="right">Price</TableCell>
                  <TableCell align="right">Transaction Hash</TableCell>
                  <TableCell align="right">To</TableCell>
                  <TableCell align="right">Token Id</TableCell>
                  <TableCell align="right">Chain</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.orderItems.map(({ type, thumbnail, productName, description, price, productId }, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>
                        <Avatar src={getThumbnailImage({ value: thumbnail })} alt="thumbnail" />
                      </TableCell>
                      <TableCell>{productName}</TableCell>
                      <TableCell>{description}</TableCell>
                      <TableCell align="right">{type?.replace('_', ' ')}</TableCell>
                      <TableCell align="right">{price}</TableCell>
                      {order.nfts.length > 0 && <>
                        <TableCell align="right">{nft(productId)?.details?.transactionHash.substring(0, 10)}...</TableCell>
                        <TableCell align="right">{nft(productId)?.details?.to.substring(0, 10)}...</TableCell>
                        <TableCell align="right">{nft(productId)?.tokenId}</TableCell>
                        <TableCell align="right">{nft(productId)?.details?.chain}</TableCell>
                      </>}

                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell rowSpan={3} />
                  <TableCell>Subtotal</TableCell>
                  <TableCell align="right">{order?.totalPrice}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Vat</TableCell>
                  <TableCell align="right">{order?.vat}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Total</TableCell>
                  {order && <TableCell align="right">{order?.totalPrice + order?.vat}</TableCell>}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Card>
  );
};
