// Dependencies
import { FC, useEffect, useMemo, useState } from 'react';
import { Button, Card, CardHeader } from '@mui/material';

// Styles
import * as S from './styles';

import { HistoryApi } from '../../../apis';
import { IHistory } from '../../../shared/types/history.type';
import moment from 'moment';
import { RECENT_PAGE_LIMIT } from '../../../constants';

// Export recent activities
const RecentActivities: FC = () => {
  // State
  const [activities, setActivities] = useState<IHistory[]>([]);

  const fetchHistories = () => {
    HistoryApi.readAll()
      .then((res) => {
        setActivities(res.histories);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getHistoryTime = (time) => {
    let sep = moment(time).format('a');
    if (sep == 'am') {
      sep = 'a.m.';
    } else {
      sep = 'p.m.';
    }
    return moment(time).format('HH:mm ') + sep;
  };

  const histories = useMemo(() => {
    return activities.map((activity) => {
      return {
        time: getHistoryTime(activity?.createdAt),
        color: activity?.content.defaultColor,
        content: (
          <>
            <S.Color color={activity.content.whoAction.color}>{activity.content.whoAction.value} </S.Color>
            {activity.content.whoActionSuffix && <span>{activity.content.whoActionSuffix.value}</span>}
            {activity.content.howAction.color ? (
              <S.Color color={activity.content.howAction.color}>{activity.content.howAction.value} </S.Color>
            ) : (
              <span>{activity.content.howAction.value} </span>
            )}
            {activity.content.howActionSuffix && <span>{activity.content.howActionSuffix.value}</span>}
            <S.Color color={activity.content.withWhat.color}>{activity.content.withWhat.value} </S.Color>
            {activity.content.withWhatSuffix && (
              <S.Color color={activity.content.withWhatSuffix.color}>{activity.content.withWhatSuffix.value}</S.Color>
            )}
          </>
        )
      };
    });
  }, [activities]);

  // On mounted
  useEffect(() => {
    fetchHistories();
  }, []);

  // Return recent Activities
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Recent Activities" action={<Button>View all</Button>} sx={{ mb: 21 }} />
      {histories.slice(0, RECENT_PAGE_LIMIT).map(({ time, color, content }, index) => (
        <S.Item key={index}>
          <S.Time variant="label">{time}</S.Time>
          <S.Separator>
            <S.Connector />
            <S.Dot color={color} />
            <S.Connector />
          </S.Separator>
          <S.Label>{content}</S.Label>
        </S.Item>
      ))}
    </Card>
  );
};

// Export recent items
export default RecentActivities;
