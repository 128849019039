// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants';

// Component
import { Gallery } from '../../../components/Gallery';
import { ArtistApi } from '../../../apis';

interface IArtistListProps {
  title?: string;
}

export const ArtistListPage: FC<IArtistListProps> = ({ title = 'Artist' }) => {
  // States
  // const [isLoading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(0);
  // const [pageLimit, setPageLimit] = useState<number>(16);
  const [galleryList, setGalleryList] = useState<any>('');
  console.log('🚀 ~ file: index.tsx:19 ~ galleryList', galleryList);
  // const [totalPage, setTotalPage] = useState<number>(-1);

  // Navigate
  const navigate = useNavigate();

  //useEffect
  useEffect(()=>{
    ArtistApi.readAll({ options: {
        limit: 16,
        skip: pageNumber * 16,
      } }).then(res => {
      setGalleryList(res);
    }).catch(error => console.log('Error ', error));
  },[pageNumber]);

  // Page change handler
  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  // New handler
  const handleNew = () => {
    navigate(ROUTES.ARTIST.NEW);
  };

  const handleItemClick = (id) => {
    navigate(ROUTES.ARTIST.DETAIL.replace(':id', id));
  };

  return (
      <Gallery
        title={title}
        data={galleryList?.artists}
        pageLimit={galleryList?.pagination?.pageLimit}
        isLoading={false}
        totalPage={galleryList?.pagination?.total}
        pageNumber={pageNumber}
        onNew={handleNew}
        onPageChange={handlePageChange}
        onItemClick={handleItemClick}
        isGallery={false}
      />
  );
};
