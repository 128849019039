// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Grid } from '@mui/material';

// Widgets
import RecentItems from './RecentItems';
import RecentActivities from './RecentActivities';
import TrafficMap from './TrafficMap';
import Display from './Display';
import Traffic from './Traffic';
import Wallets from './Wallets';
import PaymentsType from './PaymentsType/inde';
import { useSelector } from 'react-redux';
import { getDashboardPeriod } from '../../store/selectors';
import { DashboardApi } from '../../apis';
import { CURRENCY } from '../../shared/enums';
// Constants
const data = [
  {
    label: 'Credit Card',
    percent: 0.2,
    color: 'info',
    currency: CURRENCY.DOLLAR,
    dataIndex: 'credit',
  },
  {
    label: 'Direct Crypto',
    percent: 0.4,
    color: 'error',
    currency: CURRENCY.DOLLAR,
    dataIndex: 'cryptoCurrency',
  },
  {
    label: 'Paypal',
    percent: 0.2,
    color: 'success',
    currency: CURRENCY.DOLLAR,
    dataIndex: 'paypal',
  },
  {
    label: 'Prepaid Crypto Balance',
    percent: 0.2,
    color: 'warning',
    currency: CURRENCY.DOLLAR,
    dataIndex: 'prepayCrypto',
  }
];
// Export dashboard page
export const DashboardPage: FC = () => {
  // peroid
  const period = useSelector(getDashboardPeriod);
  // states
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [totalTraffic, setTotalTraffic] = useState<number>(0);
  const [totalOrder, setTotalOrder] = useState<number>(0);
  const [totalAccount, setTotalAccount] = useState<number>(0);
  const [totalArtist, setTotalArtist] = useState<number>(0);
  const [ratingTraffic, setRatingTraffic] = useState<number>(1);
  const [ratingArtist, setRatingArtist] = useState<number>(1);
  const [ratingOrder, setRatingOrder] = useState<number>(1);
  const [ratingAccount, setRatingAccount] = useState<number>(1);
  const [paymentsTypeData, setPaymentsTypeData] = useState<any[]>(data);

  const [trafficData, setTrafficData] = useState<any>([
    {
      name: 'Songs',
      data: []
    },
    {
      name: 'Events',
      data: []
    },
    {
      name: 'Orders',
      data: []
    }
  ]);

  const handleMakeTrafficData = (res) => {
    const item = [
      {
        name: 'Songs',
        data: []
      },
      {
        name: 'Events',
        data: []
      },
      {
        name: 'Orders',
        data: []
      }
    ];

    item[0].data = res.songs.map((item) => {
      return {
        x: item._id,
        y: item.count
      };
    });
    item[1].data = res.events.map((item) => {
      return {
        x: item._id,
        y: item.count
      };
    });
    item[2].data = res.orders.map((item) => {
      return {
        x: item._id,
        y: item.count
      };
    });

    setTrafficData(item);
  };

  // Fetch data
  const fetchData = () => {
    setIsLoading(true);
    if (isLoading) {
      // fix me
    }
    DashboardApi.getDashboardData({
      period
    })
      .then((res) => {
        setTotalTraffic(res.totalTraffic);
        setTotalOrder(res.totalOrders);
        setTotalAccount(res.totalAccount);
        setTotalArtist(res.totalArtist);
        setRatingTraffic(res.ratingTraffic);
        setRatingOrder(res.ratingOrder);
        setRatingArtist(res.ratingArtist);
        setRatingAccount(res.ratingAccount);

        handleMakeTrafficData(res);

        const { ratingPayments = {} } = res;

        setPaymentsTypeData(data.map((item) => ({
          ...item,
          percent: ratingPayments[item.dataIndex]
        })));
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, [period]);
  // Return dashboard page
  return (
    <Grid container spacing={8}>
      <Grid item xs={12} md={12}>
        <Traffic trafficData={trafficData} />
      </Grid>
      <Grid item xs={6} md={3}>
        <Display
          title="New Artists"
          value={totalArtist.toLocaleString()}
          percent={Number(ratingArtist.toFixed(2))}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Display
          title="New Accounts"
          value={totalAccount.toLocaleString()}
          percent={Number(ratingAccount.toFixed(2))}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Display
          title="New Products"
          value={totalTraffic.toLocaleString()}
          percent={Number(ratingTraffic.toFixed(2))}
        />
      </Grid>
      <Grid item xs={6} md={3}>
        <Display
          title="New Orders"
          value={totalOrder.toLocaleString()}
          percent={Number(ratingOrder.toFixed(2))}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Wallets />
      </Grid>
      <Grid item xs={12} md={6}>
        <PaymentsType paymentsTypeData={paymentsTypeData} />
      </Grid>
      <Grid item xs={12}>
        <TrafficMap />
      </Grid>
      <Grid item xs={12} md={6}>
        <RecentActivities />
      </Grid>
      <Grid item xs={12} md={6}>
        <RecentItems />
      </Grid>
    </Grid>
  );
};
