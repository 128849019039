import React from 'react';
import { iconBase64, ITemplate } from './template1';
import moment from 'moment/moment';

export default function Template5(props: ITemplate) {
  const { name, startTime, location, base64, height, width } = props;
 return(
   <svg xmlns="http://www.w3.org/2000/svg"  id='Template5' width={width} height={height} viewBox="0 0 1690 420">
     <defs>
       <clipPath id="clip-path">
         <rect id="Rectangle_2002" data-name="Rectangle 2002" width="1690" height="420" transform="translate(115 330)" fill="#fff"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       </clipPath>
       <clipPath id="clip-path-2">
         <rect id="Rectangle_1943" data-name="Rectangle 1943" width="80" height="420" transform="translate(1345 330)" fill="aqua"/>
       </clipPath>
       <clipPath id="clip-path-3">
         <rect id="Rectangle_1864" data-name="Rectangle 1864" width="231" height="300" transform="translate(1022 410)" fill="#fff" stroke="#707070" stroke-width="1"/>
       </clipPath>
       <clipPath id="clip-path-4">
         <rect width="22.828" height="451.828" fill="none"/>
       </clipPath>
       <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
         <stop offset="0" stop-color="#f0f"/>
         <stop offset="1" stop-color="#f0f" stop-opacity="0"/>
       </linearGradient>
       <linearGradient id="linear-gradient-4" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
         <stop offset="0" stop-color="aqua"/>
         <stop offset="1" stop-color="aqua"/>
       </linearGradient>
       <linearGradient id="linear-gradient-5" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
         <stop offset="0" stop-color="aqua"/>
         <stop offset="1" stop-color="aqua" stop-opacity="0"/>
       </linearGradient>
       <linearGradient id="linear-gradient-6" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
         <stop offset="0" stop-color="lime"/>
         <stop offset="1" stop-color="lime" stop-opacity="0"/>
       </linearGradient>
       <linearGradient id="linear-gradient-11" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
         <stop offset="0" stop-color="#ff0"/>
         <stop offset="1" stop-color="#ff0" stop-opacity="0"/>
       </linearGradient>
       <radialGradient id="radial-gradient" cx="0.5" cy="0.5" r="0.5" gradientUnits="objectBoundingBox">
         <stop offset="0" stop-color="#fff"/>
         <stop offset="1" stop-color="gray"/>
       </radialGradient>
     </defs>
     <g id="Mask_Group_43" data-name="Mask Group 43" transform="translate(-115 -330)" clip-path="url(#clip-path)">
       <rect id="Rectangle_1883" data-name="Rectangle 1883" width="1690" height="420" transform="translate(115 330)" fill="#e6e6e6"/>
       <g id="Mask_Group_42" data-name="Mask Group 42" clip-path="url(#clip-path-2)">
         <circle id="Ellipse_666" data-name="Ellipse 666" cx="40" cy="40" r="40" transform="translate(1345 710)" fill="blue"/>
         <circle id="Ellipse_667" data-name="Ellipse 667" cx="40" cy="40" r="40" transform="translate(1345 290)" fill="blue"/>
       </g>
       <line id="Line_158" data-name="Line 158" y2="336" transform="translate(155 372.5)" fill="none" stroke="blue" stroke-width="1" stroke-dasharray="24 4"/>
       <line id="Line_159" data-name="Line 159" y2="336" transform="translate(1385 372.5)" fill="none" stroke="blue" stroke-width="5" stroke-dasharray="24 2"/>
       <g id="Group_1133" data-name="Group 1133" transform="translate(-170)">
         <path id="Subtraction_44" data-name="Subtraction 44" d="M119.6,80H40.009A40.044,40.044,0,0,0,80,40a40.125,40.125,0,0,0,39.6,40Z" transform="translate(244.991 670)" fill="blue"/>
         <path id="Subtraction_45" data-name="Subtraction 45" d="M79.588,0H0A39.722,39.722,0,0,1,28.264,11.729,39.731,39.731,0,0,1,39.992,40a40.124,40.124,0,0,1,39.6-40Z" transform="translate(285 330)" fill="blue"/>
       </g>
       <path id="Rectangle_1948" data-name="Rectangle 1948" d="M0,0H0A16,16,0,0,1,16,16v88A16,16,0,0,1,0,120H0a0,0,0,0,1,0,0V0A0,0,0,0,1,0,0Z" transform="translate(115 480)" fill="blue"/>
       <text id="D-MUSIC_EVENT_TICKET" data-name="D-MUSIC EVENT TICKET" transform="translate(148.5 540) rotate(-90)" fill="blue" font-size="16" font-family="Gemunu Libre" font-weight="300" letter-spacing="0.2em"><tspan x="-97.304" y="0">D-MUSIC EVENT TICKET</tspan></text>
       <text id="D-MUSIC_EVENT_TICKET-2" data-name="D-MUSIC EVENT TICKET" transform="translate(1595 737)" fill="blue" font-size="16" font-family="Gemunu Libre" font-weight="300" letter-spacing="0.2em"><tspan x="-97.304" y="0">D-MUSIC EVENT TICKET</tspan></text>
       <text id="D-MUSIC_EVENT_TICKET-3" data-name="D-MUSIC EVENT TICKET" transform="translate(960 364)" fill="blue" font-size="16" font-family="Gemunu Libre" font-weight="300" letter-spacing="0.547em"><tspan x="-150.048" y="0">D-MUSIC EVENT TICKET</tspan></text>
       <path id="Union_1" data-name="Union 1" d="M0,99V19.54H6.492V13.026h6.491V19.54H79.2V99Zm72.706-6.513V26.052H6.492V92.487Zm6.492,0V85.973h6.491v6.514Zm6.491-6.514V79.461h6.492v6.513Zm198.643-5.21V74.25h18.177v6.514Zm-49.336,0V74.25h29.862v6.514Zm-35.055,0V74.25H193.45V67.737h6.491V74.25H216.82v6.514Zm-42.845,0V74.25h16.879v6.514Zm-24.668,0V44.29h-5.193V37.776H138.92V80.763Zm-11.685,0V44.29h-5.193V80.763h-6.492V37.776h11.685V44.29h6.492V80.763Zm-90.881,0V74.25H48.038v6.514Zm-6.492,0V37.776h6.492V80.763Zm68.811-1.3V13.026H85.689V6.514H19.475V0h79.2V79.461ZM302.509,74.25V67.737H309V74.25Zm-24.668,0V44.29h6.491V74.25Zm-31.16,0V44.29H235V37.776h29.862V44.29H253.173V74.25Zm-29.861,0V62.526H199.941V56.013H216.82v6.513h6.491V74.25Zm-42.845,0V37.776h6.491V74.25Zm-23.37,0V37.776H157.1V74.25Zm-102.568,0V44.29H29.862V37.776H48.038V44.29H54.53V74.25ZM193.45,56.013V44.29h6.491V56.013ZM302.509,50.8V44.29H309V50.8Zm-85.689,0V44.29h6.491V50.8Zm67.512-6.513V37.776h18.177V44.29Zm-84.391,0V37.776H216.82V44.29ZM79.2,19.54V13.026h6.491V19.54ZM12.983,13.026V6.514h6.492v6.513Z" transform="translate(215 621)" fill="blue"/>
       <path id="Union_19" data-name="Union 19" d="M0,60V11.842H3.929V7.895H7.857v3.948H47.929V60Zm44-3.947V15.789H3.929V56.053Zm3.929,0V52.1h3.928v3.948ZM51.857,52.1V48.158h3.929V52.1Zm120.214-3.158V45h11v3.948Zm-29.857,0V45h18.072v3.948Zm-21.214,0V45h-3.928V41.053H121V45h10.215v3.948Zm-25.929,0V45h10.215v3.948Zm-14.928,0V26.842H77V22.895h7.071V48.947Zm-7.071,0V26.842H69.929V48.947H66V22.895h7.071v3.948H77V48.947Zm-55,0V45h11v3.948Zm-3.929,0V22.895h3.929V48.947Zm41.643-.789V7.895H51.857V3.948H11.786V0H59.714V48.158ZM183.071,45V41.053H187V45Zm-14.929,0V26.842h3.928V45Zm-18.857,0V26.842h-7.071V22.895h18.072v3.948h-7.071V45Zm-18.071,0V37.894H121V33.947h10.215v3.947h3.928V45Zm-25.929,0V22.895h3.928V45ZM91.143,45V22.895h3.928V45ZM29.071,45V26.842h-11V22.895h11v3.948H33V45Zm88-11.052V26.842H121v7.105Zm66-3.158V26.842H187v3.947Zm-51.857,0V26.842h3.928v3.947Zm40.857-3.947V22.895h11v3.948Zm-51.071,0V22.895h10.215v3.948Zm-73.071-15V7.895h3.928v3.948ZM7.857,7.895V3.948h3.929V7.895Z" transform="translate(1715 537) rotate(-90)" fill="blue"/>
       <g id="Group_1134" data-name="Group 1134">
         <g id="Mask_Group_36" data-name="Mask Group 36" transform="translate(-178 -20)" clip-path="url(#clip-path-3)">
           <image id="https-__cdn.evbuc.com_images_226136489_249360561197_1_original" width="597.919" height="298.96" transform="translate(1022 410.002)" xlinkHref={base64 ? base64 : iconBase64 }/>
         </g>
         <text id="Music_on_Rhoon_Festival" data-name="Music on Rhoon Festival" transform="translate(215 423)" font-size="80" font-family="Gemunu Libre" font-weight="800" letter-spacing="-0.08em"><tspan x="0" y="71">{name ? name : 'Event Title here'}</tspan></text>
         <text id="Music_on_Rhoon_Festival-2" data-name="Music on Rhoon Festival" transform="translate(1415 690) rotate(-90)" font-size="40" font-family="Gemunu Libre" font-weight="800" letter-spacing="-0.08em"><tspan x="0" y="35">{name ? name : 'Event Title here'}</tspan></text>
         <text id="D-Music_s_Clubhouse_Metaverse" data-name="D-Music&apos;s Clubhouse
Metaverse" transform="translate(1488 690) rotate(-90)" font-size="30" font-family="Gemunu Libre" font-weight="200" letter-spacing="-0.08em"><tspan x="0" y="27">{location ? location : 'Event Location here'}</tspan></text>
         <text id="Saturday_June_25_2022_14:30_to_01:00_CEST_" data-name="Saturday
June 25 2022
14:30 to 01:00 (CEST)" transform="translate(1591 690) rotate(-90)" font-size="30" font-family="Gemunu Libre" font-weight="200" letter-spacing="-0.08em"><tspan x="0" y="27">{startTime? moment(startTime).format('MMMM Do YYYY, h:mm:ss a') : 'Event Date Here'}</tspan></text>
         <text id="D-Music_s_Clubhouse_Metaverse-2" data-name="D-Music&apos;s Clubhouse
Metaverse" transform="translate(1124 412)" font-size="30" font-family="Gemunu Libre" font-weight="800" letter-spacing="-0.08em"><tspan x="-231" y="27">{location? location : 'Event Location here'}</tspan></text>
         <text id="Saturday_June_25_2022_14:30_to_01:00_CEST_2" data-name="Saturday
June 25 2022
14:30 to 01:00 (CEST)" transform="translate(1124 597)" font-size="30" font-family="Gemunu Libre" font-weight="800" letter-spacing="-0.08em"><tspan x="-111.96" y="27">{startTime? moment(startTime).format('MMMM Do YYYY, h:mm:ss a') : 'Event Date Here'}</tspan></text>
         <text id="Event_Title:" data-name="Event Title:" transform="translate(215 390)" font-size="30" font-family="Gemunu Libre" font-weight="200" letter-spacing="-0.02em"><tspan x="0" y="27">EVENT TITLE:</tspan></text>
         <text id="Location:" transform="translate(1124 390)" font-size="20" font-family="Gemunu Libre" font-weight="200" letter-spacing="-0.02em"><tspan x="-70.68" y="18">LOCATION:</tspan></text>
         <text id="Date:" transform="translate(1124 575)" font-size="20" font-family="Gemunu Libre" font-weight="200" letter-spacing="-0.02em"><tspan x="-38.76" y="18">DATE:</tspan></text>
       </g>
       <g id="Repeat_Grid_5" data-name="Repeat Grid 5" transform="translate(1787 308.586)" clip-path="url(#clip-path-4)">
         <g transform="translate(-1785.586 -308.586)">
           <line id="Line_160" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -297.586)">
           <line id="Line_160-2" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -286.586)">
           <line id="Line_160-3" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -275.586)">
           <line id="Line_160-4" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -264.586)">
           <line id="Line_160-5" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -253.586)">
           <line id="Line_160-6" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -242.586)">
           <line id="Line_160-7" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -231.586)">
           <line id="Line_160-8" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -220.586)">
           <line id="Line_160-9" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -209.586)">
           <line id="Line_160-10" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -198.586)">
           <line id="Line_160-11" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -187.586)">
           <line id="Line_160-12" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -176.586)">
           <line id="Line_160-13" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -165.586)">
           <line id="Line_160-14" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -154.586)">
           <line id="Line_160-15" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -143.586)">
           <line id="Line_160-16" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -132.586)">
           <line id="Line_160-17" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -121.586)">
           <line id="Line_160-18" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -110.586)">
           <line id="Line_160-19" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -99.586)">
           <line id="Line_160-20" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -88.586)">
           <line id="Line_160-21" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -77.586)">
           <line id="Line_160-22" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -66.586)">
           <line id="Line_160-23" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -55.586)">
           <line id="Line_160-24" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -44.586)">
           <line id="Line_160-25" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -33.586)">
           <line id="Line_160-26" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -22.586)">
           <line id="Line_160-27" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -11.586)">
           <line id="Line_160-28" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 -0.586)">
           <line id="Line_160-29" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 10.414)">
           <line id="Line_160-30" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 21.414)">
           <line id="Line_160-31" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 32.414)">
           <line id="Line_160-32" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 43.414)">
           <line id="Line_160-33" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 54.414)">
           <line id="Line_160-34" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 65.414)">
           <line id="Line_160-35" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 76.414)">
           <line id="Line_160-36" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 87.414)">
           <line id="Line_160-37" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 98.414)">
           <line id="Line_160-38" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 109.414)">
           <line id="Line_160-39" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 120.414)">
           <line id="Line_160-40" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 131.414)">
           <line id="Line_160-41" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
         <g transform="translate(-1785.586 142.414)">
           <line id="Line_160-42" data-name="Line 160" x2="20" y2="20" transform="translate(1787 310)" fill="none" stroke="blue" stroke-width="4"/>
         </g>
       </g>
       <rect id="Rectangle_1989" data-name="Rectangle 1989" width="111" height="489" transform="translate(279.686 236.508) rotate(30)" fill="url(#linear-gradient)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1996" data-name="Rectangle 1996" width="111" height="489" transform="translate(186.686 102.508) rotate(30)" fill="#f0f"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1995" data-name="Rectangle 1995" width="111" height="489" transform="translate(1246.971 808.986) rotate(-150)" fill="url(#linear-gradient)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_2001" data-name="Rectangle 2001" width="207" height="647" transform="translate(755.91 878.02) rotate(-150)" opacity="0.74" fill="url(#linear-gradient)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1998" data-name="Rectangle 1998" width="111" height="489" transform="translate(1626.971 828.986) rotate(-150)" fill="url(#linear-gradient-4)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1999" data-name="Rectangle 1999" width="134" height="489" transform="translate(1725.047 840.486) rotate(-150)" fill="#ff0"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1994" data-name="Rectangle 1994" width="111" height="489" transform="translate(450 147.508) rotate(30)" fill="url(#linear-gradient-5)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1990" data-name="Rectangle 1990" width="33.092" height="489" transform="translate(1061.5 817.494) rotate(-150)" opacity="0.6" fill="url(#linear-gradient-6)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_2000" data-name="Rectangle 2000" width="49.254" height="673.631" transform="translate(1800.344 -68.01) rotate(30)" opacity="0.9" fill="url(#linear-gradient-6)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1992" data-name="Rectangle 1992" width="13.059" height="627" transform="translate(818.65 810.256) rotate(-150)" opacity="0.6" fill="url(#linear-gradient-6)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1993" data-name="Rectangle 1993" width="51.059" height="627" transform="translate(787.65 797.256) rotate(-150)" opacity="0.33" fill="url(#linear-gradient-6)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1997" data-name="Rectangle 1997" width="51.059" height="627" transform="translate(178.719 797.256) rotate(-150)" opacity="0.44" fill="url(#linear-gradient-6)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1991" data-name="Rectangle 1991" width="69.092" height="489" transform="translate(1082.677 906.018) rotate(-150)" fill="url(#linear-gradient-11)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
       <rect id="Rectangle_1988" data-name="Rectangle 1988" width="1690" height="420" transform="translate(115 330)" opacity="0.5" fill="url(#radial-gradient)"  style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
     </g>
   </svg>

 );
}
