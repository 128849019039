import React from 'react';
import { iconBase64, ITemplate } from './template1';
import moment from 'moment/moment';
export default function Template2(props: ITemplate){
  const { name, startTime, location, base64, height, width, description } = props;
return(
    <svg xmlns="http://www.w3.org/2000/svg" id='Template2' width={width} height={height} viewBox="0 0 1689.997 420.004">
      {/*<defs>*/}
      {/*  <clipPath id="clip-path">*/}
      {/*    <rect id="Rectangle_1864" data-name="Rectangle 1864" width="300" height="388.769" transform="translate(1022 410)" fill="#fff" stroke="#707070" stroke-width="1"/>*/}
      {/*  </clipPath>*/}
      {/*</defs>*/}
      <g id="Group_1063" data-name="Group 1063" transform="translate(-222 1170.002) rotate(-90)">
        <g id="Group_1057" data-name="Group 1057" transform="translate(1500 106.998) rotate(90)">
          <path id="Subtraction_43" data-name="Subtraction 43" d="M16197,24446h0l-539,0-20.5-35-20.5,35H14507v-420l1110,0,20.5,35,20.5-35h539v420Z" transform="translate(-14392 -23696)" fill="#fff"/>
          <line id="Line_156" data-name="Line 156" y2="348.5" transform="translate(1245.5 365.5)" fill="none" stroke="#000" stroke-width="1" stroke-dasharray="5 5"/>
        </g>
        <g id="Group_1060" data-name="Group 1060" transform="translate(-7 -7)">
          <path id="Union_1" data-name="Union 1" d="M0,76V15H5V10h5v5H61V76Zm56-5V20H5V71Zm5,0V66h5v5Zm5-5V61h5v5Zm153-4V57h14v5Zm-38,0V57h23v5Zm-27,0V57h-5V52h5v5h13v5Zm-33,0V57h13v5Zm-19,0V34H98V29h9V62Zm-9,0V34H89V62H84V29h9v5h5V62ZM23,62V57H37v5Zm-5,0V29h5V62Zm53-1V10H66V5H15V0H76V61Zm162-4V52h5v5Zm-19,0V34h5V57Zm-24,0V34h-9V29h23v5h-9V57Zm-23,0V48H154V43h13v5h5v9Zm-33,0V29h5V57Zm-18,0V29h5V57ZM37,57V34H23V29H37v5h5V57ZM149,43V34h5v9Zm84-4V34h5v5Zm-66,0V34h5v5Zm52-5V29h14v5Zm-65,0V29h13v5ZM61,15V10h5v5ZM10,10V5h5v5Z" transform="translate(921 489)"/>
          <g id="Group_1058" data-name="Group 1058" transform="translate(448 -114)">
            <rect id="Rectangle_1884" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1059" data-name="Group 1059" transform="translate(457.797 -104.203)">
            <rect id="Rectangle_1885-2" data-name="Rectangle 1885" width="5" height="41" transform="translate(362.203 360.203)"/>
            <rect id="Rectangle_1888" data-name="Rectangle 1888" width="5" height="41" transform="translate(326.203 360.203)"/>
            <rect id="Rectangle_1886-2" data-name="Rectangle 1886" width="5" height="41" transform="translate(367.203 360.203) rotate(90)"/>
            <rect id="Rectangle_1889" data-name="Rectangle 1889" width="5" height="41" transform="translate(367.203 396.203) rotate(90)"/>
          </g>
          <g id="Group_1058-2" data-name="Group 1058" transform="translate(529 -114)">
            <rect id="Rectangle_1884-2" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-3" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-3" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-2" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-3" data-name="Group 1058" transform="translate(610 -114)">
            <rect id="Rectangle_1884-3" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-4" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-4" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-3" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-4" data-name="Group 1058" transform="translate(691 -114)">
            <rect id="Rectangle_1884-4" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-5" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-5" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-4" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-5" data-name="Group 1058" transform="translate(772 -114)">
            <rect id="Rectangle_1884-5" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-6" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-6" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-5" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-6" data-name="Group 1058" transform="translate(448 -33)">
            <rect id="Rectangle_1884-6" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-7" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-7" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-6" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-7" data-name="Group 1058" transform="translate(529 -33)">
            <rect id="Rectangle_1884-7" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-8" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-8" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-7" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-8" data-name="Group 1058" transform="translate(610 -33)">
            <rect id="Rectangle_1884-8" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-9" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-9" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-8" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-9" data-name="Group 1058" transform="translate(691 -33)">
            <rect id="Rectangle_1884-9" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-10" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-10" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-9" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-10" data-name="Group 1058" transform="translate(772 -33)">
            <rect id="Rectangle_1884-10" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-11" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-11" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-10" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-11" data-name="Group 1058" transform="translate(448 48)">
            <rect id="Rectangle_1884-11" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-12" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-12" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-11" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-12" data-name="Group 1058" transform="translate(529 48)">
            <rect id="Rectangle_1884-12" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-13" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-13" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-12" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-13" data-name="Group 1058" transform="translate(610 48)">
            <rect id="Rectangle_1884-13" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-14" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-14" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-13" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-14" data-name="Group 1058" transform="translate(691 48)">
            <rect id="Rectangle_1884-14" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-15" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-15" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-14" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-15" data-name="Group 1058" transform="translate(772 48)">
            <rect id="Rectangle_1884-15" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-16" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-16" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-15" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-16" data-name="Group 1058" transform="translate(448 129)">
            <rect id="Rectangle_1884-16" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-17" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-17" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-16" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-17" data-name="Group 1058" transform="translate(529 129)">
            <rect id="Rectangle_1884-17" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-18" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-18" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-17" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-18" data-name="Group 1058" transform="translate(448 210)">
            <rect id="Rectangle_1884-18" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-19" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-19" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-18" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-19" data-name="Group 1058" transform="translate(529 210)">
            <rect id="Rectangle_1884-19" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-20" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-20" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-19" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-20" data-name="Group 1058" transform="translate(610 210)">
            <rect id="Rectangle_1884-20" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-21" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-21" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-20" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-21" data-name="Group 1058" transform="translate(691 210)">
            <rect id="Rectangle_1884-21" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-22" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-22" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-21" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-22" data-name="Group 1058" transform="translate(772 210)">
            <rect id="Rectangle_1884-22" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-23" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-23" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-22" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-23" data-name="Group 1058" transform="translate(448 291)">
            <rect id="Rectangle_1884-23" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-24" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-24" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-23" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-24" data-name="Group 1058" transform="translate(529 291)">
            <rect id="Rectangle_1884-24" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-25" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-25" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-24" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-25" data-name="Group 1058" transform="translate(610 291)">
            <rect id="Rectangle_1884-25" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-26" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-26" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-25" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-26" data-name="Group 1058" transform="translate(691 291)">
            <rect id="Rectangle_1884-26" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-27" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-27" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-26" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
          <g id="Group_1058-27" data-name="Group 1058" transform="translate(772 291)">
            <rect id="Rectangle_1884-27" data-name="Rectangle 1884" width="5" height="61" transform="translate(326 360)"/>
            <rect id="Rectangle_1885-28" data-name="Rectangle 1885" width="5" height="61" transform="translate(382 360)"/>
            <rect id="Rectangle_1886-28" data-name="Rectangle 1886" width="5" height="61" transform="translate(387 360) rotate(90)"/>
            <rect id="Rectangle_1887-27" data-name="Rectangle 1887" width="5" height="61" transform="translate(387 416) rotate(90)"/>
          </g>
        </g>
        <g id="Group_1061" data-name="Group 1061" transform="translate(0 -1)">
          <text id="Music_on_Rhoon_Festival" data-name="Music on Rhoon
Festival" transform="translate(767 866)" font-size="65" font-family="BebasNeue-Regular, Bebas Neue"><tspan x="0" y="62">{name? name : 'Event title here'}</tspan></text>
          <text id="description" transform="translate(767 999)" font-size="16" font-family="Roboto-Regular, Roboto"> <tspan>{description}.</tspan><tspan x="0" y="75"></tspan><tspan x="0" y="95">{startTime ? moment(startTime).format('MMMM Do YYYY, h:mm:ss a')  : 'Event Date here'}</tspan><tspan x="0" y="115">{location ? location : 'Event Location here'}</tspan></text>
        </g>
        <text id="SAT" transform="translate(767 1306)" font-size="32" font-family="BebasNeue-Regular, Bebas Neue"><tspan x="0" y="0">{startTime}</tspan></text>
        <g id="Group_1062" data-name="Group 1062">
          {/*<g id="Mask_Group_36" data-name="Mask Group 36" transform="translate(-212 1027.615)" clip-path="url(#clip-path)">*/}
          {/*</g>*/}
          <image xlinkHref={base64 ? base64 : iconBase64} height={'100%'} transform="translate(743, 1430)"/>
          <rect id="Rectangle_1890" data-name="Rectangle 1890" width="300" height="388" transform="translate(810 1438)" style={{ mixBlendMode: 'overlay',isolation: 'isolate' }}/>
        </g>
        {/*<rect id="me" width="10" height="10" color={'red'}>*/}
        {/*  <image xlinkHref={base64 ? base64 : iconBase64} transform="translate(743, 1430)"/>*/}
        {/*</rect>*/}
      </g>
    </svg>
  );
}
