import React, { FC, useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Stack, TextField, Typography } from '@mui/material';

import * as S from './styles';
import { ITerm } from '../../../shared/types';
import { TermApi } from '../../../apis';
import moment from 'moment';

export const TermsPage: FC = () => {
  const [termData, setTermData] = useState<ITerm>();

  const handleSave = () => {
    if (termData) {
      TermApi.update(termData)
        .then((res) => {
          console.log('req',res.body);
          setTermData(res.body);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Fetch data
  const fetchData = () => {
    TermApi.read()
      .then((res) => {
        setTermData(res.body);
      })
      .catch((err) => {
        console.log(err);
      });
  };

// On mounted
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Card>
      <S.Header>
        {/*<Typography*/}
        {/*  variant="title"*/}
        {/*  color="text.secondary"*/}
        {/*  contentEditable*/}
        {/*  onBlur={(e) => handleBlur(e, 'title')}>*/}
        {/*  {termData?.title}*/}
        {/*</Typography>*/}
        <TextField
          name='title'
          sx={(theme)=>({
            width: '100%',
            '& fieldset': {
              border: 'none'
            },
            'input': {
              color: theme.palette.text.secondary,
              fontSize: 24,
              padding: '0 !important',
            }
          })}
          value={termData?.title}
          onChange={(e) => setTermData({
            content: termData?.content,
            title: e.target.value })}
        />


        <Typography variant="overline" color="text.disabled" sx={{ flexShrink: 0 }}>
          Updated: {moment(termData?.updatedAt).format('HH:MM - MMMM DD,  YYYY')}
        </Typography>
      </S.Header>
      <CardContent>
        <TextField
          fullWidth
          multiline
          minRows={12}
          name="description"
          label=''
          value={termData?.content}
          onChange={(e) => setTermData({
            title: termData?.title,
            content: e.target.value })}
          sx={(theme)=>({
            '& fieldset': {
              border: 'none'
            },
            '& textarea': {
              color: theme.palette.text.secondary,
              fontSize: 20,
              padding: '0 !important',
            }
          })}
        />
      </CardContent>
      <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
        <Stack direction="row" spacing={1}>
          <Button variant="contained" onClick={handleSave}>
            Save
          </Button>
        </Stack>
      </Box>
    </Card>
  );
};
