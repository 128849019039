import { Button, Card, Box, Typography } from '@mui/material';
import styled from 'styled-components';

export const CardTitle = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.palette.text.disabled};
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;

  button {
    display: none;
  }
`;

export const CardView = styled(Card)`
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.text.secondary};
    button {
      color: ${(props) => props.theme.palette.text.secondary};
      display: block;
    }
  }
  height: 320px;
  border: 1px solid transparent;
  button {
    display: none;
  }
`;

export const Title = styled(Typography)`
  outline: none;
`;

export const Description = styled(Typography)`
  outline: none;
`;

export const AddButton = styled(Button)`
  && {
    color: ${(props) => props.theme.palette.text.disabled};
    width: 100%;
    margin-top: 10px;

    &:hover {
      background: ${(props) => props.theme.palette.common.white};
    }
  }
`;

export const ColAddButton = styled(Button)`
  && {
    color: ${(props) => props.theme.palette.text.disabled};
    min-width: 40px;
    height: 320px;
    padding: 0;

    &:hover {
      background: #ffffff;
    }

    span {
      margin: 0;
    }
  }
`;
