import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const Footer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${(props) => props.theme.spacing(4)};
`;

export const Progress = styled(Stack)`
  flex: 1;
  margin-right: ${(props) => props.theme.spacing(89)};
`;
