import { Box, Stack } from '@mui/material';
import styled from 'styled-components';

export const Header = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${(props) => props.theme.spacing(29)};
`;

export const Circle = styled(Box)`
  width: 12px;
  height: 12px;
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.palette.text.secondary};
  margin-right: ${(props) => props.theme.spacing(12)};
`;

export const Category = styled(Box)<{ active: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 56px;
  margin-left: ${(props) => props.theme.spacing(-8)};
  padding-left: ${(props) => props.theme.spacing(8)};
  width: calc(100% + 16px);
  border-radius: 8px;
  background-color: ${(props) => (props.active ? props.theme.palette.action.active : 'transparent')};

  ${Circle} {
    background-color: ${(props) => (props.active ? props.theme.palette.text.primary : 'transparent')};
  }
`;

export const Question = styled(Box)`
  display: flex;
  align-items: center;

  & > svg {
    margin-right: ${(props) => props.theme.spacing(12)};
  }
`;

export const Actions = styled(Stack)`
  margin-left: auto;
  align-items: center;
`;

export const PreviewHeader = styled(Box)`
  display: flex;
  align-items: center;
`;

export const PreviewContentItem = styled(Box)`
  display: flex;
  align-items: flex-end;
  padding: ${(props) => props.theme.spacing(12, 12)};
`;
