import { Box, TextField } from '@mui/material';
import styled from 'styled-components';


export const Header = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(0, 16)};
  border-bottom: 1px solid ${(props) => props.theme.palette.text.disabled};
`;

export const Description = styled(TextField)`
  fieldset {
    border: none;
  }
  textarea {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: ${(props) => props.theme.body1};
    padding:0!important;
  }
`;
export const Title = styled(TextField)`
  fieldset {
    border: none;
  }
  textarea {
    color: ${(props) => props.theme.palette.text.secondary};
    font-size: ${(props) => props.theme.title};
    padding:0!important;
  }
`;

export const Content = styled(Box)`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.secondary};
  padding: ${(props) => props.theme.spacing(16, 12, 0)};
`;
