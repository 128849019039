// Dependencies
import React, { FC, useEffect, useState } from 'react';
import {
  Typography,
  Button,
  CardHeader,
  Stack,
  Table,
  TableCell,
  TableRow,
  Divider
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getTransactionDetails } from '../../../apis/products.api';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import TrannsferTokenDialog from '../../../components/TransferTokenDialog';
import { ArrowLeftIcon, ArrowsExchangeAltIcon } from '../../../assets/icons';
import { IColumn } from '../../../components';
import { Table as DataTable } from '../../../components';
import { PINATA_GATEWAY_URL } from '../List';

// Export users page
export const NftDetailPage: FC = () => {
  const { address } = useParams();
  const navigate = useNavigate();
  const [transactionDetail, setTransactionDetail] = useState<any>({});
  console.log('🚀 ~ file: index.tsx:27 ~ transactionDetail', transactionDetail);
  const location = useLocation();
  // const TOKEN_OWNER_ADDRESS = '0x266c2b52b9065f61274f77ca6473e3accbd3e130';
  const query = new URLSearchParams(location.search);
  const tokenId = query.get('tokenId');
  const getTransaction = async () => {
    const res = await getTransactionDetails(address as string, tokenId as string);
    setTransactionDetail(res);
  };

  const handleClickBackBtn = () => {
    navigate(-1);
  };
  const ETHEREUM_TESTNET_NETWORK_PROVIDER = 'https://sepolia.etherscan.io';
  const CASPER_TESTNET_NETWORK_PROVIDER = 'https://div3.in';

  const columns: IColumn[] = [
    {
      field: 'txn_hash',
      title: 'Txn Hash',
      sort: false,
      render: (row) => (
        <a href={`${ETHEREUM_TESTNET_NETWORK_PROVIDER}/tx/${row?.transactionHash}`} target="_blank">
          {row.transactionHash.substring(0, 12) + '...'}
        </a>
      ),
    },
    {
      field: 'event',
      title: 'Method',
      sort: false,
    },
    {
      field: '',
      title: 'From',
      sort: false,
      render: (row) => (
        <a href={`${ETHEREUM_TESTNET_NETWORK_PROVIDER}/address/${row?.returnValues?.from}`} target="_blank">
          {row?.returnValues?.from.substring(0, 12) + '...'}
        </a>
      ),
    },
    {
      field: '',
      title: 'To',
      sort: false,
      render: (row) => (
        <a href={`${ETHEREUM_TESTNET_NETWORK_PROVIDER}/address/${row?.returnValues?.to}`} target="_blank">
          {row?.returnValues?.to.substring(0, 12) + '...'}
        </a>
      )
    },
    {
      field: '',
      title: 'Token ID',
      sort: false,
      render: (row) => (
        <a href={`${ETHEREUM_TESTNET_NETWORK_PROVIDER}/token/${row?.returnValues?.tokenId}`} target="_blank">
          {row?.returnValues?.tokenId}
        </a>
      ),
    }
  ];

  useEffect(() => {
    if (address) {
      getTransaction();
    }
  }, [address]);

  return (
    <Stack spacing={8}>
      <Card>
        <CardHeader
          title={
            <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleClickBackBtn}>
              Back
            </Button>
          }
          action={
            <Button startIcon={<ArrowsExchangeAltIcon />} size="medium">
              Transfer Token
            </Button>
          }
        />
        <CardContent>
          <Typography mb={60} variant="title" color="text.secondary">Detail</Typography>
          {transactionDetail && !transactionDetail?.tx?.approvals ? <Stack divider={<Divider />}>
            <Table>
              <TableRow>
                <TableCell>Token ID:</TableCell>
                <TableCell>{tokenId}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tx Hash:</TableCell>
                <TableCell>
                  <a href={`${ETHEREUM_TESTNET_NETWORK_PROVIDER}/tx/${transactionDetail?.tx?.transactionHash}`} target="_blank">
                    {transactionDetail?.tx?.transactionHash}
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Status:</TableCell>
                <TableCell>{transactionDetail?.tx?.status ? 'Success' : 'Failed'}</TableCell>
              </TableRow>
            </Table>
            <Table>
              <TableRow>
                <TableCell>Own Address:</TableCell>
                <TableCell>
                  <a href={`${ETHEREUM_TESTNET_NETWORK_PROVIDER}/address/${transactionDetail?.tx?.from}`} target="_blank">
                    {transactionDetail?.tx?.from}
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>From:</TableCell>
                <TableCell>
                  <a href={`${ETHEREUM_TESTNET_NETWORK_PROVIDER}/address/${transactionDetail?.tx?.from}`} target="_blank">
                    {transactionDetail?.tx?.from}
                  </a>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>To:</TableCell>
                <TableCell>
                  <a href={`${ETHEREUM_TESTNET_NETWORK_PROVIDER}/address/${transactionDetail?.tx?.to}`} target="_blank">
                    {transactionDetail?.tx?.to}
                  </a>
                </TableCell>
              </TableRow>
            </Table>
            <Table>
              <TableRow>
                <TableCell>Gas Limit & Usage by Txn:</TableCell>
                <TableCell>{transactionDetail?.tx?.gasUsed}</TableCell>
              </TableRow>
            </Table>
          </Stack>
            :
            <Stack divider={<Divider />}>
              <Table>
                <TableRow>
                  <TableCell>Token ID:</TableCell>
                  <TableCell>{tokenId}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tx Hash:</TableCell>
                  <TableCell>
                    <a href={`${CASPER_TESTNET_NETWORK_PROVIDER}/deploys/${transactionDetail?.nft?.details.transactionHash}`} target="_blank">
                      {transactionDetail?.nft?.details.transactionHash}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Status:</TableCell>
                  <TableCell>{transactionDetail?.nft?.status ? 'Success' : 'Failed'}</TableCell>
                </TableRow>
              </Table>
              <Table>
                <TableRow>
                  <TableCell>Ipfs image hash:</TableCell>
                  <TableCell>
                    <a href={`${PINATA_GATEWAY_URL}/${transactionDetail?.nft?.ipfsImageHash?.split('//')[1]}`} target="_blank">
                      {transactionDetail?.nft?.ipfsImageHash}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Ipfs json file hash:</TableCell>
                  <TableCell>
                    <a href={`${PINATA_GATEWAY_URL}/${transactionDetail?.nft?.ipfsFileHash?.split('//')[1]}`} target="_blank">
                      {transactionDetail?.nft?.ipfsFileHash}
                    </a>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>To:</TableCell>
                  <TableCell>
                    <a href={`${CASPER_TESTNET_NETWORK_PROVIDER}/accounts/${transactionDetail?.nft?.details.to}`} target="_blank">
                      {transactionDetail?.nft?.details.to}
                    </a>
                  </TableCell>
                </TableRow>
              </Table>
            </Stack>
          }

        </CardContent>
      </Card>
      <DataTable title="History" data={transactionDetail?.history || []} columns={columns} />
    </Stack>
  );
};
