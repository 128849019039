import React, { FC, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Grid,
  Card,
  Typography,
  Button,
  Stack,
  CircularProgress,
  Chip,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Switch,
  TextField,
  Divider,
  InputLabel
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Delete, Edit } from '@mui/icons-material';
import moment from 'moment';

import { ProductsApi } from '../../../apis';
import { ProductModel } from '../../../shared/models';
import { ConfirmDialog, Dropzone } from '../../../components';
import { REACT_APP_API_ASSETS_SERVER, ROUTES } from '../../../constants';
import { ArrowLeftIcon } from '../../../assets/icons';

export const EventDetailPage: FC = () => {
  const [event, setEvent] = useState<ProductModel | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);

  const location = useLocation();
  const routes = useParams();
  const navigate = useNavigate();

  const fetchEvent = () => {
    const id = routes.id;
    if (!id) return;

    setLoading(true);
    ProductsApi.read(id)
      .then((res) => {
        setEvent(new ProductModel(res.product));
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchEvent();
  }, [location.pathname]);

  const handleClickBackBtn = () => {
    navigate(-1);
  };

  const handleClickDeleteBtn = () => {
    setVisibleDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmed = () => {
    ProductsApi.remove(routes.id as string)
      .then(() => navigate(ROUTES.EVENT.LIST))
      .catch((err) => console.log(err));
  };

  const handleEdit = () => {
    if (event) {
      navigate(ROUTES.EVENT.EDIT.replace(':id', event.id));
    }
  };

  return (
    <Card>
      {loading || !event ? (
        <Box height={300} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <>
          <CardHeader
            title={
              <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleClickBackBtn}>
                Back
              </Button>
            }
            action={
              <Stack direction="row" spacing={16}>
                <Button startIcon={<Delete />} color="error" size="medium" onClick={handleClickDeleteBtn}>
                  Delete
                </Button>
                <Button startIcon={<Edit />} size="medium" onClick={handleEdit}>
                  Edit
                </Button>
              </Stack>
            }
            sx={{ mb: 24 }}
          />
          <Box display="flex" alignItems="center">
            <Avatar src={event.getAvatarUrl} />
            <Typography variant="h5" sx={{ ml: 8 }}>
              {event.name}
            </Typography>
          </Box>
          <Grid container sx={{ mt: 16 }} justifyContent="space-between">
            <Grid item xs={12} lg={5.8} mb={16}>
              <Stack direction="column" spacing={16}>
                <Table>
                  <TableBody>
                    {event?.name && (
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>{event?.name}</TableCell>
                      </TableRow>
                    )}
                    {event?.categoryNames && (
                      <TableRow>
                        <TableCell>Category</TableCell>
                        <TableCell>
                          {event.categoryNames.map((cat) => (
                            <Chip label={cat} key={cat} />
                          ))}
                        </TableCell>
                      </TableRow>
                    )}
                    {event?.price && (
                      <TableRow>
                        <TableCell>Price</TableCell>
                        <TableCell>
                          {event?.currency}
                          {event?.price}
                        </TableCell>
                      </TableRow>
                    )}
                    {event?.sku && (
                      <TableRow>
                        <TableCell>SKU</TableCell>
                        <TableCell>{event?.sku}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>Is Featured</TableCell>
                      <TableCell>
                        <Switch disabled checked={event?.isFeatured} />
                      </TableCell>
                    </TableRow>
                    {event?.startTime && (
                      <TableRow>
                        <TableCell>StartTime</TableCell>
                        <TableCell>{moment(event?.startTime).calendar()}</TableCell>
                      </TableRow>
                    )}
                    {event?.endTime && (
                      <TableRow>
                        <TableCell>EndTime</TableCell>
                        <TableCell>{moment(event?.endTime).calendar()}</TableCell>
                      </TableRow>
                    )}
                    <TableRow>
                      <TableCell>Created At</TableCell>
                      <TableCell>{moment(event?.createdAt).calendar()}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Updated At</TableCell>
                      <TableCell>{moment(event?.updatedAt).calendar()}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Stack>
            </Grid>
            <Divider orientation="vertical" flexItem />
            <Grid item xs={12} lg={5.8}>
              <Grid container spacing={24} direction="column">
                <Grid item xs={12} lg={6}>
                  {/* @ts-ignore */}
                  <InputLabel variant="alone">Thumbnail</InputLabel>
                  <Dropzone
                    label="Drag image here to upload"
                    accept={['.png', '.jpg', '.svg']}
                    onDrop={handleEdit}
                    preview={`${REACT_APP_API_ASSETS_SERVER}/${event.thumbnail?.fieldname}/${event.thumbnail?.filename}`}
                  />
                </Grid>
                <Grid item xs={12}>
                  {event.statement && (
                    <TextField
                      fullWidth
                      multiline
                      minRows={5}
                      name="statement"
                      label="Statement"
                      value={event.statement}
                      disabled
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    minRows={7}
                    name="description"
                    label="Description"
                    value={event.description}
                    disabled
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <ConfirmDialog
        description="Are you sure to delete?"
        visible={visibleDeleteConfirmDialog}
        setVisible={setVisibleDeleteConfirmDialog}
        onConfirmed={handleDeleteConfirmed}
      />
    </Card>
  );
};
