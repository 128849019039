// Dependencies
import { FC } from 'react';
import ReactDropzone from 'react-dropzone';
import { Typography } from '@mui/material';

// Styles
import * as S from './styles';
import * as React from 'react';
import { AudioPlayer } from '../PreviewDialog/AudioPlayer';

// Interfaces
interface IDropzoneProps {
  label: string;
  accept: string[];
  disabled?: boolean;
  onDrop: (files) => void;
  preview?: any;
  audioSrc?: string;
}

// Export dropzone
export const Dropzone: FC<IDropzoneProps> = ({ label, accept, disabled, onDrop, preview, audioSrc }) => {
  // Drop handler
  const handleDrop = (acceptedFiles) => {
    onDrop(acceptedFiles);
  };

  // Return dropzone
  return (
    <ReactDropzone onDrop={handleDrop} disabled={disabled}>
      {({ getRootProps, getInputProps, isDragActive }) => (
        <S.Dropzone {...getRootProps()} active={audioSrc ? true : isDragActive} disabled={disabled}>
          <input {...getInputProps()} accept={accept.join(', ')} />
          {preview ? (
            <img src={preview} width={'100%'} height={'100%'} alt={'preview'} />
          ) : audioSrc ? (
            <AudioPlayer src={audioSrc} />
          ) : (
            <Typography variant="subtitle" textAlign="center" color="text.secondary">
              {label}
              <br />({accept.join(', ').toUpperCase()})
            </Typography>
          )}
        </S.Dropzone>
      )}
    </ReactDropzone>
  );
};
