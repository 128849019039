import styled from 'styled-components';
import { Box } from '@mui/material';

export const Chart = styled(Box)`
  display: flex;
  align-items: flex-end;
`;

export const Item = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  & + & {
    margin-left: ${(props) => props.theme.spacing(12)};
  }
`;

export const Bar = styled(Box)<{ percent: number; color: string }>`
  width: 96px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette[props.color].main};
  height: ${(props) => props.percent * 250}px;
  margin: ${(props) => props.theme.spacing(2, 0, 12)};
`;
