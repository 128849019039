import React, { FC } from 'react';
import { Box } from '@mui/material';
import styled from 'styled-components';
import { NftListPage } from '../../../../Nft';
import { ArtistListPage } from '../../../List';
import { SmartContractListPage } from '../../SmartContract/List';
import { GalleryListPage } from '../galleryList';


export const ListContent = styled(Box)`
  border-top: 1px solid ${(props) => props.theme.palette.text.disabled};
  padding-top: ${(props) => props.theme.spacing(34)}; ;
`;

interface IGalleryListProps {
  galleryState: string;
  isContractCreated?: boolean | undefined;
}

export const ArtistDetailGalleryListPage: FC<IGalleryListProps> = ({ galleryState, isContractCreated }: IGalleryListProps) => {

  return (
    <ListContent>
      {
        galleryState === 'PROFILE_NFTS' ?
          <NftListPage title='' /> :
          galleryState === 'PROFILE_GALLERY' ?
            <GalleryListPage /> :
            galleryState === 'Artist_Home' ?
              <ArtistListPage title='' /> :
              <SmartContractListPage isContractCreated={isContractCreated} />

      }
    </ListContent>
  );
};
