// Dependencies
import React, { FC, useMemo } from 'react';
import { Card, CardHeader, Grid, Stack, Typography } from '@mui/material';

// Types
import { CURRENCY } from '../../../shared/enums';

// Styles
import * as S from './styles';

// Constants
const data = [
  {
    label: 'Deposits',
    value: 56935881,
    color: 'warning',
    currency: CURRENCY.DOLLAR
  },
  {
    label: 'Withdraws',
    value: 8611352,
    color: 'error',
    currency: CURRENCY.DOLLAR
  },
  {
    label: 'Expenses',
    value: 1690420,
    color: 'success',
    currency: CURRENCY.DOLLAR
  }
];

// Create wallets
const Wallets: FC = () => {
  // Get wallets
  const wallets = useMemo(() => {
    let max = 0;

    for (let i = 0; i < data.length; i++) {
      const value = data[i].value;
      if (value > max) {
        max = value;
      }
    }

    return data.map((d) => ({
      ...d,
      percent: d.value / max
    }));
  }, []);

  // Return wallets
  return (
    <Card sx={{ height: '100%' }}>
      <CardHeader title="Prepaid crypto wallets" sx={{ mb: 24 }} />
      <Grid container spacing={12} direction="row" alignItems="center" justifyContent="space-between">
        <Grid xs={12} sm={6} item>
          <Stack spacing={12}>
            {wallets.map(({ label, value, color, currency }, index) => (
              <Stack key={index} spacing={4}>
                <Typography variant="overline" color={`${color}.main`}>
                  {label}
                </Typography>
                <Typography variant="headline" color={`${color}.main`}>
                  {currency}
                  {value?.toLocaleString()}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Grid xs={12} sm={6} item>
          <S.Chart>
            {wallets.map(({ value, label, color, currency, percent }, index) => (
              <S.Item key={index}>
                <Typography variant="overline" color={`${color}.main`}>
                  {currency}
                  {value?.toLocaleString()}
                </Typography>
                <S.Bar color={color} percent={percent} />
                <Typography variant="label" color="text.disabled">
                  {label}
                </Typography>
              </S.Item>
            ))}
          </S.Chart>
        </Grid>
      </Grid>
    </Card>
  );
};

// Export wallets
export default Wallets;
