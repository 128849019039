// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../constants';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { ArrowLeftIcon, CreateArtistIcon, EyeIcon, RecycleIcon, UpdateIcon } from '../../../assets/icons';

import { Form, Formik, FormikHelpers } from 'formik';
import { Dropzone, SwitchField } from '../../../components';
import * as Yup from 'yup';
import { getObjectUrl } from '../../../utils/common';
import { ArtistApi } from '../../../apis';
import { IArtist, IFile } from '../../../shared/types';
const isValidUrl = (url) => {
  try {
      new URL(url);
  } catch (e) {
      return false;
  }
  return true;
};

const validationSchema = Yup.object().shape({
  name: Yup.string().max(50, 'Please enter less than 50 characters').required('Name is required!'),
  // thumbnail: Yup.object().required('Thumbnail is required!'),
  email: Yup.string().matches(/([@])/, 'Insert correct email').required('email is required!'),
  bio: Yup.string().required('BIO is required!'),
  id: Yup.string(),
  website: Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required('Website address is required!'),
  spotify: Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required('Spotify is required!'),
  twitter: Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required('Twitter address is required!'),
  facebook: Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required('Facebook address is required!'),
  instagram: Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required('Instagram  is required!'),
  discord: Yup.string().test('is-url-valid', 'URL is not valid', (value) => isValidUrl(value)).required('Discord is required!'),
  // apiKey: Yup.string().required('API key is required!'),
  // paypal: Yup.string().required('Paypal is required!'),
  ethereumWallet: Yup.string().matches(/^(0x){1}[0-9a-fA-F]{40}$/i, 'Please Enter valide Ethereum Wallet Address'),
  casperWallet: Yup.string().matches(/^(0x){1}[0-9a-fA-F]{40}$/i, 'Please Enter valide Casper Wallet Address')
});
const initialValues = {
  name: '',
  thumbnail: null as unknown as IFile,
  email: '',
  bio: '',
  id: '',
  website: '',
  spotify: '',
  twitter: '',
  facebook: '',
  instagram: '',
  discord: '',
  apiKey: '',
  paypal: '',
  ethereumWallet: '',
  casperWallet: '',
  isFeatured: false
};

export const ArtistNewPage: FC = () => {
  const navigate = useNavigate();
  const [artist, setArtist] = useState<IArtist>(initialValues);
  const [loading, setLoading] = useState<boolean>(false);
  const { id } = useParams<{ id: string }>();

  //useEffect
  useEffect(() => {
    if (id) {
      setLoading(true);
      ArtistApi.read(`${id}`).then(res => {
        setArtist(res.artist);
        setLoading(false);
      }).catch(e => {
        setLoading(false);
        console.log('error', e);
      });
    }
  }, []);


  const handleClickBackBtn = () => {
    navigate(-1);
  };

  const handleRemove = () => {
    ArtistApi.remove(id as string)
      .then(() => {
        navigate(ROUTES.ARTIST.LIST);
      })

      .catch((err) => {
        console.log('🚀 ~ file: index.tsx:96 ~ .then ~ err', err);

      });
  };

  const handleSubmit = async (values, { setSubmitting }: FormikHelpers<any>) => {
    setSubmitting(true);
    try {
      const artist = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        artist.append(key, value as string);
      });

      if (id) {
        await ArtistApi.update(`${id}`, artist as unknown as IArtist);
        navigate('/artists');
      } else {
        await ArtistApi.create(artist as unknown as IArtist);
        navigate('/artists');
      }
      setSubmitting(false);
    } catch (e) {
      console.log('error', e);
      setSubmitting(false);
    }
  };

  const handleImageUpload = (files, field: string, setFieldValue: any) => {
    if (files && files.length > 0) {
      setFieldValue(field, files[0]);
    }
  };

  const handlePreview = () => {
    navigate(ROUTES.ARTIST.DETAIL.replace(':id', `${id}`));
  };

  // Mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Card>
      {loading ? (
        <Box height={300} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : (
        <Formik initialValues={artist} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ handleSubmit, handleBlur, handleChange, setFieldValue, values, errors, touched, isSubmitting }) => {
            console.log(values);
            return (
              <Form onSubmit={handleSubmit}>
                <CardHeader
                  title={
                    <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleClickBackBtn}>
                      Back
                    </Button>
                  }
                  action={
                    id ? isMobile ?
                      <Stack direction="row" spacing={16}>
                        <IconButton onClick={handleRemove}><RecycleIcon /></IconButton>
                        <IconButton type="submit"><UpdateIcon /></IconButton>
                        <IconButton onClick={handlePreview}><EyeIcon
                        /></IconButton>
                      </Stack>
                      :
                      <Stack direction="row" spacing={16}>
                        <Button variant="outlined" startIcon={<RecycleIcon />} onClick={handleRemove}>
                          Remove this artist
                        </Button>
                        <Button variant="contained" type="submit" startIcon={isSubmitting ? <CircularProgress size={20} /> : <UpdateIcon />}>
                          Update
                        </Button>
                        <Button variant="contained" startIcon={<EyeIcon />} onClick={handlePreview}>
                          Preview
                        </Button>
                      </Stack>
                      : (
                        <Button
                          variant="contained"
                          type="submit"
                          disabled={isSubmitting}
                          startIcon={isSubmitting ? <CircularProgress size={20} /> : <CreateArtistIcon />}
                        >
                          Create this artist
                        </Button>
                      )
                  }
                  sx={{ mb: 24 }}
                />
                <Typography mb={72} color="text.secondary" variant="title">
                  {id ? 'Edit' : 'Add new'} artist
                </Typography>
                <Grid container>
                  <Grid xs={12} sm={6} lg={3} sx={{ mb: 16 }}>
                    <FormControl fullWidth error={!!(errors.thumbnail && touched.thumbnail)} disabled={isSubmitting}>
                      {/* @ts-ignore */}
                      <InputLabel variant="alone">PROFILE PICTURE</InputLabel>
                      <Dropzone
                        label="Drag image here to upload"
                        accept={['.png', '.jpg', '.svg']}
                        onDrop={(files) => handleImageUpload(files, 'thumbnail', setFieldValue)}
                        preview={values.thumbnail instanceof File ?
                          getObjectUrl(values.thumbnail) : values.thumbnail ? values.thumbnail : false}
                      />
                      <FormHelperText>
                        {errors.thumbnail && touched.thumbnail && String(errors.thumbnail)}
                      </FormHelperText>
                    </FormControl>
                  </Grid>
                </Grid>
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={24}>
                  <Stack spacing={16} sx={{ flex: 1 }}>
                    <TextField
                      fullWidth
                      name="name"
                      label="NAME"
                      disabled={isSubmitting}
                      value={values.name}
                      error={!!(errors.name && touched.name)}
                      helperText={errors.name && touched.name && String(errors.name)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      name="email"
                      label="EMAIL"
                      disabled={isSubmitting}
                      value={values.email}
                      error={!!(errors.email && touched.email)}
                      helperText={errors.email && touched.email && String(errors.email)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      name="id"
                      label="ID"
                      disabled={isSubmitting}
                      value={values.id}
                      error={!!(errors.id && touched.id)}
                      helperText={errors.id && touched.id && String(errors.id)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      multiline
                      minRows={5}
                      name="bio"
                      label="BIO"
                      disabled={isSubmitting}
                      value={values.bio}
                      error={!!(errors.bio && touched.bio)}
                      helperText={errors.bio && touched.bio && String(errors.bio)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      name="website"
                      label="WEBSITE"
                      disabled={isSubmitting}
                      value={values.website}
                      error={!!(errors.website && touched.website)}
                      helperText={errors.website && touched.website && String(errors.website)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      name="spotify"
                      label="SPOTIFY"
                      disabled={isSubmitting}
                      value={values.spotify}
                      error={!!(errors.spotify && touched.spotify)}
                      helperText={errors.spotify && touched.spotify && String(errors.spotify)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      name="twitter"
                      label="TWITTER"
                      disabled={isSubmitting}
                      value={values.twitter}
                      error={!!(errors.twitter && touched.twitter)}
                      helperText={errors.twitter && touched.twitter && String(errors.twitter)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      name="facebook"
                      label="FACEBOOK"
                      disabled={isSubmitting}
                      value={values.facebook}
                      error={!!(errors.facebook && touched.facebook)}
                      helperText={errors.facebook && touched.facebook && String(errors.facebook)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      name="instagram"
                      label="INSTAGRAM"
                      disabled={isSubmitting}
                      value={values.instagram}
                      error={!!(errors.instagram && touched.instagram)}
                      helperText={errors.instagram && touched.instagram && String(errors.instagram)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <TextField
                      fullWidth
                      name="discord"
                      label="DISCORD"
                      disabled={isSubmitting}
                      value={values.discord}
                      error={!!(errors.discord && touched.discord)}
                      helperText={errors.discord && touched.discord && String(errors.discord)}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    <FormControl fullWidth>
                      {/* @ts-ignore */}
                      <InputLabel variant="alone">Status</InputLabel>
                      <SwitchField
                        value={values.isFeatured}
                        disabled={isSubmitting}
                        onBlur={handleBlur}
                        checked={values.isFeatured}
                        onChange={(e) => {
                          setFieldValue('isFeatured', (e as React.ChangeEvent<HTMLInputElement>).target.checked);
                        }}
                      />
                    </FormControl>
                    {isMobile && (
                      <>
                        <TextField
                          fullWidth
                          name="apiKey"
                          label="API KEY"
                          disabled={isSubmitting}
                          value={values.apiKey}
                          error={!!(errors.apiKey && touched.apiKey)}
                          helperText={errors.apiKey && touched.apiKey && String(errors.apiKey)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <TextField
                          fullWidth
                          name="paypal"
                          label="PAYPAL"
                          disabled={isSubmitting}
                          value={values.paypal}
                          error={!!(errors.paypal && touched.paypal)}
                          helperText={errors.paypal && touched.paypal && String(errors.paypal)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <TextField
                          fullWidth
                          name="ethereumWallet"
                          label="ETHEREUM WALLET"
                          disabled={isSubmitting}
                          value={values.ethereumWallet}
                          error={!!(errors.ethereumWallet && touched.ethereumWallet)}
                          helperText={errors.ethereumWallet && touched.ethereumWallet && String(errors.ethereumWallet)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                        <TextField
                          fullWidth
                          name="casperWallet"
                          label="CASPER WALLET"
                          disabled={isSubmitting}
                          value={values.casperWallet}
                          error={!!(errors.casperWallet && touched.casperWallet)}
                          helperText={errors.casperWallet && touched.casperWallet && String(errors.casperWallet)}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </>
                    )}
                  </Stack>
                  {!isMobile && (
                    <Stack spacing={16} sx={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        name="apiKey"
                        label="API KEY"
                        disabled={isSubmitting}
                        value={values.apiKey}
                        error={!!(errors.apiKey && touched.apiKey)}
                        helperText={errors.apiKey && touched.apiKey && String(errors.apiKey)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        fullWidth
                        name="paypal"
                        label="PAYPAL"
                        disabled={isSubmitting}
                        value={values.paypal}
                        error={!!(errors.paypal && touched.paypal)}
                        helperText={errors.paypal && touched.paypal && String(errors.paypal)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        fullWidth
                        name="ethereumWallet"
                        label="ETHEREUM WALLET"
                        disabled={isSubmitting}
                        value={values.ethereumWallet}
                        error={!!(errors.ethereumWallet && touched.ethereumWallet)}
                        helperText={errors.ethereumWallet && touched.ethereumWallet && String(errors.ethereumWallet)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        fullWidth
                        name="casperWallet"
                        label="CASPER WALLET"
                        disabled={isSubmitting}
                        value={values.casperWallet}
                        error={!!(errors.casperWallet && touched.casperWallet)}
                        helperText={errors.casperWallet && touched.casperWallet && String(errors.casperWallet)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </Stack>
                  )}
                </Stack>
              </Form>
            );
          }}
        </Formik>
      )}
    </Card>
  );
};
