import React, { useState } from 'react';
import { Avatar, Box, IconButton, Stack, useMediaQuery, useTheme } from '@mui/material';

import SidebarItem, { ISidebarItem } from './Item';

import { ROUTES } from '../../../constants';
import {
  ArrowLeftMIcon,
  ArtistIcon,
  ArtistOutlinedIcon,
  BellIcon,
  CategoryIcon,
  CategoryOutlinedIcon,
  DashboardIcon,
  DashboardOutlinedIcon,
  DocumentIcon,
  DocumentOutlinedIcon,
  EventIcon,
  EventOutlinedIcon,
  Logo,
  MenuIcon,
  MenuMobileIcon,
  OrderIcon,
  OrderOutlinedIcon,
  SettingsIcon,
  SongIcon,
  SongOutlinedIcon,
  TokenIcon,
  TokenOutlinedIcon,
  UserIcon,
  UserOutlinedIcon
} from '../../../assets/icons';

const SIDEBAR_LIST: ISidebarItem[] = [
  {
    path: ROUTES.HOME,
    label: 'Dashboard',
    icon: {
      default: <DashboardOutlinedIcon />,
      active: <DashboardIcon />
    }
  },
  {
    path: ROUTES.ARTIST.LIST,
    label: 'Artists',
    icon: {
      default: <ArtistOutlinedIcon />,
      active: <ArtistIcon />
    }
  },
  {
    path: ROUTES.SONG.LIST,
    label: 'Songs',
    icon: {
      default: <SongOutlinedIcon />,
      active: <SongIcon />
    }
  },
  {
    path: ROUTES.VIDEO.LIST,
    label: 'Videos',
    icon: {
      default: <SongOutlinedIcon />,
      active: <SongIcon />
    }
  },
  {
    path: ROUTES.IMAGE.LIST,
    label: 'Images',
    icon: {
      default: <SongOutlinedIcon />,
      active: <SongIcon />
    }
  },
  {
    path: ROUTES.EVENT.LIST,
    label: 'Events',
    icon: {
      default: <EventOutlinedIcon />,
      active: <EventIcon />
    }
  },
  {
    label: 'Documents',
    icon: {
      default: <DocumentOutlinedIcon />,
      active: <DocumentIcon />
    },
    children: [
      {
        label: 'Main Menu',
        icon: {
          default: <ArrowLeftMIcon />,
          active: <ArrowLeftMIcon />
        }
      },
      {
        path: ROUTES.DOCUMENT.ARTICLE.LIST,
        label: 'Articles',
        icon: {
          default: <DocumentOutlinedIcon />,
          active: <DocumentIcon />
        }
      },
      {
        path: ROUTES.DOCUMENT.FAQ.DASHBOARD,
        label: 'Faq',
        icon: {
          default: <DocumentOutlinedIcon />,
          active: <DocumentIcon />
        }
      },
      {
        path: ROUTES.DOCUMENT.TICKET.LIST,
        label: 'Tickets',
        icon: {
          default: <DocumentOutlinedIcon />,
          active: <DocumentIcon />
        }
      },
      {
        path: ROUTES.DOCUMENT.ABOUT_US,
        label: 'About Us',
        icon: {
          default: <DocumentOutlinedIcon />,
          active: <DocumentIcon />
        }
      },
      {
        path: ROUTES.DOCUMENT.TERMS,
        label: 'Terms',
        icon: {
          default: <DocumentOutlinedIcon />,
          active: <DocumentIcon />
        }
      },
      {
        path: ROUTES.DOCUMENT.PRIVACY_POLICY,
        label: 'Privacy Policy',
        icon: {
          default: <DocumentOutlinedIcon />,
          active: <DocumentIcon />
        }
      }
    ]
  },
  {
    path: ROUTES.CATEGORIES,
    label: 'Categories',
    icon: {
      default: <CategoryOutlinedIcon />,
      active: <CategoryIcon />
    }
  },
  {
    path: ROUTES.USER.LIST,
    label: 'Users',
    icon: {
      default: <UserOutlinedIcon />,
      active: <UserIcon />
    }
  },
  {
    path: ROUTES.ORDER.LIST,
    label: 'Orders',
    icon: {
      default: <OrderOutlinedIcon />,
      active: <OrderIcon />
    }
  },
  {
    path: ROUTES.NFT.LIST,
    label: 'NFT Tokens',
    icon: {
      default: <TokenOutlinedIcon />,
      active: <TokenIcon />
    }
  }
];

import * as S from './styles';

interface SidebarProps {
  window?: () => Window;
  isCollapsed: boolean;
  setCollapse: (isCollapsed: boolean) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ isCollapsed, setCollapse }: SidebarProps) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [subMenuIdx, setSubMenuIdx] = useState<number>(-1);

  if (isTablet) {
    // Render in mobile
    return (
      <S.DrawerBar open={isCollapsed} variant="temporary" onClose={() => setCollapse(false)}>
        <S.Sidebar active={isCollapsed ? true : undefined}>
          <S.Brand active={isTablet ? true : undefined}>
            <S.Logo active={isCollapsed ? true : undefined} to={ROUTES.HOME}>
              <Logo />
            </S.Logo>
            <IconButton
              sx={{
                display: isTablet ? 'flex' : 'none'
              }}
              onClick={() => setCollapse(false)}
            >
              <MenuMobileIcon />
            </IconButton>
          </S.Brand>
          {isTablet && subMenuIdx > -1
            ? SIDEBAR_LIST[subMenuIdx].children?.map((item, index) => (
              <SidebarItem
                key={index}
                menuIdx={index}
                item={item}
                subMenuIdx={subMenuIdx}
                setSubMenuIdx={setSubMenuIdx}
                isCollapsed={isCollapsed}
              />
            ))
            : SIDEBAR_LIST.map((item, index) => (
              <SidebarItem
                key={index}
                menuIdx={index}
                item={item}
                subMenuIdx={subMenuIdx}
                setSubMenuIdx={setSubMenuIdx}
                isCollapsed={isCollapsed}
              />
            ))}
        </S.Sidebar>
        <Box
          sx={(theme) => ({
            padding: theme.spacing(12, 28)
          })}
        >
          <S.Bottom active={isTablet ? true : undefined}>
            <IconButton>
              <Avatar src="/images/avatar.png" />
            </IconButton>
            <Stack direction="row">
              <IconButton>
                <BellIcon />
              </IconButton>
              <IconButton>
                <SettingsIcon />
              </IconButton>
            </Stack>
          </S.Bottom>
        </Box>
      </S.DrawerBar>
    );
  } else {
    // Render in desktop
    return (
      <S.Sidebar active={isCollapsed ? true : undefined}>
        <S.Brand>
          <IconButton onClick={() => setCollapse(!isCollapsed)}>
            <MenuIcon />
          </IconButton>
          <S.Logo to={ROUTES.HOME} active={isCollapsed ? true : undefined}>
            <Logo />
          </S.Logo>
        </S.Brand>
        {!isCollapsed && subMenuIdx > -1
          ? SIDEBAR_LIST[subMenuIdx].children?.map((item, index) => (
            <SidebarItem
              key={index}
              menuIdx={index}
              item={item}
              subMenuIdx={subMenuIdx}
              setSubMenuIdx={setSubMenuIdx}
              isCollapsed={isCollapsed}
            />
          ))
          : SIDEBAR_LIST.map((item, index) => (
            <SidebarItem
              key={index}
              menuIdx={index}
              item={item}
              subMenuIdx={subMenuIdx}
              setSubMenuIdx={setSubMenuIdx}
              isCollapsed={isCollapsed}
            />
          ))}
      </S.Sidebar>
    );
  }
};

export default Sidebar;
