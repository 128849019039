// Dependencies
import React, { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
// Apis
import { ContractApi } from '../../../apis';

// Global constants
import { PAGE_LIMIT } from '../../../constants';

// Interfaces
import { Order } from '../../../shared/types';

// Components
import { IColumn, Table } from '../../../components';
//get row thumbnail icon
// const getRowThumbnail = (value) => {
//   return `${REACT_APP_API_ASSETS_SERVER}/thumbnail/${value.icon}`;
// };

export const PINATA_GATEWAY_URL = 'https://gateway.pinata.cloud/ipfs';

// Constants
const columns: IColumn[] = [
  {
    field: 'icon',
    title: 'Thumbnail Avatar',
    render: (row) => {
      if (row.ipfsImageHash) {
        const hash = row.ipfsImageHash.split('//')[1];
        return <img src={`${PINATA_GATEWAY_URL}/${hash}`} alt="thumbnail" height={50} />;
      } else {
        return <img src={row.uri} alt="thumbnail" height={50} />;
      }
    }
  },
  {
    field: 'txHash',
    title: 'Token Address',
    render: (row) => {
      return (
        <Tooltip title={row.details.transactionHash} placement="bottom" arrow>
          {row.details.transactionHash && row.details.to ? <a href={row.details.chain == 'CSPR' ? `https://div3.in/deploys/${row.details.transactionHash}` : `https://sepolia.etherscan.io/tx/${row.details.transactionHash}`} target="_blank">
            {row.details.transactionHash.slice(0, 4)}...{row.details.transactionHash.slice(-4)}
          </a> : <p>---</p>}
        </Tooltip>
      );
    }
  },
  {
    field: 'owner',
    title: 'Owner',
    render: (row) => (
      <Tooltip title={row.details.to} placement="bottom" arrow>
        {row.details.transactionHash && row.details.to ? <a href={row.details.chain == 'CSPR' ? `https://div3.in/accounts/${row.details.to}` : `https://sepolia.etherscan.io/address/${row.details.to}`} target="_blank">
          {row.details.to.slice(0, 4)}...{row.details.to.slice(-4)}
        </a> : <p>---</p>}

      </Tooltip>
    )
  },
  {
    field: 'createdAt',
    title: 'Listed',
    render: (row) => moment(row.createdAt).format('HH:mm - DD MMMM YYYY')
  },
  {
    field: 'updatedAt',
    title: 'Updated',
    render: (row) => moment(row.updatedAt).format('HH:mm - DD MMMM YYYY')
  }
];

interface INftListProps {
  title?: string;
}

// Export users page
export const NftListPage: FC<INftListProps> = ({ title = 'NFT Tokens' }: INftListProps) => {
  // States
  const [pageNumber, setPageNumber] = useState<number>(0);
  // const [totalPage, setTotalPage] = useState<number>(-1);
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [order, setOrder] = useState<Order>(Order.Desc);
  const [nfts, setNfts] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get navigate from hook
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();

  // Page change handler
  const handleChangePage = (value: number) => {
    setPageNumber(value);
  };

  // Sort handler
  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? Order.Desc : Order.Asc);
    setOrderBy(property);
  };

  // Fetch products
  const fetchNfts = () => {
    setIsLoading(true);
    ContractApi.readAllNftsByArtist({
      query: {
        artistId: id
      },
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT,
        sort: {
          [orderBy]: order
        }
      }
    })
      .then((res) => {
        setNfts(res);
        // setTotalPage(res.pagination.total);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  // On pageNumber, order and orderBy changed
  useEffect(() => {
    fetchNfts();
  }, [pageNumber, order, orderBy]);

  // View handler
  const handleView = (id: string) => {
    const nft = nfts.nfts.find((nft) => nft.details.transactionHash === id);
    navigate(`/nfts/${nft?.details.transactionHash}?tokenId=${nft?.tokenId}`);
  };

  // Edit handler
  const handleDelete = (id: string) => {
    if (id) {
      // Add your code
    }
  };

  // Edit handler
  const handleEdit = (id: string) => {
    if (id) {
      // Add your code
    }
  };

  // Return users page
  return (
    <Table
      title={title}
      order={order}
      data={nfts?.nfts}
      columns={columns}
      orderBy={orderBy}
      pageNumber={pageNumber}
      totalPage={nfts?.pagination?.total}
      isLoading={isLoading}
      onSort={handleSort}
      onPageChange={handleChangePage}
      onView={handleView}
      onDelete={handleDelete}
      onEdit={handleEdit}
    />
  );
};
