// Dependencies
import React, { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { IconButton, Stack, Divider, InputAdornment, Typography, useTheme, useMediaQuery } from '@mui/material';

import {
  AlignCenterIcon, AlignLeftIcon, AlignRightIcon, AlignThroughIcon,
  FontBoldIcon, FontItalicIcon, FontThroughIcon, FontUnderlineIcon,
  HeaderArchieveIcon,
  HeaderRecycleIcon,
  HeaderStarIcon, InsertLinkIcon, ListIcon,
  MenuIcon,
  SearchIcon,
  PowerIcon,
} from '../../../../assets/icons';

// Styles
import * as S from './styles';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardPeriod } from '../../../../store/selectors';
import { setDashboardPeriod } from '../../../../store/actions/dashboard.actions';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  setArchieveTicketBtnClicked,
  setDelTicketBtnClicked,
  setFavTicketBtnClicked
} from '../../../../store/actions/header.actions';
import { ACCESS_TOKEN_KEY, ROUTES } from '../../../../constants';
import { setAccount } from '../../../../store/actions/auth.actions';

// Interfaces
interface IHeaderProps {
  isMobile: boolean;
  isCollapsed: boolean;
  setCollapse: (isCollapsed: boolean) => void;
}

const TOGGLE_1 = [
  {
    label: '1h',
    value: '1h'
  },
  {
    label: '24h',
    value: '24h'
  },
  {
    label: '7d',
    value: '7d'
  },
  {
    label: '30d',
    value: '30d'
  },
  {
    label: '3m',
    value: '3m'
  },
  {
    label: '6m',
    value: '6m'
  },
  {
    label: '1y',
    value: '1y'
  },
  {
    label: 'All',
    value: 'All'
  }
];

const TOGGLE_3 = [
  {
    label: <FontBoldIcon />,
    value: 'bold'
  },
  {
    label: <FontItalicIcon />,
    value: 'italic'
  },
  {
    label: <FontUnderlineIcon />,
    value: 'underline'
  },
  {
    label: <FontThroughIcon />,
    value: 'throughout'
  },
];
const TOGGLE_4 = [
  {
    label: <InsertLinkIcon />,
    value: 'link'
  },
];
const TOGGLE_5 = [
  {
    label: <ListIcon />,
    value: 'list'
  },
];

const TOGGLE_6 = [
  {
    label: <AlignLeftIcon />,
    value: 'align-left'
  },
  {
    label: <AlignCenterIcon />,
    value: 'align-center'
  },
  {
    label: <AlignRightIcon />,
    value: 'align-right'
  },
  {
    label: <AlignThroughIcon />,
    value: 'align-through'
  }
];

const DesktopHeader: FC<IHeaderProps> = ({ isMobile, isCollapsed, setCollapse }) => {
  // States
  const [toggle2, setToggle2] = useState<string>('');
  const [toggle3, setToggle3] = useState<string>('');
  const [toggle4, setToggle4] = useState<string>('');
  const [toggle5, setToggle5] = useState<string>('');
  const [toggle6, setToggle6] = useState<string>('');

  const navigate = useNavigate();
  const location = useLocation();
  // States
  const theme = useTheme();
  const isLargeTablet = useMediaQuery(theme.breakpoints.down('lg'));

  const period = useSelector(getDashboardPeriod);
  const dispatch = useDispatch();

  const handleChangeToggle1 = (_: MouseEvent<HTMLElement>, value: string) => {
    dispatch(setDashboardPeriod(value));
  };

  const handleChangeToggle2 = (_: MouseEvent<HTMLElement>, value: string) => {
    setToggle2(value);
  };

  const handleChangeToggle3 = (_: MouseEvent<HTMLElement>, value: string) => {
    setToggle3(value);
  };

  const handleChangeToggle4 = (_: MouseEvent<HTMLElement>, value: string) => {
    setToggle4(value);
  };

  const handleChangeToggle5 = (_: MouseEvent<HTMLElement>, value: string) => {
    setToggle5(value);
  };

  const handleChangeToggle6 = (_: MouseEvent<HTMLElement>, value: string) => {
    setToggle6(value);
  };

  const handleClickFavorite = () => {
    dispatch(setFavTicketBtnClicked(true));
  };

  const handleClickArchieve = () => {
    dispatch(setArchieveTicketBtnClicked(true));
  };

  const handleClickDelete = () => {
    dispatch(setDelTicketBtnClicked(true));
  };

  const handleLogOut = () => {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    dispatch(setAccount(null));
    navigate(ROUTES.AUTH.SIGN_IN);
  };

  // Right header bar
  const headerRightBar = useMemo(() => {
    if (
      location.pathname == '/' ||
      location.pathname == '/songs' ||
      location.pathname == '/events' ||
      location.pathname == '/articles' ||
      location.pathname == '/tickets'
    ) {
      return (
        <Stack direction="row" spacing={8} divider={<Divider orientation="vertical" flexItem />}>
          {location.pathname === '/tickets' && (
            <Stack direction="row">
              <IconButton>
                <HeaderStarIcon onClick={handleClickFavorite} />
              </IconButton>
              <IconButton>
                <HeaderArchieveIcon onClick={handleClickArchieve} />
              </IconButton>
              <IconButton>
                <HeaderRecycleIcon onClick={handleClickDelete} />
              </IconButton>
            </Stack>
          )}
        </Stack>
      );
    }
    return undefined;
  }, [location.pathname]);

  // Dashboard header bar
  const headerDashboardMenus = useMemo(() => {
    if (location.pathname === '/') {
      return (
        <>
          <S.ToggleGroup exclusive value={period} onChange={handleChangeToggle1}>
            {TOGGLE_1.map(({ label, value }, index) => (
              <S.Toggle key={index} value={value}>
                <S.ToggleLabel>{label}</S.ToggleLabel>
              </S.Toggle>
            ))}
          </S.ToggleGroup>
        </>
      );
    }
    console.log(location.pathname);
    if (location.pathname.includes(ROUTES.DOCUMENT.ARTICLE.LIST) &&
        location.pathname != ROUTES.DOCUMENT.ARTICLE.LIST) {
      return (
        <Stack direction="row">
          <S.ToggleGroup value={toggle3} onChange={handleChangeToggle3}>
            {TOGGLE_3.map(({ label, value }, index) => (
              <S.Toggle key={index} value={value}>
                <S.ToggleLabel>{label}</S.ToggleLabel>
              </S.Toggle>
            ))}
          </S.ToggleGroup>
          <S.ToggleGroup exclusive value={toggle4} onChange={handleChangeToggle4}>
            {TOGGLE_4.map(({ label, value }, index) => (
              <S.Toggle key={index} value={value}>
                <S.ToggleLabel>{label}</S.ToggleLabel>
              </S.Toggle>
            ))}
          </S.ToggleGroup>
          <S.ToggleGroup exclusive value={toggle5} onChange={handleChangeToggle5}>
            {TOGGLE_5.map(({ label, value }, index) => (
              <S.Toggle key={index} value={value}>
                <S.ToggleLabel>{label}</S.ToggleLabel>
              </S.Toggle>
            ))}
          </S.ToggleGroup>
          <S.ToggleGroup exclusive value={toggle6} onChange={handleChangeToggle6}>
            {TOGGLE_6.map(({ label, value }, index) => (
              <S.Toggle key={index} value={value}>
                <S.ToggleLabel>{label}</S.ToggleLabel>
              </S.Toggle>
            ))}
          </S.ToggleGroup>
        </Stack>
      );
    }
    return undefined;
  }, [location.pathname, period, toggle2, toggle3, toggle4, toggle5, toggle6, handleChangeToggle1, handleChangeToggle2, handleChangeToggle3, handleChangeToggle4, handleChangeToggle5, handleChangeToggle6]);

  useEffect(() => {
    const inlineElm = document.getElementsByClassName('rdw-inline-wrapper')[0] as any;
    if (inlineElm) {
      if (toggle3.includes('bold')) {
        if (inlineElm.children[0].getAttribute('aria-selected') === 'false') {
          inlineElm.children[0].click();
        }
      } else {
        if (inlineElm.children[0].getAttribute('aria-selected') === 'true') {
          inlineElm.children[0].click();
        }
      }
    }
  }, [ toggle3 ]);

  useEffect(() => {
    const listElm = document.getElementsByClassName('rdw-list-wrapper')[0] as any;
    if (listElm) {
      if (toggle5 === 'list') {
        if (listElm.children[0].getAttribute('aria-selected') === 'false') {
          listElm.children[0].click();
        }
      } else {
        if (listElm.children[0].getAttribute('aria-selected') === 'true') {
          listElm.children[0].click();
        }
      }
    }
  }, [toggle5]);

  useEffect(() => {
    const alignElm = document.getElementsByClassName('rdw-text-align-wrapper')[0] as any;
    switch (toggle6) {
      case 'align-left': {
        alignElm.children[0].click();
        break;
      }

      case 'align-center': {
        alignElm.children[1].click();
        break;
      }

      case 'align-right': {
        alignElm.children[2].click();
        break;
      }

      case 'align-through': {
        alignElm.children[3].click();
        break;
      }
    }
  }, [toggle6]);

  return (
    <S.Header>
      <Stack direction="row" spacing={8}>
        <IconButton
          onClick={() => setCollapse(!isCollapsed)}
          sx={{
            display: isCollapsed || !isMobile ? 'none' : 'flex',
            mt: 5
          }}
        >
          <MenuIcon />
        </IconButton>
        <S.SearchContainer>
          <S.SearchInputWrapper>
            <S.SearchInput
              placeholder="Search"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
            <S.SearchResult>
              <Typography>Search result number 1</Typography>
              <Typography>Search result number 2</Typography>
              <Typography>Search result number 3</Typography>
              <Typography>Search result number 4</Typography>
            </S.SearchResult>
          </S.SearchInputWrapper>
        </S.SearchContainer>
        {!isLargeTablet && headerDashboardMenus}
      </Stack>
      <Stack direction="row" spacing={8} divider={headerRightBar && <Divider orientation="vertical" flexItem />}>
        {headerRightBar}
        <Stack direction="row" spacing={8}>
            <IconButton>
              <PowerIcon onClick={handleLogOut}/>
            </IconButton>
        </Stack>
      </Stack>
    </S.Header>
  );
};

export default DesktopHeader;
