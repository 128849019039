// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Avatar, Stack, Typography } from '@mui/material';
import moment from 'moment';

// Types
import { Order } from '../../../shared/types';

// Components
import { ConfirmDialog, IColumn, Table } from '../../../components';

// Apis
import { UsersApi } from '../../../apis';

// Global constants
import { PAGE_LIMIT, ROUTES } from '../../../constants';
import { getUsersAction } from '../../../store/actions/users.actions';
import { useDispatch, useSelector } from 'react-redux';
import { getUsersSelector } from '../../../store/selectors';
import { useNavigate } from 'react-router-dom';

// Export users page
export const UserListPage: FC = () => {
  // States
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [order, setOrder] = useState<Order>(Order.Desc);
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const users = useSelector(getUsersSelector);

  // Fetch users
  const fetchUsers = () => {
    setIsLoading(true);
    UsersApi.readAll({
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT,
        sort: {
          [orderBy]: order
        }
      }
    })
      .then((res) => {
        dispatch(getUsersAction(res.users));
        setTotalPage(res.pagination.total);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  // Page change handler
  const handleChangePage = (pageN: number) => {
    setPageNumber(pageN);
  };

  // Sort handler
  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? Order.Desc : Order.Asc);
    setOrderBy(property);
  };

  // Delete handler
  const handleDelete = (id: string) => {
    setSelectedUserId(id);
    setVisibleDeleteConfirmDialog(true);
  };

  // Delete confirm dialog
  const handleDeleteConfirmed = () => {
    UsersApi.remove(selectedUserId as string)
      .then(() => fetchUsers())
      .catch((err) => console.log(err));
  };

  // Edit handler
  const handleEdit = (id: string) => {
    navigate(ROUTES.USER.DETAIL.replace(':id', id));
  };

  // View handler
  const handleView = (id: string) => {
    navigate(ROUTES.USER.DETAIL.replace(':id', id));
  };

  // Constants
  const columns: IColumn[] = [
    {
      field: 'name',
      title: 'Name',
      render: (row) => (
        <Stack direction="row" spacing={12} alignItems="center">
          <Avatar src={row.avatarUrl} alt="user-image" />
          <Typography variant="body2">{row.name}</Typography>
        </Stack>
      )
    },
    {
      field: 'email',
      title: 'Email'
    },
    {
      field: 'country',
      title: 'County'
    },
    {
      field: 'totalSpent',
      title: 'Total Spent'
    },
    {
      field: 'createdAt',
      title: 'Joined',
      render: (row) => moment(row.createdAt).format('HH:mm - DD MMMM YYYY')
    }
  ];

  // New handler
  const handleNew = () => {
    navigate(ROUTES.USER.NEW);
  };

  // On pageNumber, pageLimit, order, orderBy changed
  useEffect(() => {
    fetchUsers();
  }, [pageNumber, order, orderBy]);

  // Return users page
  return (
    <>
      <Table
        title="Users"
        data={users}
        columns={columns}
        totalPage={totalPage}
        pageNumber={pageNumber}
        onPageChange={handleChangePage}
        order={order}
        orderBy={orderBy}
        isLoading={isLoading}
        onNew={handleNew}
        onSort={handleSort}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onView={handleView}
      />
      <ConfirmDialog
        description="Are you sure to delete?"
        visible={visibleDeleteConfirmDialog}
        setVisible={setVisibleDeleteConfirmDialog}
        onConfirmed={handleDeleteConfirmed}
      />
    </>
  );
};
