// Dependencies
import { FC } from 'react';
import { Box, Card, CardHeader, Typography } from '@mui/material';
import { VectorMap } from '@react-jvectormap/core';
import { worldMill }  from '@react-jvectormap/world';

// Styles
import * as S from './styles';
import { SquareBox } from '../../../assets/icons';

// Create traffic map
const TrafficMap: FC = () => {
  const  series = [
    {
      values:{ DE:1000 },
      traffic:'high'
    },
    {
      //@ts-ignore
      values: { BR :1000 },
      traffic: 'lowest'
    },
    {
    //@ts-ignore
    values: { AU :1000 },
    traffic: 'low'
   },
    {
    //@ts-ignore
    values: { IN :1000 },
    traffic: 'highest'
  },
    {
      //@ts-ignore
      values: { US :1000 },
      //@ts-ignore
      traffic: 'lowest'
    },
    {
      //@ts-ignore
      values: { BR :1000 },
      traffic: 'low'
    },
    {
      //@ts-ignore
      values: { RU :1000 },
      traffic: 'high'
    },
    {
      //@ts-ignore
      values: { CA :1000 },
      traffic: 'lowest'
    },
    {
      //@ts-ignore
      values: { GB :1000 },
      traffic: 'highest'
    },
    {
      //@ts-ignore
      values: { GE :1000 },
      traffic: 'highest'
    }];
  const getScale =(value) =>{
    if(value === 'highest'){
      return ['#00008B'];
    }else if(value === 'high'){
      return ['#00FF00'];
    }else if(value === 'low'){
      return ['#fff444'];
    }else if(value === 'lowest'){
      return ['#FF0000'];
    }
  };
  // Return traffic map
  return (
    <Card>
    <CardHeader title="Traffic map" sx={{ mb: 24 }} />
      <VectorMap
        containerClassName="vector-map"
        className="vector-map"
        style={{
          height: '600px',
        }}
        map={worldMill}
        zoomOnScroll={false}
        backgroundColor="transparent"
        regionStyle={{
          initial: {
            fill: '#e9ecef',
            // @ts-ignore
            'fill-opacity': 0.8,
            stroke: 'none',
            'stroke-width': 0,
            'stroke-opacity': 1,
          },
          hover: {
            fill: '#dee2e6',
            // @ts-ignore
            'fill-opacity': 0.8,
            cursor: 'pointer',
          },
          selected: {
            fill: 'yellow',
          },
        }}
        series={{
          //@ts-ignore
          regions: series.map(item => {
            const scale = getScale(item.traffic);
            return {
              values: item.values,
              //@ts-ignore
              scale: scale,
              normalizeFunction: 'polynomial',
              attribute: 'fill',
            };
          }),
        }}
      />
      <Box display={'flex'} flexDirection={'row'} justifyContent={'space-evenly'} width={'30%'} ml={'30%'} alignSelf={'center'} alignItems={'center'}>
        <SquareBox height={15} width={15} color={'#00008B'}/>
        <Typography>Highest</Typography>
        <SquareBox height={15} width={15} color={'#00FF00'}/>
        <Typography>High</Typography>
        <SquareBox height={15} width={15} color={'#fff444'}/>
        <Typography>Low</Typography>
        <SquareBox height={15} width={15} color={'#FF0000'}/>
        <Typography>Lowest</Typography>
      </Box>
    </Card>
  );
};

// Export traffic map
export default TrafficMap;
