// Dependencies
import React, { FC } from 'react';
import { Button, Stack, Typography } from '@mui/material';

// Icons
import { PlusIcon } from '../../../assets/icons';

// Styles
import * as S from './styles';

// Types
interface TableToolbarProps {
  title?: string;
  isLoading: boolean;
  onNew?: () => void;
}

// Create table toolbar component
const TableToolbar: FC<TableToolbarProps> = ({ title, isLoading, onNew }) => {
  // Return table toolbar component
  return (
    <S.TableToolbar>
      <Typography variant="title" color="text.secondary">
        {title}
      </Typography>
      <Stack direction="row" spacing={2}>
        {onNew && (
          <Button startIcon={<PlusIcon />} disabled={isLoading} onClick={onNew}>
            Add new
          </Button>
        )}
      </Stack>
    </S.TableToolbar>
  );
};

export default TableToolbar;
