import { Card, Box } from '@mui/material';
import styled from 'styled-components';

export const Profile = styled(Box)`
  border-bottom: 1px solid ${(props) => props.theme.palette.text.disabled};
  padding: ${(props) => props.theme.spacing(16, 0)};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ProfileValue = styled(Card)`
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ProfileValueContent = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
