// Dependencies
import { FC } from 'react';
import { Button, Stack } from '@mui/material';

// Components
import { AudioPlayer } from './AudioPlayer';

// Icons
import { ArrowLeftIcon, PublishIcon } from '../../assets/icons';

// Types
import { IProduct } from '../../shared/types';

// Constants
import { API_SERVER, REACT_APP_API_ASSETS_SERVER } from '../../constants';

// Styles
import * as S from './styles';
import { PRODUCT_TYPE } from '../../shared/enums';

// Interfaces
interface IPreviewModalProps {
  open: boolean;
  product: IProduct;
  onClose: () => void;
  svg?: string | any;
}

// Export preview dialog
export const PreviewDialog: FC<IPreviewModalProps> = ({ open, product,svg, onClose }) => {
  const { name, type, preview, thumbnail, statement, sign } = product;
  const getObjectUrl = (file) => {
    const url = URL.createObjectURL(file);
    return url.toString();
  };
  // Return preview dialog
  return (
    <S.PreviewDialog fullWidth maxWidth="xl" open={open} onClose={onClose}>
      <Stack mb={24} direction="row" justifyContent="center" spacing={16}>
        <Button variant="outlined" startIcon={<ArrowLeftIcon />} onClick={onClose}>
          Back
        </Button>
        <Button startIcon={<PublishIcon />}>Publish</Button>
      </Stack>
      <S.Content>
        {type === PRODUCT_TYPE.SONG && (
          <S.Song>
            <S.Summary>
              <S.Backdrop />
              <S.SongName>{name}</S.SongName>
              {preview?.filename && <AudioPlayer src={`${API_SERVER}/${preview.filename}`} />}
              {/*<S.Category>{category.join(', ')}</S.Category>*/}
              {thumbnail?.filename && (
                <S.Thumbnail src={`${API_SERVER}/${thumbnail?.filename}`} alt={thumbnail?.filename} />
              )}
            </S.Summary>
            <S.Information>
              <S.Label>Artist's Statement.</S.Label>
              <S.Statement>{statement}</S.Statement>
              {sign && <S.Sign src={`${API_SERVER}/${sign.filename}`} alt="sign" />}
            </S.Information>
          </S.Song>
        )}
        {type === PRODUCT_TYPE.VIRTUAL_EVENT && (
          <img src={  svg ? svg : thumbnail?.fieldname
            ? `${REACT_APP_API_ASSETS_SERVER}/${thumbnail?.fieldname}/${thumbnail?.filename}`
            : getObjectUrl(thumbnail)} alt="thumbnail"/>
        )}
      </S.Content>
    </S.PreviewDialog>
  );
};
