// Dependencies
import React, { FC, FocusEvent, useEffect, useState } from 'react';
import { CardContent, Grid, IconButton, useMediaQuery, useTheme } from '@mui/material';

// Apis
import { UsApi } from '../../../apis';

// Icons
import { DeleteIcon, PlusIcon } from '../../../assets/icons';

// Styles
import * as S from './styles';

// Interfaces
import { IUs } from '../../../shared/types';
import { ConfirmDialog } from '../../../components';

// Interface of display about us data
interface IData extends IUs {
  isActive: boolean;
}

// Export about us page
export const AboutUsPage: FC = () => {
  // States
  const [data, setData] = useState<IData[]>();
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>();
  const [selectedAboutUsIndex, setSelectedAboutUsDisplayIndex] = useState<number>(0);

  // Mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // initial about data
  const initialData = [
    {
      title: 'Title',
      content: 'Description here...',
      isActive: true
    },
    {
      title: 'Title',
      content: 'Description here...',
      isActive: false
    }
  ];

  // Fetch data
  const fetchData = () => {
    UsApi.readAll()
      .then((res) => {
        const newData = res.us.map((d) => ({
          ...d,
          isActive: true
        }));
        if (newData.length === 0) {
          setData([...initialData]);
        } else if (newData.length % 2 === 1) {
          setData([
            ...newData,
            {
              title: 'Title',
              content: 'Description here...',
              isActive: false
            }
          ]);
        } else {
          setData(newData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Add row handler
  const handleAddRow = (index: number) => {
    if (data) {
      data.splice(
        index + 2,
        0,
        {
          title: 'Title',
          content: 'Description here...',
          isActive: true
        },
        {
          title: 'Title',
          content: 'Description here...',
          isActive: false
        }
      );

      setData([...data]);
    }
  };

  // Add column handler
  const handleAddColumn = (index: number) => {
    if (data) {
      data[index].isActive = true;
      setData([...data]);
    }
  };

  // Update data from back-end
  const handleBlurUpdated = (requestData, index) => {
    const newData = { ...requestData, isActive: true };
    setData(
      data?.map((item, idx) => {
        if (index == idx) {
          return newData;
        }
        return item;
      })
    );
  };

  // Blur handler
  const handleBlur = (e: FocusEvent<HTMLSpanElement>, type: 'title' | 'content', index: number, id?: string) => {
    const text = e.currentTarget.innerText;
    if (id) {
      UsApi.update(id, {
        [type]: text
      })
        .then((res) => {
          handleBlurUpdated(res.body, index);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      if (data) {
        const item = {
          title: type === 'title' ? text : data[index].title,
          content: type === 'content' ? text : data[index].content
        };
        UsApi.create(item)
          .then((res) => {
            handleBlurUpdated(res.body, index);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  // Delete confirm handler
  const handleDeleteConfirmed = () => {
    if (selectedProductId) {
      UsApi.remove(selectedProductId)
        .then(() => {
          handleUpdateDisplayData(selectedAboutUsIndex);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      handleUpdateDisplayData(selectedAboutUsIndex);
    }
  };

  // Delete handler
  const handleDelete = (index: number, id?: string) => {
    setSelectedProductId(id);
    setSelectedAboutUsDisplayIndex(index);
    setVisibleDeleteConfirmDialog(true);
  };

  const handleUpdateDisplayData = (index: number) => {
    if (!data) return;
    let upData = data;
    if (index % 2 === 0) {
      upData = data?.filter((d, idx) => idx !== index);
      if (upData[index].isActive) {
        upData?.splice(index + 1, 0, {
          title: 'Title',
          content: 'Description here...',
          isActive: false
        });
      } else {
        upData = upData?.filter((d, idx) => idx !== index);
      }
    } else {
      upData[index].isActive = false;
    }
    setData(upData.map((d) => d));
  };

  // On mounted
  useEffect(() => {
    fetchData();
  }, []);

  // Return about us page
  return (
    <Grid container columns={24} spacing={10}>
      {data &&
        data.map(({ id, title, content, isActive }, index) => (
          <Grid key={`item-${index}`} item sm={12} xs={24}>
            {(isActive || index % 2 === 0) && (
              <S.CardView>
                <S.CardTitle>
                  <S.Title
                    variant="title"
                    color="text.secondary"
                    contentEditable
                    onBlur={(e) => handleBlur(e, 'title', index, id)}
                  >
                    {title}
                  </S.Title>
                  <IconButton color="secondary" onClick={() => handleDelete(index, id)}>
                    <DeleteIcon />
                  </IconButton>
                </S.CardTitle>
                <CardContent>
                  <S.Description
                    variant="body1"
                    color="text.secondary"
                    contentEditable
                    onBlur={(e) => handleBlur(e, 'content', index, id)}
                  >
                    {content}
                  </S.Description>
                </CardContent>
              </S.CardView>
            )}
            {index % 2 === 0 && (
              <S.AddButton onClick={() => handleAddRow(index)} variant="outlined" startIcon={<PlusIcon />}>
                Add new section
              </S.AddButton>
            )}
            {index % 2 === 1 && !isActive && !isMobile && (
              <S.ColAddButton variant="outlined" startIcon={<PlusIcon />} onClick={() => handleAddColumn(index)} />
            )}
          </Grid>
        ))}
      <ConfirmDialog
        description="Are you sure to delete?"
        visible={visibleDeleteConfirmDialog}
        setVisible={setVisibleDeleteConfirmDialog}
        onConfirmed={handleDeleteConfirmed}
      />
    </Grid>
  );
};
