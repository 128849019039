// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { ConfirmDialog, IColumn, Table } from '../../../../components';

import moment from 'moment';

// Material
import { Avatar, Button, Chip, Grid, Stack, Switch, TextField, Typography } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import { PAGE_LIMIT, REACT_APP_API_ASSETS_SERVER, ROUTES } from '../../../../constants';

// Components
import { DetailDialog } from '../../../../components/Dialog';

// styles
import * as S from './styles';

// Types
import { ArticleApi } from '../../../../apis';
import { IArticle, Order } from '../../../../shared/types';
import { ARTICLE_STATUS } from '../../../../shared/enums';

export const ArticleListPage: FC = () => {
  // States
  const [article, setArticle] = useState<IArticle[]>([]);
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>();
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [order, setOrder] = useState<Order>(Order.Desc);
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [visiblePreviewDialog, setVisiblePreviewDialog] = useState(false);
  const [previewArticle, setPreviewArticle] = useState<IArticle>();
  // Get navigate from hook
  const navigate = useNavigate();

  // Fetch datas
  const fetchData = () => {
    ArticleApi.readAll({
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT,
        sort: {
          [orderBy]: order
        }
      }
    })
      .then((res) => {
        setArticle(res.articles);
        setTotalPage(res.pagination.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // New handler
  const handleNew = () => {
    navigate(ROUTES.DOCUMENT.ARTICLE.NEW);
  };

  // Edit handler
  const handleEdit = (id = '') => {
    navigate(ROUTES.DOCUMENT.ARTICLE.EDIT.replace(':id', id));
  };

  // View handler
  const handleView = (id: string) => {
    ArticleApi.read(id)
      .then((res) => {
        setPreviewArticle(res.article);
        setVisiblePreviewDialog(true);
      })
      .catch((err) => console.log(err));
  };

  // Delete confirm handler
  const handleDeleteConfirmed = () => {
    ArticleApi.remove(selectedProductId as string)
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  // Delete handler
  const handleDelete = (id: string) => {
    setSelectedProductId(id);
    setVisibleDeleteConfirmDialog(true);
  };

  // Page change handler
  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  // Sort handler
  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? Order.Desc : Order.Asc);
    setOrderBy(property);
  };

  // Feature handler
  const handleFeature = (id: string, isFeature: boolean) => {
    ArticleApi.toggleFeature(id, isFeature)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    fetchData();
  }, [pageNumber, order, orderBy]);

  // Constants
  const columns: IColumn[] = [
    {
      title: 'Name',
      render: (article: IArticle) => (
        <Stack direction="row" spacing={12} alignItems="center">
          <Avatar src={`${REACT_APP_API_ASSETS_SERVER}/${article.thumbnail?.filename}`} alt="product-image" />
          <Typography variant="body2">{article.title}</Typography>
        </Stack>
      )
    },
    {
      field: 'author',
      title: 'Author',
      render: (row) => (
        <Stack direction="row" spacing={1}>
          <Typography variant="body2">{row.author}</Typography>
        </Stack>
      )
    },
    {
      title: 'Featured',
      render: (row) => (
        <Switch
          defaultChecked={row?.isFeatured}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFeature(row?.id as string, e.target.checked)}
        />
      )
    },
    {
      field: 'createdAt',
      title: 'Create At',
      render: (row) => moment(row.createdAt).format('HH:mm - DD MMMM YYYY')
    },
    {
      field: 'updatedAt',
      title: 'Updated At',
      render: (row) => moment(row.updatedAt).format('HH:mm - DD MMMM YYYY')
    },
    {
      field: 'category',
      title: 'Category',
      render: (row) => (
        <Stack direction="row" spacing={1}>
          <Chip
            label={row.status}
            color={
              row.status == ARTICLE_STATUS.DRAFT
                ? 'info'
                : row.status == ARTICLE_STATUS.PUBLISHED
                ? 'success'
                : 'warning'
            }
          />
        </Stack>
      )
    }
  ];

  return (
    <>
      <Table
        title="Articles"
        data={article}
        columns={columns}
        totalPage={totalPage}
        pageNumber={pageNumber}
        onPageChange={handlePageChange}
        order={order}
        orderBy={orderBy}
        onSort={handleSort}
        onNew={handleNew}
        onDelete={handleDelete}
        onEdit={handleEdit}
        onView={handleView}
      />
      <ConfirmDialog
        description="Are you sure to delete?"
        visible={visibleDeleteConfirmDialog}
        setVisible={setVisibleDeleteConfirmDialog}
        onConfirmed={handleDeleteConfirmed}
      />
      <DetailDialog
        title={previewArticle?.title}
        open={visiblePreviewDialog}
        onClose={() => setVisiblePreviewDialog(false)}
        actions={
          <>
            <Button variant="text" size="medium" onClick={() => setVisiblePreviewDialog(false)}>
              Cancel
            </Button>
            <Button variant="contained" size="medium" onClick={() => handleEdit(previewArticle?.id)}>
              Edit
            </Button>
          </>
        }
      >
        <Grid xs={6} spacing={12}>
          <S.PreviewContentItem>
            <img src={`${REACT_APP_API_ASSETS_SERVER}/${previewArticle?.thumbnail?.filename}`} alt='' />
          </S.PreviewContentItem>
        </Grid>

        <Grid xs={6} spacing={12}>
          <S.PreviewContentItem>
            <Typography variant="body1">{previewArticle?.author}</Typography>
            <Typography variant="label">{moment(previewArticle?.createdAt).format('yyyy/MM/DD hh:mm:ss')}</Typography>
          </S.PreviewContentItem>
        </Grid>
        <Grid item xs={6}>
          <S.PreviewContentItem>
            <Typography variant="body1">{previewArticle?.isFeatured ? 'Featured' : 'Not featured'}</Typography>            <Chip
              label={previewArticle?.status}
              color={
                previewArticle?.status == ARTICLE_STATUS.DRAFT
                  ? 'info'
                  : previewArticle?.status == ARTICLE_STATUS.PUBLISHED
                  ? 'success'
                  : 'warning'
              }
            />
          </S.PreviewContentItem>
        </Grid>
        <Grid xs={12}>
          <S.PreviewContentItem>
            <TextField
              fullWidth
              multiline
              minRows={5}
              name="description"
              label="Article"
              value={previewArticle?.description}
              disabled
            />
          </S.PreviewContentItem>
        </Grid>
      </DetailDialog>
    </>
  );
};
