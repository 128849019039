import styled from 'styled-components';
import { FormControlLabel } from '@mui/material';

export const SwitchField = styled(FormControlLabel)`
  && {
    justify-content: space-between;
    border-radius: 8px;
    padding: ${(props) => props.theme.spacing(11, 16)};
    border: 1px solid ${(props) => props.theme.palette.text.secondary};
    margin: ${(props) => props.theme.spacing(0)};
    color: ${(props) => props.theme.palette.text.secondary};

    &.Mui-disabled {
      border-color: rgba(0, 0, 0, 0.26);
    }
  }
`;
