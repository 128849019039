import { Box } from '@mui/material';
import styled from 'styled-components';

export const PreviewHeader = styled(Box)`
  display: flex;
  align-items: center;
`;

export const PreviewContentItem = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${(props) => props.theme.spacing(12, 12)};
  img {
    width: 100%;
    height: 240px;
  }
`;

export const PreviewContent = styled(Box)`
  padding: ${(props) => props.theme.spacing(6, 6)};
`;

export const PreviewContentContext = styled(Box)`
  padding: ${(props) => props.theme.spacing(6, 6)};
`;
