import { FormControlLabel, FormGroup } from '@mui/material';
import styled from 'styled-components';

export const Group = styled(FormGroup)`
  border-radius: 8px;
  padding-top: ${(props) => props.theme.spacing(16)};
  border: 1px solid ${(props) => props.theme.palette.text.secondary};

  &.Mui-error {
    border-color: ${(props) => props.theme.palette.error.main};
  }
`;

export const ControlLabel = styled(FormControlLabel)`
  && {
    padding: 16px 12px;
    border-radius: 5px;
    box-sizing: content-box;
    margin: ${(props) => props.theme.spacing(0)};
    color: ${(props) => props.theme.palette.text.secondary};
    background-color: ${(props) =>
      props.checked ? props.theme.palette.action.active : props.theme.palette.common.white};

    &:hover {
      background: ${(props) => (props.checked ? props.theme.palette.action.active : props.theme.palette.action.hover)};
    }

    .MuiFormControlLabel-label {
      ${(props) => props.theme.typography.body2};
      color: ${(props) => (props.checked ? props.theme.palette.text.primary : props.theme.palette.text.secondary)};
    }
  }
`;
