import styled from 'styled-components';
import { Box, ButtonBase, Stack, Typography } from '@mui/material';

export const Icon = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin-right: ${(props) => props.theme.spacing(8)};
  flex-shrink: 0;
`;

export const Label = styled(Typography)<{ isCollapsed?: boolean }>`
  text-transform: none;
  width: ${(props) => (props.isCollapsed ? '200px' : '0px')};
  transition: all ease-in 0.5s;
  overflow: hidden;
  text-align: left;
`;

export const Item = styled(ButtonBase)<{ active?: boolean; child?: boolean; isCollapsed?: boolean }>`
  && {
    width: ${(props) => (props.isCollapsed ? (props.child ? '228px' : '240px') : '56px')};
    border-radius: 20px;
    text-decoration: none;
    padding: ${(props) => props.theme.spacing(8, 16)};
    margin-bottom: ${(props) => props.theme.spacing(4)};
    justify-content: flex-start;
    transition: all ease-in 0.5s;
    color: ${(props) => props.theme.palette.action.accent};
    background-color: ${(props) =>
      props.active ? props.theme.palette.action[props.child ? 'active' : 'accent'] : 'transparent'};

    ${Icon} {
      color: ${(props) => props.theme.palette.text.primary};
    }

    &:hover {
      background-color: ${(props) =>
        props.active ? props.theme.palette.action.accent : props.theme.palette.action.hover};

      ${Icon} {
        color: ${(props) => props.theme.palette.text.primary};
      }

      ${Label} {
        color: ${(props) => props.theme.palette.text.primary};
      }
    }

    ${Icon} {
      color: ${(props) => (props.active ? props.theme.palette.text.primary : props.theme.palette.text.secondary)};
    }

    ${Label} {
      color: ${(props) => (props.active ? props.theme.palette.text.primary : props.theme.palette.text.secondary)};
    }
  }
`;

export const Children = styled(Stack)`
  && {
    flex-direction: column;
    align-items: flex-end;
  }
`;
