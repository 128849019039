// Dependencies
import React, { Fragment, useEffect, useState } from 'react';
import {
    Box,
    Button,
    CircularProgress,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Stack,
    Select,
    TextField,
    OutlinedInput,
    ListItemText,
    Typography, useTheme, useMediaQuery
} from '@mui/material';

import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// Components
import { Dropzone, SwitchField } from '../../../components';

// Apis
import { ArtistApi, CategoriesApi, GalleryApi } from '../../../apis';

// Types
import { ICategory, IFile, IProduct } from '../../../shared/types';
import { CURRENCY, PRODUCT_TYPE } from '../../../shared/enums';

// Constants
import { REACT_APP_API_ASSETS_SERVER, ROUTES } from '../../../constants';

const initialValues: IProduct = {
    type: PRODUCT_TYPE.VIDEO,
    name: '',
    thumbnail: null as unknown as IFile,
    category: [],
    description: '',
    price: 0,
    currency: CURRENCY.DOLLAR,
    sku: '',
    statement: '',
    artistId: '',
    gallery: ''
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    }
};

export const VideoForm = ({ setGallery, gallery, artist, product, handleBlur, handleChange, setFieldValue, values, errors, touched, isSubmitting, setArtist, setProduct }) => {
    console.log('🚀 ~ file: Form.tsx:64 ~ VideoForm ~ values:', values);

    const [artists, setArtists] = useState<any>([]);
    const [galleries, setGalleries] = useState<any>([]);
    const [categories, setCategories] = useState<ICategory[]>([]);

    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [searchParams] = useSearchParams();
    const artistId = searchParams.get('artistId');
    const galleryId = searchParams.get('galleryId') || product.galleryId;

    // Mobile
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    useEffect(() => {
        CategoriesApi.readAll()
            .then((res) => {
                setCategories(res.categories);
            })
            .catch((err) => console.log(err));
        ArtistApi.readAll()
            .then((res) => {
                setArtists(res.artists);
            })
            .catch((err) => {
                console.log('err :', err);
            });
    }, []);

    const handleMusicUpload = (files, setFieldValue: any) => {
        if (files && files.length > 0) {
            const file = files[0];
            const blob = new Blob([file], { type: file.type });
            const newBlob = blob.slice(0, blob.size / 10);
            const previewFile = new File([newBlob], `preview-${file.name}`, {
                type: file.type
            });

            setFieldValue('video', file);
            // setFieldValue('preview', previewFile);
        }
    };

    const handleImageUpload = (files, field: string, setFieldValue: any) => {
        if (files && files.length > 0) {
            setFieldValue(field, files[0]);
        }
    };


    const handleCancel = () => {
        navigate(ROUTES.VIDEO.LIST);
    };

    const renderSelectedCategories = (selected) => {
        const selectedCategoriesName = selected.map((cat) => {
            const category = categories.find((c) => (typeof cat === 'string' ? c.id === cat : c.id === cat.id));
            return category?.name;
        });

        return selectedCategoriesName.join(', ');
    };

    const getObjectUrl = (file) => {
        const url = URL.createObjectURL(file);
        return url.toString();
    };

    useEffect(() => {
        if (values.artistId && !artistId) {
            GalleryApi.readAll(values.artistId)
                .then((res) => {
                    setGalleries(res.galleries);
                    if (galleryId || product.galleryId) {
                        const galleryFound = res.galleries.find((gallery) => gallery._id == galleryId);
                        setGallery(galleryFound);
                        setFieldValue('gallery', galleryFound.name);
                    }
                })
                .catch((err) => {
                    console.log('err :', err);
                });
        }
    }, [values.artistId]);

    useEffect(() => {

        if (artistId) {
            ArtistApi.read(artistId)
                .then((res) => {
                    setArtist(res.artist);
                    setProduct({ ...initialValues, artistId: res.artist.name });
                })
                .catch((err) => {
                    console.log('err :', err);
                });
            GalleryApi.readAll(artistId)
                .then((res) => {
                    setGalleries(res.galleries);
                    if (artistId && galleryId || product.galleryId) {
                        const galleryFound = res.galleries.find((gallery) => gallery._id == galleryId);
                        setGallery(galleryFound);
                        setFieldValue('gallery', galleryFound.name);
                    }
                })
                .catch((err) => {
                    console.log('err :', err);
                });
        }
    }, [artistId]);
    return (
        <Fragment>
            <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={24}>
                <Stack spacing={16} sx={{ flex: 1 }}>
                    {
                        isMobile && (
                            <Grid container spacing={24}>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth>
                                        {/* @ts-ignore */}
                                        <InputLabel variant="alone">Music</InputLabel>
                                        <Dropzone
                                            label="Drag video here to upload"
                                            accept={['.mp4']}
                                            onDrop={(files) => handleMusicUpload(files, setFieldValue)}
                                            // audioSrc={
                                            //     values.video?.fieldname
                                            //         ? `${REACT_APP_API_ASSETS_SERVER}/${values.video?.filename}`
                                            //         : values.video
                                            //             ? getObjectUrl(values.video)
                                            //             : ''
                                            // }
                                        />
                                        {values.video && (
                                            <Typography sx={{ marginLeft: 1 }}>
                                                <b>Video File:</b> {values.video.filename || values.video.name}
                                            </Typography>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl
                                        fullWidth
                                        error={!!(errors.thumbnail && touched.thumbnail)}
                                        disabled={isSubmitting}
                                        hiddenLabel
                                    >
                                        {/* @ts-ignore */}
                                        <InputLabel variant="alone">Thumbnail</InputLabel>
                                        <Dropzone
                                            label="Drag image here to upload"
                                            accept={['.png', '.jpg', '.svg']}
                                            onDrop={(files) => handleImageUpload(files, 'thumbnail', setFieldValue)}
                                            preview={
                                                values.thumbnail?.fieldname
                                                    ? `${REACT_APP_API_ASSETS_SERVER}/${values.thumbnail?.fieldname}/${values.thumbnail?.filename}`
                                                    : values.thumbnail
                                                        ? getObjectUrl(values.thumbnail)
                                                        : false
                                            }
                                        />
                                        <FormHelperText>
                                            {errors.thumbnail && touched.thumbnail && String(errors.thumbnail)}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth error={!!(errors.icon && touched.icon)} disabled={isSubmitting}>
                                        {/* @ts-ignore */}
                                        <InputLabel variant="alone">Icon</InputLabel>
                                        <Dropzone
                                            label="Drag image here to upload"
                                            accept={['.png', '.jpg', '.svg']}
                                            onDrop={(files) => handleImageUpload(files, 'icon', setFieldValue)}
                                            preview={
                                                values.icon?.fieldname
                                                    ? `${REACT_APP_API_ASSETS_SERVER}/${values.icon?.fieldname}/${values.icon?.filename}`
                                                    : values.icon
                                                        ? getObjectUrl(values.icon)
                                                        : false
                                            }
                                        />
                                        <FormHelperText>{errors.icon && touched.icon && String(errors.icon)}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth error={!!(errors.sign && touched.sign)} disabled={isSubmitting}>
                                        {/* @ts-ignore */}
                                        <InputLabel variant="alone">Signature</InputLabel>
                                        <Dropzone
                                            label="Drag image here to upload"
                                            accept={['.png', '.jpg', '.svg']}
                                            onDrop={(files) => handleImageUpload(files, 'sign', setFieldValue)}
                                            preview={
                                                values.sign?.fieldname
                                                    ? `${REACT_APP_API_ASSETS_SERVER}/${values.sign?.fieldname}/${values.sign?.filename}`
                                                    : values.sign
                                                        ? getObjectUrl(values.sign)
                                                        : false
                                            }
                                        />
                                        <FormHelperText>{errors.sign && touched.sign && String(errors.sign)}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )
                    }
                    <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        value={values.name}
                        error={!!(errors.name && touched.name)}
                        helperText={errors.name && touched.name && String(errors.name)}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        fullWidth
                        multiline
                        minRows={5}
                        name="description"
                        label="Description"
                        value={values.description}
                        error={!!(errors.description && touched.description)}
                        helperText={errors.description && touched.description && String(errors.description)}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
                        <InputLabel id="category">Category</InputLabel>
                        <Select
                            labelId="category"
                            id="category"
                            name="category"
                            multiple
                            fullWidth
                            value={values.category}
                            onChange={handleChange}
                            input={<OutlinedInput label="Category" />}
                            renderValue={(selected) => renderSelectedCategories(selected)}
                            MenuProps={MenuProps}
                            error={!!(errors.category && touched.category)}
                        >
                            {categories.map((category) => (
                                <MenuItem key={category.id} value={category.id}>
                                    <ListItemText primary={category.name} />
                                </MenuItem>
                            ))}
                        </Select>
                        {errors.category && touched.category && (
                            <FormHelperText error>{String(errors.category)}</FormHelperText>
                        )}
                    </FormControl>
                    <Box>
                        <Grid container spacing={16}>
                            <Grid item xs={9}>
                                <TextField
                                    fullWidth
                                    name="price"
                                    label="Price"
                                    type="number"
                                    value={values.price}
                                    error={!!(errors.price && touched.price)}
                                    helperText={errors.price && touched.price && String(errors.price)}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <TextField
                                    select
                                    fullWidth
                                    name="currency"
                                    label="Currency"
                                    value={values.currency}
                                    error={!!(errors.currency && touched.currency)}
                                    helperText={errors.currency && touched.currency && String(errors.currency)}
                                    disabled={isSubmitting}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                >
                                    <MenuItem value="$">$</MenuItem>
                                    <MenuItem value="€">€</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>
                    <TextField
                        name="sku"
                        label="SKU"
                        fullWidth
                        value={values.sku}
                        error={!!(errors.sku && touched.sku)}
                        helperText={errors.sku && touched.sku && String(errors.sku)}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    <TextField
                        fullWidth
                        multiline
                        minRows={5}
                        name="statement"
                        label="Statement"
                        value={values.statement}
                        error={!!(errors.statement && touched.statement)}
                        helperText={errors.statement && touched.statement && String(errors.statement)}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {values.thumbnail && location.pathname.includes('/edit') &&
                        <>
                            <TextField
                                name="thumbnailIpfs"
                                label="Thumbnail Ipfs"
                                fullWidth
                                value={values.thumbnail.ipfsHash}
                                disabled={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <TextField
                                name="musicIpfs"
                                label="Music Ipfs"
                                fullWidth
                                value={values.music.ipfsHash}
                                disabled={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />

                            <TextField
                                name="iconIpfs"
                                label="Icon Ipfs"
                                fullWidth
                                value={values.icon.ipfsHash}
                                disabled={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                            <TextField
                                name="signIpfs"
                                label="Sign Ipfs"
                                fullWidth
                                value={values.sign.ipfsHash}
                                disabled={true}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            />
                        </>}

                    {!id &&
                        <FormControl fullWidth>
                            {/* @ts-ignore */}
                            <InputLabel variant="alone">PREPARE PRODUCT AS NFT</InputLabel>

                        </FormControl>
                    }
                    <FormControl fullWidth>
                        {/* @ts-ignore */}
                        <InputLabel variant="alone">Video</InputLabel>
                        <SwitchField
                            value={values.isFeatured}
                            disabled={isSubmitting}
                            onBlur={handleBlur}
                            checked={values.isFeatured}
                            onChange={(e) => {
                                setFieldValue('isFeatured', (e as React.ChangeEvent<HTMLInputElement>).target.checked);
                            }}
                        />
                    </FormControl>
                </Stack>
                {!isMobile && (
                    <Stack sx={{ flex: 1 }}>
                        <Grid container spacing={24}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth>
                                    {/* @ts-ignore */}
                                    <InputLabel variant="alone">Video</InputLabel>
                                    <Dropzone
                                        label="Drag video here to upload"
                                        accept={['.mp4']}
                                        onDrop={(files) => handleMusicUpload(files, setFieldValue)}
                                        audioSrc={
                                            values.music?.fieldname
                                                ? `${REACT_APP_API_ASSETS_SERVER}/${values.music?.filename}`
                                                : values.music
                                                    ? getObjectUrl(values.music)
                                                    : ''
                                        }
                                    />
                                    {values.music && (
                                        <Typography sx={{ marginLeft: 1 }}>
                                            <b>Video File:</b> {values.music.filename || values.music.name}
                                        </Typography>
                                    )}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    fullWidth
                                    error={!!(errors.thumbnail && touched.thumbnail)}
                                    disabled={isSubmitting}
                                    hiddenLabel
                                >
                                    {/* @ts-ignore */}
                                    <InputLabel variant="alone">Thumbnail</InputLabel>
                                    <Dropzone
                                        label="Drag image here to upload"
                                        accept={['.png', '.jpg', '.svg']}
                                        onDrop={(files) => handleImageUpload(files, 'thumbnail', setFieldValue)}
                                        preview={
                                            values.thumbnail?.fieldname
                                                ? `${REACT_APP_API_ASSETS_SERVER}/${values.thumbnail?.fieldname}/${values.thumbnail?.filename}`
                                                : values.thumbnail
                                                    ? getObjectUrl(values.thumbnail)
                                                    : false
                                        }
                                    />
                                    <FormHelperText>
                                        {errors.thumbnail && touched.thumbnail && String(errors.thumbnail)}
                                    </FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth error={!!(errors.icon && touched.icon)} disabled={isSubmitting}>
                                    {/* @ts-ignore */}
                                    <InputLabel variant="alone">Icon</InputLabel>
                                    <Dropzone
                                        label="Drag image here to upload"
                                        accept={['.png', '.jpg', '.svg']}
                                        onDrop={(files) => handleImageUpload(files, 'icon', setFieldValue)}
                                        preview={
                                            values.icon?.fieldname
                                                ? `${REACT_APP_API_ASSETS_SERVER}/${values.icon?.fieldname}/${values.icon?.filename}`
                                                : values.icon
                                                    ? getObjectUrl(values.icon)
                                                    : false
                                        }
                                    />
                                    <FormHelperText>{errors.icon && touched.icon && String(errors.icon)}</FormHelperText>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth error={!!(errors.sign && touched.sign)} disabled={isSubmitting}>
                                    {/* @ts-ignore */}
                                    <InputLabel variant="alone">Signature</InputLabel>
                                    <Dropzone
                                        label="Drag image here to upload"
                                        accept={['.png', '.jpg', '.svg']}
                                        onDrop={(files) => handleImageUpload(files, 'sign', setFieldValue)}
                                        preview={
                                            values.sign?.fieldname
                                                ? `${REACT_APP_API_ASSETS_SERVER}/${values.sign?.fieldname}/${values.sign?.filename}`
                                                : values.sign
                                                    ? getObjectUrl(values.sign)
                                                    : false
                                        }
                                    />
                                    <FormHelperText>{errors.sign && touched.sign && String(errors.sign)}</FormHelperText>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <FormControl fullWidth size="small" sx={{ mt: '24px' }}>
                            <InputLabel id="artist">SELECT ARTIST</InputLabel>

                            <Select
                                labelId="artist"
                                id="artistId"
                                name="artistId"
                                fullWidth
                                disabled={artistId || product.artistId ? true : false}
                                value={artist ? artist.id : values.artistId}
                                onChange={(e) => {
                                    handleChange(e);
                                    setFieldValue('gallery', '');
                                }}
                                defaultValue='SOmething'
                                input={<OutlinedInput label="SELECT ARTIST" />}
                                MenuProps={MenuProps}
                                error={!!(errors.artistId && touched.artistId)}
                            >
                                {artists?.length > 0 ? artists.map((art) => {
                                    return (
                                        <MenuItem value={art.id}>{art.name}</MenuItem>
                                    );
                                })
                                    :
                                    <MenuItem value={values.artistId}>{values.artistId}</MenuItem>
                                }
                            </Select>
                            {errors.artistId && touched.artistId && (
                                <FormHelperText error>{String(errors.artistId)}</FormHelperText>
                            )}
                        </FormControl>
                        <FormControl fullWidth size="small" sx={{ mt: '16px' }}>
                            <InputLabel id="artist">SELECT GALLERY</InputLabel>
                            <Select
                                labelId="gallery"
                                id="gallery"
                                name="gallery"
                                fullWidth
                                disabled={gallery?._id ? true : false}
                                value={values.gallery}
                                onChange={handleChange}
                                input={<OutlinedInput label="SELECT GALLERY" />}
                                error={!!(errors.gallery && touched.gallery)}
                            >
                                {gallery?._id && <MenuItem value={values.gallery}>{values.gallery}</MenuItem>}
                                {!gallery?._id || product.galleryId && galleries.length > 0 ? galleries?.map((gallery) => {
                                    return (
                                        <MenuItem value={gallery.id}>{gallery.name}</MenuItem>
                                    );
                                }) : null}
                            </Select>
                            {errors.gallery && touched.gallery && (
                                <FormHelperText error>{String(errors.gallery)}</FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                )}
            </Stack>
            <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                <Stack direction="row" spacing={1}>
                    <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        disabled={isSubmitting}
                        startIcon={isSubmitting && <CircularProgress size={20} />}
                    >
                        {product ? 'Save' : 'Create'}
                    </Button>
                </Stack>
            </Box>
        </Fragment>
    );
};
