// Dependencies
import React, { Fragment, FC, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardHeader,
  CircularProgress,
  Typography,
} from '@mui/material';

import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

// Components
import { ConfirmDialog, PreviewDialog } from '../../../components';

// Apis
import { ProductsApi } from '../../../apis';

// Types
import { IFile, IProduct } from '../../../shared/types';
import { CURRENCY, PRODUCT_TYPE } from '../../../shared/enums';

// Icons
import { ArrowLeftIcon, EyeIcon } from '../../../assets/icons';

// Constants
import { ROUTES } from '../../../constants';

//Form
import { VideoForm } from './Form';

const initialValues: IProduct = {
  type: PRODUCT_TYPE.VIDEO,
  name: '',
  thumbnail: null as unknown as IFile,
  category: [],
  description: '',
  price: 0,
  currency: CURRENCY.DOLLAR,
  sku: '',
  statement: '',
  artistId: '',
  gallery: ''
};



export const NewVideoPage: FC = () => {
  const [product, setProduct] = useState<IProduct | undefined>(initialValues);
  const [isLoading, setLoading] = useState(false);
  const [artist, setArtist] = useState<any>('');
  const [gallery, setGallery] = useState<any>('');
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);
  const [visiblePreviewDialog, setVisiblePreviewDialog] = useState<boolean>(false);

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const artistId = searchParams.get('artistId');
  const galleryId = searchParams.get('galleryId');

  const isEditPage = {
    galleryValidation: false
  };

  if (location.pathname.includes('/videos') && location.pathname.includes('/edit')) {
    isEditPage.galleryValidation = true;
  }
  const validationSchema = Yup.object().shape({
    name: Yup.string().max(50, 'Please enter less than 50 characters').required('Name is required!'),
    // thumbnail: Yup.object().required('Thumbnail is required!'),
    category: Yup.array().required('Category is required!').min(1, 'Please select at least one of category'),
    price: Yup.number().min(0).required('Price is required!'),
    description: Yup.string().required('Description is required!'),
    currency: Yup.string()
      .matches(/([€$])/, 'Please select $ or €!')
      .required('Currency is required!'),
    sku: Yup.string(),
    gallery: !isEditPage.galleryValidation ? Yup.string().required('Gallery is required!') : Yup.string(),
    artistId: Yup.string().required('Artist is required!'),
  });


  const fetchProduct = () => {
    if (!id) return;
    if (isLoading) {
      // add your code
    }
    setLoading(true);
    ProductsApi.read(id)
      .then((res) => {
        const categoryIds = res.product.category.map((cat) => cat.id);
        setProduct({ ...res.product, category: categoryIds });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchProduct();
  }, [location.pathname]);

  const handleSubmit = (values: IProduct, { setSubmitting }: FormikHelpers<any>) => {

    const newProduct = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === 'category') {
        value.forEach((cat) => newProduct.append(key, cat));
      } else {
        newProduct.append(key, value);
      }
    });
    newProduct.delete('next');

    if (id && product) {
      if (_.isEqual(product?.thumbnail, values.thumbnail)) {
        newProduct.delete('thumbnail');
      }
      if (_.isEqual(product?.icon, values.icon)) {
        newProduct.delete('icon');
      }
      if (_.isEqual(product?.sign, values.sign)) {
        newProduct.delete('sign');
      }
      if (_.isEqual(product?.video, values.video)) {
        newProduct.delete('video');
        // newProduct.delete('preview');
      }
      ProductsApi.update(id, newProduct as unknown as IProduct)
        .then(() => {
          setSubmitting(false);
          navigate(ROUTES.SONG.LIST);
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    } else {
      if (artistId) {
        newProduct.set('artistId', artist._id);
      }
      if (galleryId || gallery._id) {
        newProduct.set('gallery', gallery._id);
      }
      ProductsApi.create(newProduct as unknown as IProduct)
        .then(() => {
          setSubmitting(false);
          navigate(ROUTES.VIDEO.LIST);
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    }
  };

  const handleClickBackBtn = () => {
    navigate(-1);
  };

  const handleDeleteConfirmed = () => {
    if (id) {
      ProductsApi.remove(id)
        .then(() => navigate(ROUTES.VIDEO.LIST))
        .catch((err) => console.log(err));
    }
  };


  const handleOpenPreviewDialog = () => {
    setVisiblePreviewDialog(true);
  };

  const handleClosePreviewDialog = () => {
    setVisiblePreviewDialog(false);
  };

  return (
    <Fragment>
      <Card sx={{ overflow: 'visible' }}>
        {id && !product ? (
          <Box height={300} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Fragment>
            <CardHeader
              title={
                <Button variant="text" size="medium" startIcon={<ArrowLeftIcon />} onClick={handleClickBackBtn}>
                  Back
                </Button>
              }
              action={
                <Button startIcon={<EyeIcon />} onClick={handleOpenPreviewDialog}>
                  Preview
                </Button>
              }
              sx={{ mb: 24 }}
            />
            <Typography mb={72} color="text.secondary" variant="title">
              {id ? 'Edit' : 'Add new'} video
            </Typography>
            <Formik
              enableReinitialize
              initialValues={product || initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, handleBlur, handleChange, setFieldValue, values, errors, touched, isSubmitting }) => {
                return (
                  <Form onSubmit={handleSubmit}>
                    <VideoForm artist={artist} product={product} handleBlur={handleBlur} handleChange={handleChange} setFieldValue={setFieldValue} values={values} errors={errors} touched={touched} isSubmitting={isSubmitting} setArtist={setArtist} setProduct={setProduct} gallery={gallery} setGallery={setGallery} />
                  </Form>
                );
              }}
            </Formik>
          </Fragment>
        )}
      </Card>
      {visibleDeleteConfirmDialog && (
        <ConfirmDialog
          description="Are you sure to delete?"
          visible={visibleDeleteConfirmDialog}
          setVisible={setVisibleDeleteConfirmDialog}
          onConfirmed={handleDeleteConfirmed}
        />
      )}
      {visiblePreviewDialog && product && (
        <PreviewDialog open={visiblePreviewDialog} product={product} onClose={handleClosePreviewDialog} />
      )}
    </Fragment>
  );
};
