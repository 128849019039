// Dependencies
import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { ThemeProvider as StyledProvider } from 'styled-components';

// App routes
import AppRoutes from './routes';

// Theme
import themes from './theme';

// Style
import './assets/styles/font.css';

// Create app
const App = () => {
  // Return app
  return (
    <ThemeProvider theme={themes[0]}>
      <StyledProvider theme={themes[0]}>
        <CssBaseline />
        <AppRoutes />
      </StyledProvider>
    </ThemeProvider>
  );
};

// Export app
export default App;
