// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Icons
import { ArrowLeftIcon, DocumentIcon, PublishIcon } from '../../../../assets/icons';

// Components
import { Dropzone, SwitchField } from '../../../../components';
import { ARTICLE_STATUS } from '../../../../shared/enums';
import { getObjectUrl } from '../../../../utils/common';
// Apis
import { ArticleApi } from '../../../../apis';
import { IArticle } from '../../../../shared/types';
import { REACT_APP_API_ASSETS_SERVER, ROUTES } from '../../../../constants';
import _ from 'lodash';

// Constants
const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required field!'),
  description: Yup.string().required('Required field!'),
  thumbnail: Yup.mixed().nullable().required('Required field!')
});

// Export new article page
export const NewArticlePage: FC = () => {
  // Get navigate from hook
  const [isLoading, setLoading] = useState(false);
  const [article, setArticle] = useState<IArticle | undefined>(undefined);

  const navigate = useNavigate();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  // Mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchProduct = () => {
    if (!id) return;

    setLoading(true);
    if (isLoading) {
      // fix warning error
    }
    ArticleApi.read(id)
      .then((res) => {
        setArticle(res.article);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchProduct();
  }, [location.pathname]);

  // Back to handler
  const handleBack = () => {
    navigate(-1);
  };

  // Create formik
  const {
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    enableReinitialize: true,
    validationSchema,
    initialValues: {
      title: article ? article.title : '',
      description: article ? article.description : '',
      isFeatured: article ? article.isFeatured : false,
      thumbnail: article ? article.thumbnail : null
    },
    onSubmit: (values) => {
      const ticket = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        // @ts-ignore
        ticket.append(key, value);
      });

      if (id && article) {
        if (_.isEqual(article?.thumbnail, values.thumbnail)) {
          ticket.delete('thumbnail');
        }
        ArticleApi.update(id, ticket as unknown as IArticle)
          .then(() => {
            setSubmitting(false);
            navigate(ROUTES.DOCUMENT.ARTICLE.LIST);
          })
          .catch((err) => {
            console.log(err);
            setSubmitting(false);
          });
      } else {
        ArticleApi.create(ticket as unknown as IArticle)
          .then((res) => {
            navigate(ROUTES.DOCUMENT.ARTICLE.LIST);
            console.log(res);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    }
  });

  // Image upload handler
  const handleImageUpload = (files) => {
    setFieldValue('thumbnail', files[0]);
  };

  // Publish handler
  const handlePublish = () => {
    setFieldValue(
      'status',
      article?.status == ARTICLE_STATUS.PUBLISHED ? ARTICLE_STATUS.UNPUBLISHED : ARTICLE_STATUS.PUBLISHED
    );
    handleSubmit();
  };

  // Save as draft handler
  const handleSave = () => {
    setFieldValue('status', ARTICLE_STATUS.DRAFT);
    handleSubmit();
  };

  // Return new article page
  return (
    <Card>
      <CardHeader
        title={
          <Button variant="text" size="medium" startIcon={<ArrowLeftIcon />} onClick={handleBack}>
            Back
          </Button>
        }
        action={
          <Stack direction="row" spacing={16}>
            {article?.status != ARTICLE_STATUS.PUBLISHED &&
            article?.status != ARTICLE_STATUS.UNPUBLISHED &&
            (isMobile ? (
              <IconButton onClick={handleSave}>
                <DocumentIcon />
              </IconButton>
            ) : (
              <Button variant="outlined" startIcon={<DocumentIcon />} onClick={handleSave}>
                Save as draft
              </Button>
            ))}
            {isMobile ? (
              <IconButton onClick={handlePublish}>
                <PublishIcon />
              </IconButton>
            ) : (
              <Button variant="contained" startIcon={<PublishIcon />} onClick={handlePublish}>
                {article?.status == ARTICLE_STATUS.PUBLISHED ? 'Unpublished' : 'Publish'}
              </Button>
            )}
          </Stack>
        }
        sx={{ mb: 24 }}
      />
      <Typography mb={72} color="text.secondary" variant="title">
        {id ? 'Edit' : 'Add new'} article
      </Typography>
      <Grid container rowSpacing={20} columnSpacing={56}>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth error={!!(errors.thumbnail && touched.thumbnail)}>
            {/* @ts-ignore */}
            <InputLabel variant="alone">THUMBNAIL</InputLabel>
            <Dropzone
              label="Drag image here to upload"
              accept={['.png', '.jpg', '.svg']}
              preview={
                values.thumbnail?.fieldname
                  ? `${REACT_APP_API_ASSETS_SERVER}/${values.thumbnail?.fieldname}/${values.thumbnail?.filename}`
                  : values.thumbnail
                    ? getObjectUrl(values.thumbnail)
                    : false
              }
              onDrop={handleImageUpload}
            />
            {!!(errors.thumbnail && touched.thumbnail) && <FormHelperText>{String(errors.thumbnail)}</FormHelperText>}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            name="title"
            label="Title"
            value={values.title}
            error={!!(errors.title && touched.title)}
            helperText={errors.title && touched.title && String(errors.title)}
            disabled={isSubmitting}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            {/* @ts-ignore */}
            <InputLabel variant="alone">Status</InputLabel>
            <SwitchField
              value={values.isFeatured}
              disabled={isSubmitting}
              onBlur={handleBlur}
              checked={values.isFeatured}
              onChange={(e) => {
                setFieldValue('isFeatured', (e as React.ChangeEvent<HTMLInputElement>).target.checked);
              }}
            />
            {!!(errors.isFeatured && touched.isFeatured) && (
              <FormHelperText>{String(errors.isFeatured)}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth error={!!(errors.description && touched.description)}>
              <TextField
                fullWidth
                name="description"
                label="TEXT"
                value={values.description}
                error={!!(errors.description && touched.description)}
                helperText={errors.description && touched.description && String(errors.description)}
                disabled={isSubmitting}
                onChange={handleChange}
                multiline={true}
                minRows={8}
                onBlur={handleBlur}
              />
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
};
