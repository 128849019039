// Dependencies
import { FC, ReactNode } from 'react';
import { Box, Card, CardHeader, Stack, Typography } from '@mui/material';

// Styles
import * as S from './styles';
import { ArrowDownIcon, ArrowUpIcon } from '../../../assets/icons';

// Interfaces
interface IDisplayProps {
  title: string;
  value: string;
  percent: number;
  progress?: ReactNode;
}

// Create display
const Display: FC<IDisplayProps> = ({ title, value, percent, progress }) => {
  // Return display
  return (
    <Card>
      <CardHeader title={title} sx={{ mb: 16 }} />
      <Typography color={percent > 0 ? 'success.main' : 'error'} variant="display">
        {value}
      </Typography>
      <S.Footer>
        <S.Progress>{progress}</S.Progress>
        <Stack spacing={14} direction="row">
          <Box color={percent > 0 ? 'success.main' : 'error.main'}>
            {percent > 0 ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </Box>
          <Typography variant="subtitle" color="text.secondary">
            {(Math.abs(percent) * 100).toFixed(0)}%
          </Typography>
        </Stack>
      </S.Footer>
    </Card>
  );
};

// Export display
export default Display;
