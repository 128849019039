// Dependencies
import React, { FC, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Card,
  FormControl,
  Button,
  Stack,
  CircularProgress,
  TextField,
  MenuItem,
  InputLabel,
  FormHelperText,
  CardHeader,
  Typography,
  Divider,
  Select,
  OutlinedInput,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItemText,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

// Components
import { ConfirmDialog, DateRangePicker, Dropzone, SwitchField, PreviewDialog } from '../../../components';

// Apis
import { CategoriesApi, ProductsApi } from '../../../apis';

// Types
import { ICategory, IFile, IProduct } from '../../../shared/types';
import { CURRENCY, PRODUCT_TYPE } from '../../../shared/enums';

// Icons
import { ArrowLeftIcon, EyeIcon } from '../../../assets/icons';

// Constants
import { REACT_APP_API_ASSETS_SERVER, ROUTES } from '../../../constants';
import Template1 from '../../../assets/tamplates/template1';
import Template2 from '../../../assets/tamplates/template2';
import Template3 from '../../../assets/tamplates/template3';
import Template4 from '../../../assets/tamplates/template4';
import Template5 from '../../../assets/tamplates/template5';

const components = [
  {
    value:'Template4',
    label:'Template 1',
    Comp: Template4,
    props: {
    }
  },
  {
    value:'Template5',
    label:'Template 2',
    Comp: Template5,
    props: {
    }
  },
  {
    value:'Template2',
    label:'Template 3',
    Comp: Template2,
    props: {
    }
  },
  {
    value:'Template1',
    label:'Template 4',
    Comp: Template1,
    props: {
    },
  },
  {
    value:'Template3',
    label:'Template 5',
    Comp: Template3,
    props: {
    }
  },
];

const initialValues: IProduct = {
  type: PRODUCT_TYPE.VIRTUAL_EVENT,
  name: '',
  thumbnail: null as unknown as IFile,
  icon: null as unknown as IFile,
  category: [],
  description: '',
  price: 0,
  currency: CURRENCY.DOLLAR,
  sku: '',
  statement: '',
  isFeatured: false,
  location: '',
  attenders: []
};

const validationSchema = Yup.object().shape({
  name: Yup.string().max(50, 'Please enter less than 50 characters').required('Name is required!'),
  // thumbnail: Yup.object().required('Thumbnail is required!'),
  category: Yup.array().required('Category is required!').min(1, 'Please select at least one of category'),
  price: Yup.number().min(0).required('Price is required!'),
  description: Yup.string().required('Description is required!'),
  currency: Yup.string()
    .matches(/([€$])/, 'Please select $ or €!')
    .required('Currency is required!'),
  location: Yup.string().max(50, 'Please enter less than 50 characters').required('Name is required!'),
  startTime: Yup.date().required('Start time is required'),
  endTime: Yup.date().required('End time is required')
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

// Export new event page
export const NewEventPage: FC = () => {
  const [product, setProduct] = useState<IProduct | undefined>(undefined);
  const [icon, setIcon] = useState<string | any>('');
  const [isLoading, setLoading] = useState(false);
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [visiblePreviewDialog, setVisiblePreviewDialog] = useState<boolean>(false);
  const [svg, setSvg] = useState<string>();
  const [svgBlob, setSvgBlob] = useState<any>();
  const [value, setValue] = React.useState('Template4');

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  // Mobile
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchProduct = () => {
    if (!id) return;
    if (isLoading) {
      // add your code
    }
    setLoading(true);
    ProductsApi.read(id)
      .then((res) => {
        const categoryIds = res.product.category.map((cat) => cat.id);
        setProduct({ ...res.product, category: categoryIds });
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  function getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      setIcon(reader.result);
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  useEffect(() => {
    fetchProduct();
    handleChangeRadio('Thumbnail1');
  }, [location.pathname]);

  useEffect(() => {
    CategoriesApi.readAll()
      .then((res) => {
        setCategories(res.categories);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (values: IProduct, { setSubmitting }: FormikHelpers<any>) => {
    const newProduct = new FormData();
    Object.entries(values).forEach(([key, value]) => {
      if (key === 'category') {
        value.forEach((cat) => newProduct.append(key, cat));
      } else if(key === 'thumbnail'){
        newProduct.append(key, svgBlob);
      }else {
        newProduct.append(key, value);
      }
    });
    newProduct.delete('next');

    if (id && product) {
      if (_.isEqual(product?.thumbnail, values.thumbnail)) {
        newProduct.delete('thumbnail');
      }
      if (_.isEqual(product?.mask_thumbnail, values.mask_thumbnail)) {
        newProduct.delete('mask_thumbnail');
      }
      ProductsApi.update(id, newProduct as unknown as IProduct)
        .then(() => {
          setSubmitting(false);
          navigate(ROUTES.EVENT.LIST);
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    } else {
      ProductsApi.create(newProduct as unknown as IProduct)
        .then(() => {
          setSubmitting(false);
          navigate(ROUTES.EVENT.LIST);
        })
        .catch((err) => {
          console.log(err);
          setSubmitting(false);
        });
    }
  };

  const handleClickBackBtn = () => {
    navigate(-1);
  };

  const handleDeleteConfirmed = () => {
    if (id) {
      ProductsApi.remove(id)
        .then(() => navigate(ROUTES.EVENT.LIST))
        .catch((err) => console.log(err));
    }
  };
  const handleImageUpload = (files, field: string, setFieldValue: any) => {
    if (files && files.length > 0) {
      getBase64(files[0]);
      setFieldValue(field, files[0]);
    }
  };

  const handleOpenPreviewDialog = () => {
    setVisiblePreviewDialog(true);
  };

  const handleClosePreviewDialog = () => {
    setVisiblePreviewDialog(false);
  };

  const handleCancel = () => {
    navigate(ROUTES.EVENT.LIST);
  };
  const getObjectUrl = (file) => {
    const url = URL.createObjectURL(file);
    return url.toString();
  };
  const renderSelectedCategories = (selected) => {
    const selectedCategoriesName = selected.map((cat) => {
      const category = categories.find((c) => (typeof cat === 'string' ? c.id === cat : c.id === cat.id));
      return category?.name;
    });

    return selectedCategoriesName.join(', ');
  };

  const handleChangeRadio = async ( value: string) => {
    setValue(value);
    const svg = document.getElementById(value);
    // @ts-ignore
    const data = (new XMLSerializer()).serializeToString(svg);
    const svgBlobData = new Blob([data], {
      type: 'image/svg+xml'
    });
    const url = URL.createObjectURL(svgBlobData);
    setSvg(url);
    setSvgBlob(svgBlobData);
  };

  return (
    <>
      <Card sx={{ overflow: 'visible' }}>
        {id && !product ? (
          <Box height={300} display="flex" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <>
            <CardHeader
              title={
                <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleClickBackBtn}>
                  Back
                </Button>
              }
              action={
                <Button startIcon={<EyeIcon />} onClick={handleOpenPreviewDialog}>
                  Preview
                </Button>
              }
              sx={{ mb: 24 }}
            />
            <Typography mb={72} color="text.secondary" variant="title">
              {id ? 'Edit' : 'Add new'} event ticket
            </Typography>
            <Formik
              initialValues={product || initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ handleSubmit, handleBlur, handleChange, setFieldValue, values, errors, touched, isSubmitting }) => (
                <Form onSubmit={handleSubmit}>
                  <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={24}>
                    <Stack spacing={16} sx={{ flex: 1 }}>
                      <TextField
                        fullWidth
                        name="name"
                        label="Name"
                        disabled={isSubmitting}
                        value={values.name}
                        error={!!(errors.name && touched.name)}
                        helperText={errors.name && touched.name && String(errors.name)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        fullWidth
                        multiline
                        minRows={5}
                        name="description"
                        label="Description"
                        disabled={isSubmitting}
                        value={values.description}
                        error={!!(errors.description && touched.description)}
                        helperText={errors.description && touched.description && String(errors.description)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
                        <InputLabel id="category">Category</InputLabel>
                        <Select
                          labelId="category"
                          id="category"
                          name="category"
                          multiple
                          fullWidth
                          value={values.category}
                          onChange={handleChange}
                          input={<OutlinedInput label="Category" />}
                          renderValue={(selected) => renderSelectedCategories(selected)}
                          MenuProps={MenuProps}
                          error={!!(errors.category && touched.category)}
                        >
                          {categories.map((category) => (
                            <MenuItem key={category.id} value={category.id}>
                              <ListItemText primary={category.name} />
                            </MenuItem>
                          ))}
                        </Select>
                        {errors.category && touched.category && (
                          <FormHelperText error>{String(errors.category)}</FormHelperText>
                        )}
                      </FormControl>
                      <Box>
                        <Grid container spacing={16}>
                          <Grid item xs={9}>
                            <TextField
                              fullWidth
                              name="price"
                              label="Price"
                              type="number"
                              disabled={isSubmitting}
                              value={values.price}
                              error={!!(errors.price && touched.price)}
                              helperText={errors.price && touched.price && String(errors.price)}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </Grid>
                          <Grid item xs={3}>
                            <TextField
                              select
                              fullWidth
                              name="currency"
                              label="Currency"
                              disabled={isSubmitting}
                              value={values.currency}
                              error={!!(errors.currency && touched.currency)}
                              helperText={errors.currency && touched.currency && String(errors.currency)}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            >
                              <MenuItem value="$">$</MenuItem>
                              <MenuItem value="€">€</MenuItem>
                            </TextField>
                          </Grid>
                        </Grid>
                      </Box>
                      <TextField
                        name="sku"
                        label="SKU"
                        fullWidth
                        value={values.sku}
                        error={!!(errors.sku && touched.sku)}
                        helperText={errors.sku && touched.sku && String(errors.sku)}
                        disabled={isSubmitting}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        fullWidth
                        multiline
                        minRows={5}
                        name="statement"
                        label="Statement"
                        disabled={isSubmitting}
                        value={values.statement}
                        error={!!(errors.statement && touched.statement)}
                        helperText={errors.statement && touched.statement && String(errors.statement)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <TextField
                        fullWidth
                        name="location"
                        label="Location"
                        value={values.location}
                        disabled={isSubmitting}
                        error={!!(errors.location && touched.location)}
                        helperText={errors.location && touched.location && String(errors.location)}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <Box>
                       <DateRangePicker/>
                      </Box>
                      <FormControl fullWidth>
                        {/* @ts-ignore */}
                        <InputLabel variant="alone">Status</InputLabel>
                        <SwitchField />
                      </FormControl>
                    </Stack>
                    <Box sx={{ flex: 1 }}>
                      <Grid container spacing={24}>
                        <Grid container spacing={24}>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl
                              fullWidth
                              error={!!(errors.thumbnail && touched.thumbnail)}
                              disabled={isSubmitting}
                            >
                              {/* @ts-ignore */}
                              <InputLabel variant="alone">Thumbnail</InputLabel>
                              <Dropzone
                                label="Drag image here to upload"
                                accept={['.png', '.jpg', '.svg']}
                                onDrop={(files) => handleImageUpload(files, 'thumbnail', setFieldValue)}
                                preview={
                                  values.thumbnail?.fieldname
                                    ? `${REACT_APP_API_ASSETS_SERVER}/${values.thumbnail?.fieldname}/${values.thumbnail?.filename}`
                                    : values.thumbnail
                                      ? getObjectUrl(values.thumbnail)
                                      : false
                                }
                              />
                              <FormHelperText>
                                {errors.thumbnail && touched.thumbnail && String(errors.thumbnail)}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} md={6} lg={6}>
                            <FormControl
                              fullWidth
                              error={!!(errors.icon && touched.icon)}
                              disabled={isSubmitting}
                            >
                              {/* @ts-ignore */}
                              <InputLabel variant="alone">ICON</InputLabel>
                              <Dropzone
                                label="Drag image here to upload"
                                accept={['.png', '.jpg', '.svg']}
                                onDrop={(files) => handleImageUpload(files, 'icon', setFieldValue)}
                                preview={
                                  values.icon?.fieldname
                                    ? `${REACT_APP_API_ASSETS_SERVER}/${values.icon?.fieldname}/${values.icon?.filename}`
                                    : values.icon
                                      ? getObjectUrl(values.icon)
                                      : false
                                }
                              />
                              <FormHelperText>
                                {errors.icon && touched.icon && String(errors.icon)}
                              </FormHelperText>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}  alignSelf={'center'}>
                          <FormControl>
                            <InputLabel variant="standard">Ticket Style</InputLabel>                          {/* @ts-ignore */}
                            <RadioGroup
                              style={{ border:'gray 2px dotted', borderRadius:15, padding:'5%' }}
                              value={value}
                              aria-labelledby="demo-radio-buttons-group-label"
                              onChange={(event, value) => handleChangeRadio(value)}
                            >
                              {components.map(({ Comp, label, value }, index) =>{
                                return (
                                  <Box width={'100%'}>
                                    <FormControlLabel label={label} value={value}  control={<Radio value={value}/>} />
                                    <Comp key={index} startTime={values.startTime} endTime={values.endTime}
                                          name={values.name.toUpperCase()} height={200} width={'100%'} base64={icon}
                                          location={values.location}/>
                                  </Box>
                                );
                              })}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                  <Box display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
                    <Stack direction="row" spacing={1}>
                      <Button variant="outlined" onClick={handleCancel}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        disabled={isSubmitting}
                        startIcon={isSubmitting && <CircularProgress size={20} />}
                      >
                        {product ? 'Save' : 'Create'}
                      </Button>
                    </Stack>
                  </Box>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Card>
      {visibleDeleteConfirmDialog && (
        <ConfirmDialog
          title={'Confirm Dialog'}
          description="Are you sure to delete?"
          visible={visibleDeleteConfirmDialog}
          setVisible={setVisibleDeleteConfirmDialog}
          onConfirmed={handleDeleteConfirmed}
        />
      )}
      {visiblePreviewDialog && product && (
        <PreviewDialog open={visiblePreviewDialog} product={product} svg={svg} onClose={handleClosePreviewDialog} />
      )}
    </>
  );
};
