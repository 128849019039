import { Box } from '@mui/material';
import styled from 'styled-components';

export const Header = styled(Box)`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: ${(props) => props.theme.spacing(5, 6)};
  border-bottom: 1px solid ${(props) => props.theme.palette.text.disabled};
`;

export const DetailList = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${(props) => props.theme.spacing(12, 0)};
`;

export const Content = styled(Box)`
  ${(props) => props.theme.typography.body1};
  color: ${(props) => props.theme.palette.text.secondary};
  padding: ${(props) => props.theme.spacing(5, 6)};
`;
