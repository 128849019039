// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../../../../../constants';
import {
    Avatar,
    Box,
    Button,
    Card,
    CardHeader,
    CircularProgress,
    Divider,
    FormControl,
    FormHelperText,
    Grid, IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Switch,
    TextField,
    Typography, useMediaQuery, useTheme
} from '@mui/material';
import { ArrowLeftIcon, CreateArtistIcon, EyeIcon, RecycleIcon, UpdateIcon } from '../../../../../assets/icons';

import { Form, Formik, FormikHelpers } from 'formik';
import { ConfirmDialog, Dropzone, SwitchField, IColumn, Table } from '../../../../../components';
import * as Yup from 'yup';
import { getObjectUrl } from '../../../../../utils/common';
import { GalleryApi, ProductsApi } from '../../../../../apis';
import { IFile, IGallery, Order } from '../../../../../shared/types';
import moment from 'moment';

const validationSchema = Yup.object().shape({
    name: Yup.string().max(50, 'Please enter less than 50 characters').required('Title is required!'),
    //   thumbnail: Yup.object().required('Thumbnail is required!'),
    description: Yup.string().required('Description is required!'),
    chain: Yup.string().required('Chain address is required!'),
    contractId: Yup.string().required('ContractId is required!'),
});

export const NewGalleryPage: FC = () => {
    const [gallery, setGallery] = useState<IGallery | any>({
        name: '',
        thumbnail: null as unknown as IFile,
        description: '',
        chain: 'ETH',
        contractId: '',
        isFeatured: false,
        artistId: ''
    });

    const [loading, setLoading] = useState<boolean>(false);
    const { artistId } = useParams<{ artistId: string }>();
    const [nfts, setNfts] = useState([]);
    const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);
    const [visibleDeleteAlertDialog, setVisibleDeleteAlertDialog] = useState<boolean>(false);
    const [selectedProductId, setSelectedProductId] = useState<string>();
    const [orderId, setOrderId] = useState<string>();
    const [pageNumber, setPageNumber] = useState<number>(0);
    // const [totalPage, setTotalPage] = useState<number>();
    const [order, setOrder] = useState<Order>(Order.Desc);
    const [orderBy, setOrderBy] = useState<string>('createdAt');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Get navigate from hook
    const navigate = useNavigate();
    // New handler
    const handleNew = () => {
        navigate(`/songs/new?artistId=${artistId}&galleryId=${galleryId}`);
    };

    // Routing handler
    const orderDetailHandler = () => {
        if (orderId) {
            navigate(ROUTES.SONG.DETAIL.replace(':id', orderId));
        }
    };

    // Delete handler
    const handleDelete = (id: string) => {
        setSelectedProductId(id);
        setVisibleDeleteConfirmDialog(true);
    };

    // Delete confirm handler
    const handleDeleteConfirmed = async () => {
        try {
            const res = await ProductsApi.remove(selectedProductId as string);
            if (res.orderId) {
                setVisibleDeleteAlertDialog(true);
                setOrderId(res.orderId);
            } else {
                // await fetchProducts();
            }
        } catch (error) {
            console.log(error);
            setVisibleDeleteAlertDialog(true);
        }
    };

    // Edit handler
    const handleEdit = (id: string) => {
        navigate(ROUTES.SONG.EDIT.replace(':id', id));
    };

    // Feature handler
    const handleFeature = (id: string, isFeature: boolean) => {
        ProductsApi.toggleFeature(id, isFeature)
            .then((res) => console.log(res))
            .catch((err) => console.log(err));
    };

    // Page change handler
    const handlePageChange = (pageN: number) => {
        setPageNumber(pageN);
    };

    // Sort handler
    const handleSort = (property: string) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? Order.Desc : Order.Asc);
        setOrderBy(property);
    };

    // View handler
    const handleView = (id: string, tokenId?: string) => {
        navigate(`/nfts/${id}?tokenId=${tokenId}`);
    };

    // Constant
    const columns: IColumn[] = [
        {
            title: 'thumbnail',
            render: (row) => (
                <Stack direction="row" spacing={12} alignItems="center">
                    <Avatar src={row.uri} alt="product-image" />
                </Stack>
            )
        },
        {
            field: 'details.chain',
            title: 'Chain',
            render: (row) => row.details.chain
        },
        {
            title: 'Featured',
            render: (row) => (
                <Switch
                    defaultChecked={row?.isFeatured}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleFeature(row?.id as string, e.target.checked)}
                />
            )
        },
        {
            field: 'createdAt',
            title: 'Create At',
            render: (row) => moment(row.createdAt).format('HH:mm - DD MMMM YYYY')
        },
        {
            field: 'updatedAt',
            title: 'Updated At',
            render: (row) => moment(row.updatedAt).format('HH:mm - DD MMMM YYYY')
        }
    ];
    const handleClickBackBtn = () => {
        navigate(-1);
    };

    const handleRemove = () => {
        GalleryApi.remove(galleryId as string)
            .then(() => navigate(`/artists/${artistId}/detail`))
            .catch(() => navigate(`/artists/${artistId}/detail`));
    };

    const handleSubmit = async (values, { setSubmitting }: FormikHelpers<any>) => {
        setSubmitting(true);
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append('thumbnail', values.thumbnail);
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('contractId', values.contractId);
            formData.append('chain', values.chain);
            formData.append('isFeatured', values.isFeatured);
            if (artistId) {
              formData.append('artistId', artistId);
            }
            if (artistId && galleryId) {
                GalleryApi.update(galleryId, formData)
                    .then((res) => {
                        console.log('res : ', res);
                        setLoading(false);
                        navigate('/artists');
                    })
                    .catch((err) => {
                        console.log(err);
                        setLoading(false);
                    });
            } else {
                console.log('=============> Creating,');

                await GalleryApi.create(formData);
                setSubmitting(false);
                navigate('/artists');
            }
        } catch (e) {
            console.log('error', e);
            setSubmitting(false);
            setLoading(false);
        }
    };

    const handleImageUpload = (files, field: string, setFieldValue: any) => {
        if (files && files.length > 0) {
            setFieldValue(field, files[0]);
        }
    };

    const handlePreview = () => {
        navigate(ROUTES.ARTIST.DETAIL.replace(':id', `${artistId}`));
    };

    // Mobile
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { galleryId } = useParams<{ galleryId: string }>();

    useEffect(() => {
        if (galleryId && artistId) {
            setIsLoading(true);
            GalleryApi.read(galleryId)
                .then((res) => setGallery(res.gallery))
                .catch((err) => console.log('error[gallery]:', err));
            GalleryApi.readAllNftsOfGallery(galleryId).then((res) => {
                setIsLoading(false);
                setNfts(res.nfts);
                // setTotalPage(res.totalPage);
            })
                .catch((err) => { setIsLoading(false); console.log(err); });
        }
    }, [galleryId, artistId]);
    return (
        <>
            <Card>
                {loading ? (
                    <Box height={300} display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : (
                    <Formik enableReinitialize initialValues={gallery} validationSchema={validationSchema} onSubmit={handleSubmit}>
                        {({ handleSubmit, handleBlur, handleChange, setFieldValue, values, errors, touched, isSubmitting }) => (
                            <Form onSubmit={handleSubmit}>
                                <CardHeader
                                    title={
                                        <Button variant="text" startIcon={<ArrowLeftIcon />} onClick={handleClickBackBtn}>
                                            Back
                                        </Button>
                                    }
                                    action={
                                        galleryId && artistId ? isMobile ?
                                            <Stack direction="row" spacing={16}>
                                                <IconButton onClick={handleRemove}><RecycleIcon /></IconButton>
                                                <IconButton type="submit"><UpdateIcon /></IconButton>
                                                <IconButton onClick={handlePreview}><EyeIcon /></IconButton>
                                            </Stack>
                                            :
                                            <Stack direction="row" spacing={16}>
                                                <Button variant="outlined" startIcon={<RecycleIcon />} onClick={handleRemove}>
                                                    Remove this gallery
                                                </Button>
                                                <Button variant="contained" disabled={isSubmitting} startIcon={loading ? <CircularProgress size={20} /> : <UpdateIcon />} type={'submit'}>
                                                    Update
                                                </Button>
                                                <Button variant="contained" startIcon={<EyeIcon />} onClick={handlePreview}>
                                                    Preview
                                                </Button>
                                            </Stack>
                                            : (
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    disabled={isSubmitting}
                                                    startIcon={isSubmitting ? <CircularProgress size={20} /> : <CreateArtistIcon />}
                                                >
                                                    Create this gallery
                                                </Button>
                                            )
                                    }
                                    sx={{ mb: 24 }}
                                />
                                <Typography mb={72} color="text.secondary" variant="title">
                                    {galleryId && artistId ? 'Edit' : 'Add new'} gallery
                                </Typography>
                                <Grid container>
                                    <Grid xs={10} sm={6} lg={3} sx={{ mb: 16 }}>
                                        <FormControl fullWidth error={!!(errors.thumbnail && touched.thumbnail)} disabled={isSubmitting}>
                                            {/* @ts-ignore */}
                                            <InputLabel variant="alone">Thumbnail</InputLabel>
                                            <Dropzone
                                                label="Drag image here to upload"
                                                accept={['.png', '.jpg', '.svg']}
                                                onDrop={(files) => handleImageUpload(files, 'thumbnail', setFieldValue)}
                                                preview={
                                                    typeof values.thumbnail == 'string' ?
                                                        values.thumbnail
                                                        : getObjectUrl(values.thumbnail)

                                                }
                                            />
                                            <FormHelperText>{errors.thumbnail && touched.thumbnail && String(errors.thumbnail)}</FormHelperText>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={24}>
                                    <Stack spacing={16} sx={{ flex: 1 }}>
                                        <TextField
                                            fullWidth
                                            name="name"
                                            label="Gallery Title"
                                            disabled={isSubmitting}
                                            value={values.name}
                                            error={!!(errors.name && touched.name)}
                                            helperText={errors.name && touched.name && String(errors.name)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <FormControl fullWidth>
                                            {/* @ts-ignore */}
                                            <InputLabel variant="alone">Status</InputLabel>
                                            <SwitchField
                                                value={values.isFeatured}
                                                disabled={isSubmitting}
                                                onBlur={handleBlur}
                                                checked={values.isFeatured}
                                                onChange={(e) => {
                                                    setFieldValue('isFeatured', (e as React.ChangeEvent<HTMLInputElement>).target.checked);
                                                }}
                                            />
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            multiline
                                            minRows={5}
                                            name="description"
                                            label="Description"
                                            disabled={isSubmitting}
                                            value={values.description}
                                            error={!!(errors.description && touched.description)}
                                            helperText={errors.description && touched.description && String(errors.description)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <FormControl fullWidth>
                                            <InputLabel id="demo-simple-select-label">SELECT BLOCKCHAIN</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={values.chain}
                                                label="Chain"
                                                onChange={(e) => {
                                                    setFieldValue('chain', (e as React.ChangeEvent<HTMLInputElement>).target.value);
                                                }}
                                            >
                                                <MenuItem value="ETH">ETH</MenuItem>
                                                <MenuItem value="CSPR">CSPR</MenuItem>
                                            </Select>
                                        </FormControl>
                                        <TextField
                                            fullWidth
                                            name="contractId"
                                            label="Contract ID"
                                            disabled={isSubmitting}
                                            value={values.contractId}
                                            error={!!(errors.contractId && touched.contractId)}
                                            helperText={errors.contractId && touched.contractId && String(errors.contractId)}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                    </Stack>
                                </Stack>
                            </Form>
                        )}
                    </Formik>
                )}
            </Card>
            {artistId && galleryId && nfts.length > 0 && <Card sx={{ marginTop: 20 }}>
                <Table
                    title="NFTS"
                    data={nfts}
                    columns={columns}
                    // totalPage={totalPage}
                    pageNumber={pageNumber}
                    onPageChange={handlePageChange}
                    order={order}
                    orderBy={orderBy}
                    isLoading={isLoading}
                    onSort={handleSort}
                    onNew={handleNew}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                    onView={handleView}
                />
                <ConfirmDialog
                    title={'Confirm Dialog'}
                    description="Are you sure to delete?"
                    visible={visibleDeleteConfirmDialog}
                    setVisible={setVisibleDeleteConfirmDialog}
                    onConfirmed={handleDeleteConfirmed}
                />
                <ConfirmDialog
                    title={'Alert'}
                    description="You can not remove the song. Because there is an order which is bind with the current song."
                    visible={visibleDeleteAlertDialog}
                    setVisible={setVisibleDeleteAlertDialog}
                    onConfirmed={orderDetailHandler}
                    confirmButtonText="Go to Order Details"
                    noButtonText="Close"
                />
            </Card>}

        </>
    );
};
