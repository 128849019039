// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PAGE_LIMIT, ROUTES } from '../../../../constants';

// Component
import { Gallery } from '../../../../components/Gallery';
import { GalleryApi } from '../../../../apis';

interface IArtistListProps {
  title?: string;
}

export const GalleryListPage: FC<IArtistListProps> = ({ title = 'Gallery' }) => {
  // States
  // const [isLoading, setLoading] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [galleryList, setGalleryList] = useState([]);
  const { id } = useParams<{ id: string }>();

  // Navigate
  const navigate = useNavigate();

  //useEffect
  useEffect(() => {
    if (id) {
      GalleryApi.readAll(id, {
        options: {
          limit: PAGE_LIMIT,
          skip: pageNumber * PAGE_LIMIT,
        }
      }).then(res => {
        setGalleryList(res.galleries);
      }).catch(error => console.log('Error ', error));
    }
  }, [id]);

  // Page change handler
  const handlePageChange = (pageN: number) => {
    setPageNumber(pageN);
  };

  // New handler
  const handleNew = () => {
    navigate(`/artists/${id}/gallery/new`);
  };

  const handleItemClick = (gallerId) => {
    navigate(`/artists/${id}/gallery/${gallerId}/detail`);
  };

  return (
    <>
      <Gallery
        title={title}
        data={galleryList}
        pageLimit={24}
        isLoading={false}
        totalPage={galleryList.length}
        pageNumber={pageNumber}
        onNew={handleNew}
        onPageChange={handlePageChange}
        onItemClick={handleItemClick}
        isGallery={true}
      />


    </>
  );
};
