import styled from 'styled-components';
import { Box } from '@mui/material';

export const Dropzone = styled(Box)<{ active: boolean, disabled?: boolean }>`
  && {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: ${(props) => (props.active ? 2 : 1)}px dashed
      ${(props) => (props.active ? props.theme.palette.success.main : props.theme.palette.text.secondary)};
    border-radius: 8px;
    padding: ${(props) => props.theme.spacing(0)};
    height: 378px;
    padding: ${(props) => props.theme.spacing(props.active ? 0 : 1)};
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: transparent;
    }

    .MuiTypography-root {
      color: ${props => props.disabled ? 'rgba(0, 0, 0, 0.26)': '#707070'}
    }
  }
`;
