// Dependencies
import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material';

import * as Yup from 'yup';

import { useFormik } from 'formik';
import * as S from './styles';

// Icon
import { ArrowLeftIcon, JpgIcon, PdfIcon, PublishIcon, SvgIcon } from '../../../../assets/icons';
import { useNavigate, useParams } from 'react-router-dom';

// Apis
import { TicketApi } from '../../../../apis';
import { ITicket } from '../../../../shared/types';

// Moment
import moment from 'moment';
import { ROUTES } from '../../../../constants';
import { TICKET_STATUS } from '../../../../shared/enums';

const validationSchema = Yup.object().shape({
  answer: Yup.string().required('Required field!')
});

// Export ticket details page
export const TicketDetailPage: FC = () => {
  // States
  const [ticket, setTicket] = useState<ITicket>();
  const [mimeType, setMimeType] = useState<any[]>([]);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  // Mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleSetMimeType = (ticket:ITicket) => {
    if(ticket) {
      const value = ticket?.files?.map((file) => {
        if(file.mimetype?.includes('svg')) {
          return '.SVG';
        }
        else if(file.mimetype?.includes('png')) {
          return '.PNG';
        }
        else if(file.mimetype?.includes('jpg')) {
          return '.JPG';
        }
        else if(file.mimetype?.includes('doc')) {
          return '.DOC';
        }
        else if(file.mimetype?.includes('pdf')) {
          return '.PDF';
        }
        else {
          return '.***';
        }
      });
      if( value ) {
        setMimeType(value);
      }
    }
  };

  const { values, errors, touched, submitForm, isSubmitting, handleChange, handleBlur } = useFormik({
    enableReinitialize:true,
    validationSchema,
    initialValues: {
      answer: ticket ? ticket.answer :'',
      status: ticket ? ticket.status : 'New'
    },
    onSubmit: (values) => {
      const newTicket = new FormData();
      Object.entries(values).forEach(([key, value]) => {
        if (key === 'status') {
          if (ticket?.status != TICKET_STATUS.ARCHIVE && ticket?.status != TICKET_STATUS.STARRED) {
            newTicket.append(key, 'Processing');
          } else {
            newTicket.append(key, ticket?.status);
          }
        } else {
          // @ts-ignore
          newTicket.append(key, value);
        }
      });
      if (id) {
        TicketApi.update(id, newTicket)
          .then((res) => {
            console.log(res);
            navigate(ROUTES.DOCUMENT.TICKET.LIST);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  });

  // On mounted
  useEffect(() => {
    if (id) {
      TicketApi.read(id)
        .then((res) => {
          console.log(res);
          setTicket(res.ticket);
          handleSetMimeType( res.ticket );
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  const handleClickBackBtn = () => {
    navigate(-1);
  };
  return (
    <Card>
      <CardHeader
        title={
          <Button variant="text" size="medium" startIcon={<ArrowLeftIcon />} onClick={handleClickBackBtn}>
            Back
          </Button>
        }
        action={
          <Button startIcon={<PublishIcon />} onClick={submitForm}>
            Publish
          </Button>
        }
        sx={{ mb: 23 }}
      />
      <Grid container columns={12} spacing={56}>
        <Grid item sm={6} xs={12}>
          <Stack spacing={20}>
            <S.Header>
              <Typography variant="title" color="text.secondary">
                Ticket subject is here
              </Typography>
              <Typography variant="overline" color="text.disabled">
                {ticket && moment(ticket.updatedAt).format('YYYY-MM-DD hh:mm:ss')}
              </Typography>
            </S.Header>
            <S.Content>{ticket?.description}</S.Content>
            <S.Header>
              <Typography variant="title" color="text.secondary">
                Additional details
              </Typography>
            </S.Header>
            <S.Content>
              <Stack direction="row" alignItems="centers" justifyContent="space-between">
                <Typography variant="subtitle" color="text.secondary">
                  Attachments:
                </Typography>
                <Stack direction="row" spacing={5}>
                  {ticket?.files?.map((file, index) => (
                    <Chip
                      key={index}
                      color="info"
                      label={isMobile ? mimeType[index] : `Attached File ${mimeType[index]}`}
                      avatar={
                        file?.mimetype === 'image/svg+xml' ? (
                          <SvgIcon />
                        ) : file.mimetype === 'image/jpg' ? (
                          <PdfIcon />
                        ) : (
                          <JpgIcon />
                        )
                      }
                    />
                  ))}
                </Stack>
              </Stack>
              <S.DetailList>
                <Typography variant="subtitle" color="text.secondary">
                  Category:
                </Typography>
                <Typography variant="body1" color="info.main">
                  {ticket?.category}
                </Typography>
              </S.DetailList>
              <S.DetailList>
                <Typography variant="subtitle" color="text.secondary">
                  Name:
                </Typography>
                <Typography variant="body1" color="info.main">
                  {ticket?.name}
                </Typography>
              </S.DetailList>
              <S.DetailList>
                <Typography variant="subtitle" color="text.secondary">
                  Email:
                </Typography>
                <Typography variant="body1" color="info.main">
                  {ticket?.email}
                </Typography>
              </S.DetailList>
              <S.DetailList>
                <Typography variant="subtitle" color="text.secondary">
                  D-Music username:
                </Typography>
                <Typography variant="body1" color="info.main">
                  {ticket?.name}
                </Typography>
              </S.DetailList>
              <S.DetailList>
                <Typography variant="subtitle" color="text.secondary">
                  Phone number:
                </Typography>
                <Typography variant="body1" color="info.main">
                  {ticket?.phoneNumber}
                </Typography>
              </S.DetailList>
              <S.DetailList>
                <Typography variant="subtitle" color="text.secondary">
                  Country located:
                </Typography>
                <Typography variant="body1" color="info.main">
                  {ticket?.country}
                </Typography>
              </S.DetailList>
            </S.Content>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            fullWidth
            multiline
            name="answer"
            label="ANSWER"
            rows={5}
            disabled={isSubmitting}
            value={values.answer}
            error={!!(errors.answer && touched.answer)}
            helperText={errors.answer && touched.answer && String(errors.answer)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
      </Grid>
    </Card>
  );
};
