import React, { FC, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import {
  Button,
  CardHeader,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { REACT_APP_API_ASSETS_SERVER, ROUTES } from '../../../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { ArrowRepeatIcon, DeleteIcon, UserAddIcon } from '../../../assets/icons';
import CardContent from '@mui/material/CardContent';
import { ConfirmDialog, Dropzone, SwitchField } from '../../../components';
import { useFormik } from 'formik';
import { getObjectUrl } from '../../../utils/common';
import { UsersApi } from '../../../apis';
import { IUser } from '../../../shared/types';

export const UserFormPage: FC = () => {
  const [user, setUser] = useState<IUser>();
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);

  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const fetchUser = () => {
    if (id) {
      UsersApi.read(id)
        .then((res) => {
          setUser(res.user);
        })
        .catch(() => {
          navigate(-1);
        });
    }
  };

  const handleBack = () => {
    navigate(ROUTES.USER.LIST);
  };

  const AccountFields = [
    {
      label: 'Name',
      name: 'name'
    },
    {
      label: 'Email',
      name: 'email'
    },
    {
      label: 'ID',
      name: 'id'
    },
    {
      label: 'Phone Number',
      name: 'phoneNumber'
    },
    {
      label: 'Paypal',
      name: 'paypal'
    }
  ];
  const AddressFields = [
    {
      label: 'Country',
      name: 'country',
      size: 1
    },
    {
      label: 'Region',
      name: 'region',
      size: 1
    },
    {
      label: 'City',
      name: 'city',
      size: 1
    },
    {
      label: 'Postal Code',
      name: 'postalCode',
      size: 1
    },
    {
      label: 'Street Name',
      name: 'streetName'
    },
    {
      label: 'Building Number',
      name: 'buildingNumber'
    },
    {
      label: 'Address Detail',
      name: 'addressDetail'
    }
  ];

  const {
    values,
    errors,
    touched,
    setFieldValue,
    isSubmitting,
    setSubmitting,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: user ? user.name : '',
      avatar: user ? user.avatar : null,
      status: user ? user.verify : false
    },
    onSubmit: (values) => {
      console.log(values);
    }
  });

  const handleImageUpload = (files, field: string, setFieldValue: any) => {
    if (files && files.length > 0) {
      setFieldValue(field, files[0]);
    }
  };

  const handleOpenConfirmDialog = () => {
    setVisibleDeleteConfirmDialog(true);
  };

  const handleDeleteConfirmed = () => {
    if (id) {
      UsersApi.remove(id)
        .then(() => navigate(ROUTES.USER.LIST))
        .catch((err) => {
          console.log(err);
        });
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      <Card>
        <CardHeader
          title={
            <Button variant="text" startIcon={<ArrowBack />} onClick={handleBack}>
              Back
            </Button>
          }
          action={
            id ? (
              <Stack direction="row" spacing={16}>
                <Button variant="outlined" disabled={isSubmitting} startIcon={<DeleteIcon />} onClick={handleOpenConfirmDialog}>
                  Remove this user
                </Button>
                <Button startIcon={<ArrowRepeatIcon />} disabled={isSubmitting} onClick={() => handleSubmit()}>
                  Update
                </Button>
              </Stack>
            ) : (
              <Button startIcon={<UserAddIcon />} disabled={isSubmitting} onClick={() => handleSubmit()}>
                Create this user
              </Button>
            )
          }
        />
        <CardContent>
          <Typography mb={72} color="text.secondary" variant="title">
            {id ? 'Edit' : 'Add new'} user
          </Typography>
          <Grid container mb={16}>
            <Grid item xs={12} sm={3}>
              <FormControl fullWidth error={!!(errors.avatar && touched.avatar)} disabled={isSubmitting} hiddenLabel>
                {/* @ts-ignore */}
                <InputLabel variant="alone">Profile Picture</InputLabel>
                <Dropzone
                  disabled={isSubmitting}
                  label="Drag image here to upload"
                  accept={['.png', '.jpg', '.svg']}
                  onDrop={(files) => handleImageUpload(files, 'avatar', setFieldValue)}
                  preview={
                    values.avatar?.fieldname
                      ? `${REACT_APP_API_ASSETS_SERVER}/${values.avatar?.fieldname}/${values.avatar?.filename}`
                      : values.avatar
                      ? getObjectUrl(values.avatar)
                      : false
                  }
                />
                <FormHelperText>{errors.avatar && touched.avatar && String(errors.avatar)}</FormHelperText>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={16}>
            <Grid item xs={12} sm={6}>
              <Stack spacing={16}>
                {AccountFields.map(({ label, name }, index) => (
                  <TextField
                    fullWidth
                    key={index}
                    name={name}
                    label={label}
                    value={values[name]}
                    error={!!(errors[name] && touched[name])}
                    helperText={errors[name] && touched[name] && String(errors[name])}
                    disabled={isSubmitting}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                ))}
                <FormControl fullWidth disabled={isSubmitting}>
                  {/* @ts-ignore */}
                  <InputLabel variant="alone">Status</InputLabel>
                  <SwitchField
                    value={values.status}
                    disabled={isSubmitting}
                    trueLabel="Active"
                    falseLabel="Inactive"
                    onBlur={handleBlur}
                    checked={values.status}
                    onChange={(e) => {
                      setFieldValue('status', (e as React.ChangeEvent<HTMLInputElement>).target.checked);
                    }}
                  />
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={16}>
                {AddressFields.map(({ label, name, size = 2 }, index) => (
                  <Grid item xs={12} sm={6 * size}>
                    <TextField
                      fullWidth
                      key={index}
                      name={name}
                      label={label}
                      value={values[name]}
                      error={!!(errors[name] && touched[name])}
                      helperText={errors[name] && touched[name] && String(errors[name])}
                      disabled={isSubmitting}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ConfirmDialog
        description="Are you sure to delete?"
        visible={visibleDeleteConfirmDialog}
        setVisible={setVisibleDeleteConfirmDialog}
        onConfirmed={handleDeleteConfirmed}
      />
    </>
  );
};
