import styled from 'styled-components';
import { Box, IconButton } from '@mui/material';

export const AudioPlayer = styled(Box)`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
`;

export const PlayButton = styled(IconButton)`
  && {
    width: 57px;
    height: 57px;
    border: 1px solid ${(props) => props.theme.palette.common.white};
    background-color: ${(props) => props.theme.palette.common.black};
    margin-left: 3px;
    svg {
      color: cyan;
    }
  }
`;

export const WaveSurfer = styled(Box)`
  margin-left: auto;
  flex: 1;
`;
