// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tabs,
  Typography
} from '@mui/material';
import { ArrowBack, Edit } from '@mui/icons-material';
import moment from 'moment';

// Apis
import { BalanceApi, CartApi, UsersApi, OrdersApi, TransactionsApi } from '../../../apis';

// Interfaces
import { IBalance, ICart, IOrder, IProduct, IUser } from '../../../shared/types';

// Constants
import { API_SERVER, ROUTES } from '../../../constants';
import { ITransaction } from '../../../shared/types/transaction.type';
import {
  InsertChartIcon,
  InsertChartOutlinedIcon,
  PlusIcon,
  TicketAltIcon,
  TicketAltOutlinedIcon,
  TokenIcon,
  TokenOutlinedIcon
} from '../../../assets/icons';

enum TAB {
  STATISTICS,
  PURCHASED_NFTS,
  PURCHASED_TICKETS
}

const USER_INFO_TABS = [
  {
    label: 'Statistics',
    value: TAB.STATISTICS,
    icon: {
      default: <InsertChartOutlinedIcon />,
      active: <InsertChartIcon />
    }
  },
  {
    label: 'Purchased NFTs',
    value: TAB.PURCHASED_NFTS,
    icon: {
      default: <TokenOutlinedIcon />,
      active: <TokenIcon />
    }
  },
  {
    label: 'Purchased Tickets',
    value: TAB.PURCHASED_TICKETS,
    icon: {
      default: <TicketAltOutlinedIcon />,
      active: <TicketAltIcon />
    }
  }
];

// Export UserDetail page
export const UserDetailPage: FC = () => {
  // States
  const [user, setUser] = useState<IUser>();
  const [favorites, setFavorites] = useState<IProduct[]>([]);
  const [balance, setBalance] = useState<IBalance>();
  const [orders, setOrders] = useState<IOrder[]>([]);
  const [cart, setCart] = useState<ICart>();
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [activeTab, setActiveTab] = useState<TAB>(TAB.STATISTICS);

  const { id } = useParams();
  const navigate = useNavigate();

  // Fetch User
  const fetchUser = () => {
    if (id) {
      UsersApi.read(id)
        .then((res) => {
          setUser(res.user);
        })
        .catch((err) => {
          console.log(err);
          navigate(-1);
        });
    }
  };

  // Fetch orders
  const fetchOrders = () => {
    OrdersApi.readAll({
      query: {
        userId: id
      }
    })
      .then((res) => {
        setOrders(res.orders);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch favorites
  const fetchFavorites = () => {
    UsersApi.getFavorites(id)
      .then((res) => {
        setFavorites(res.favoriteProducts);
      })
      .catch((err) => console.log(err));
  };

  // Fetch balances
  const fetchBalance = () => {
    BalanceApi.readAll({
      options: {
        id
      }
    })
      .then((res) => {
        console.log(res);
        setBalance(res.balance);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch cart
  const fetchCart = () => {
    CartApi.read({
      fingerprint: id as string
    })
      .then((res) => {
        setCart(res.cart);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Fetch transactions
  const fetchTransactions = () => {
    TransactionsApi.read({
      userId: id
    })
      .then((res) => {
        setTransactions(res.transactions);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Back handler
  const handleBack = () => {
    navigate(ROUTES.USER.LIST);
  };

  // Tab change handler
  const handleTabChange = (_: React.SyntheticEvent, tab: TAB) => {
    setActiveTab(tab);
  };

  const handleUpdateUserStatus = () => {
    if (id && user) {
      UsersApi.update(id, { verify: !user.verify })
        .then(() => {
          setUser({
            ...user,
            verify: !user.verify
          });
        })
        .catch((err) => console.log(err));
    }
  };

  const handleAddNew = () => {
    navigate(ROUTES.USER.NEW);
  };

  const handleEdit = () => {
    if (id) {
      navigate(ROUTES.USER.EDIT.replace(':id', id));
    }
  };

  // On user id changed
  useEffect(() => {
    if (id) {
      fetchUser();
      fetchCart();
      fetchOrders();
      fetchBalance();
      fetchFavorites();
      fetchTransactions();
    }
  }, [id]);

  return (
    <>
      <Stack spacing={8}>
        <Card>
          <CardHeader
            title={
              <Button variant="text" startIcon={<ArrowBack />} onClick={handleBack}>
                Back
              </Button>
            }
            action={
              <Stack direction="row" spacing={2}>
                <Button startIcon={<Edit />} onClick={handleEdit}>
                  Edit user profile
                </Button>
              </Stack>
            }
          />
          <CardContent>
            <Typography mb={72} color="text.secondary" variant="title">
              User info
            </Typography>
            <Stack mb={5} direction="column" alignItems="center">
              <Avatar sx={{ width: 200, height: 200, mb: 32 }} src={user?.avatarUrl} />
              <Typography mb={16} variant="headline">
                {user?.name}
              </Typography>
              <Typography variant="label" color="success.main">
                {user?.email}
              </Typography>
            </Stack>
            <Divider sx={{ mt: 16, mb: 30 }} />
            <Grid container spacing={8}>
              <Grid item xs={12} sm={5}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Status</TableCell>
                      <TableCell align="right">
                        <FormControlLabel
                          labelPlacement="start"
                          label={
                            <Typography variant="body1" mr={16} color={user?.verify ? 'success.main' : 'text.primary'}>
                              {user?.verify ? 'Active' : 'Temporarily Deactive'}
                            </Typography>
                          }
                          control={<Switch checked={user?.verify} onChange={handleUpdateUserStatus} />}
                        />
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Email</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main">{user?.email}</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Joined</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main">
                          {moment(user?.createdAt).format('HH:mm - DD MMMM YYYY')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>PayPal:</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main"></Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Phone Number:</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main">{user?.phoneNumber}</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={0} sm={2} />
              <Grid item xs={12} sm={5}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell>Country:</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main">Country Name Here</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Region:</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main">Region Name Here</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>City:</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main">City Name Here</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Postal Code:</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main">Postal Code Here</Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Address:</TableCell>
                      <TableCell align="right">
                        <Typography color="info.main">Address Here</Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card>
          <CardHeader
            title={
              <Tabs value={activeTab} onChange={handleTabChange}>
                {USER_INFO_TABS.map(({ label, value, icon }, index) => (
                  <Tab
                    key={index}
                    label={label}
                    value={value}
                    icon={value === activeTab ? icon.active : icon.default}
                  />
                ))}
              </Tabs>
            }
            action={
              <Button startIcon={<PlusIcon />} onClick={handleAddNew}>
                Add new
              </Button>
            }
          />
          <CardContent>
            <Stack height={300} justifyContent="center">
              <Typography variant="display" textAlign="center">
                In Progress
              </Typography>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </>
  );
};
