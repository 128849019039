// Dependencies
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';

// Icons
import { ArrowLeftIcon, PublishIcon } from '../../../../assets/icons';

// Constants
import { ROUTES } from '../../../../constants';

// Apis
import { FaqApi } from '../../../../apis';

// Styles
import * as S from './styles';
import { IFaqCategory, IFaqQuestion } from '../../../../shared/types';

// Constants
const validationSchema = Yup.object().shape({
  title: Yup.string().required('Required field!')
  // questions: Yup.array().required('Required field!').min(1, 'Please select at least one')
});

// Export new category page
export const NewFaqCategoryPage: FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<IFaqQuestion[]>([]);
  const [category, setCategory] = useState<IFaqCategory | undefined>(undefined);
  // Create form form hook
  const {
    values,
    errors,
    touched,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit
  } = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      title: category ? category.title : '',
      questions: category ? category.questions : ([] as any)
    },
    onSubmit: (values) => {
      if (id && category) {
        FaqApi.updateCategory(id, values)
          .then(() => {
            setSubmitting(false);
            navigate(ROUTES.DOCUMENT.FAQ.DASHBOARD);
          })
          .catch((err) => {
            console.log(err);
            setSubmitting(false);
          });
      } else {
        FaqApi.createCategory(values)
          .then(() => {
            navigate(ROUTES.DOCUMENT.FAQ.DASHBOARD);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setSubmitting(false);
          });
      }
    }
  });

  const { id } = useParams<{ id: string }>();
  // Get navigate from hook
  const navigate = useNavigate();

  // Fetch data
  const fetchQuestionData = () => {
    setIsLoading(true);
    if (isLoading) {
      // fix me
    }
    FaqApi.readQuestions()
      .then((res) => {
        setQuestions(res.faqQuestions);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  // Fetch questions
  const fetchCategory = () => {
    if (!id) return;
    setIsLoading(true);
    if (isLoading) {
      // fix warning error
    }
    FaqApi.readCategory(id)
      .then((res) => {
        setCategory(res.faqCategory);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    fetchQuestionData();
  }, []);

  useEffect(() => {
    fetchCategory();
  }, [location.pathname]);

  // Publish handler
  const handlePublish = () => {
    handleSubmit();
  };

  // Question change handler
  const handleChangeQuestion = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value;
    const item = questions.find((item) => item?.id === value);
    if (values.questions.includes(item?.id)) {
      setFieldValue(
        'questions',
        values.questions.filter((v) => v !== item?.id)
      );
    } else {
      setFieldValue('questions', [...values.questions, item?.id]);
    }
  };
  // Return new category page
  return (
    <Card>
      <CardHeader
        title={
          <Button
            variant="text"
            size="medium"
            startIcon={<ArrowLeftIcon />}
            component={Link}
            to={ROUTES.DOCUMENT.FAQ.DASHBOARD}
          >
            Back
          </Button>
        }
        action={
          <Button startIcon={<PublishIcon />} disabled={isSubmitting} onClick={handlePublish}>
            Publish
          </Button>
        }
        sx={{ mb: 23 }}
      />
      <Typography variant="title" color="text.secondary" sx={{ mb: 72 }}>
        {id ? 'Edit Faq Category' : 'Add new Faq Category' }
      </Typography>
      <Grid container spacing={56}>
        <Grid item sm={6} xs={12}>
          <Stack spacing={20}>
            <TextField
              fullWidth
              name="title"
              label="Category Title"
              disabled={isSubmitting}
              value={values.title}
              error={!!(errors.title && touched.title)}
              helperText={errors.title && touched.title && String(errors.title)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
            <FormControl error={!!(errors.questions && touched.questions)}>
              {/* @ts-ignore */}
              <InputLabel variant="alone">Question</InputLabel>
              <S.Group>
                {questions.map((item, index) => (
                  <S.ControlLabel
                    key={index}
                    checked={values.questions.includes(item?.id)}
                    control={<Checkbox onChange={handleChangeQuestion} />}
                    value={item.id}
                    label={item.title}
                  />
                ))}
              </S.Group>
              {errors.questions && touched.questions && <FormHelperText>{String(errors.questions)}</FormHelperText>}
            </FormControl>
          </Stack>
        </Grid>
        <Grid item sm={6} xs={12} />
      </Grid>
    </Card>
  );
};
