// Dependencies
import React, { FC, useEffect, useState } from 'react';

import moment from 'moment';
import { Box, Checkbox, IconButton, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';

// Components
import { ConfirmDialog, IColumn, Table } from '../../../../components';

// Types
import { ITicket, Order } from '../../../../shared/types';

// Icons
import {
  ArchiveIcon,
  ArchiveListIcon,
  ArchiveOutlinedIcon,
  CancelledIcon,
  CancelledOutlinedIcon,
  ProcessingIcon,
  ProcessingOutLinedIcon,
  RecycleIcon,
  SolvedIcon,
  SolvedOutLinedIcon,
  StarredFavorite,
  StarredIcon,
  StarredOutlinedIcon,
  TicketIcon,
  TicketOutlinedIcon
} from '../../../../assets/icons';

// Constants
import { TICKET_STATUS } from '../../../../shared/enums';

// Api
import { TicketApi } from '../../../../apis';

// Styles
import * as S from './styles';
import { useNavigate } from 'react-router-dom';
import { PAGE_LIMIT, ROUTES } from '../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { getArchieveBtnClicked, getDelBtnClicked, getFavBtnClicked } from '../../../../store/selectors';
import {
  setArchieveTicketBtnClicked,
  setDelTicketBtnClicked,
  setFavTicketBtnClicked
} from '../../../../store/actions/header.actions';

// Data
const TICKET_TABS = [
  {
    label: 'New Tickets',
    status: TICKET_STATUS.NEW,
    icon: {
      default: <TicketIcon />,
      active: <TicketOutlinedIcon />
    }
  },
  {
    label: 'Processing',
    status: TICKET_STATUS.PROCESSING,
    icon: {
      default: <ProcessingIcon />,
      active: <ProcessingOutLinedIcon />
    }
  },
  {
    label: 'Solved',
    status: TICKET_STATUS.SOLVED,
    icon: {
      default: <SolvedIcon />,
      active: <SolvedOutLinedIcon />
    }
  },
  {
    label: 'Cancelled',
    status: TICKET_STATUS.CANCELLED,
    icon: {
      default: <CancelledIcon />,
      active: <CancelledOutlinedIcon />
    }
  },
  {
    label: 'Starred',
    status: TICKET_STATUS.STARRED,
    icon: {
      default: <StarredIcon />,
      active: <StarredOutlinedIcon />
    }
  },
  {
    label: 'Archive',
    status: TICKET_STATUS.ARCHIVE,
    icon: {
      default: <ArchiveIcon />,
      active: <ArchiveOutlinedIcon />
    }
  }
];

export const TicketListPage: FC = () => {
  const [tickets, setTickets] = useState<ITicket[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>();
  const [order, setOrder] = useState<Order>(Order.Desc);
  const [orderBy, setOrderBy] = useState<string>('createdAt');
  const [ticketStatus, setTicketStatus] = useState(TICKET_STATUS.NEW);
  const [selectedRows, setSelectedRows] = useState<ITicket[]>([]);
  const [isCheckedAll, setCheckedAll] = useState(false);
  const [numSelected, setNumSelected] = useState<number>(0);
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);
  const [selectedProductId, setSelectedProductId] = useState<string>();

  // Get navigate form hook
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const favBtnClicked = useSelector(getFavBtnClicked);
  const archieveBtnClicked = useSelector(getArchieveBtnClicked);
  const delBtnClicked = useSelector(getDelBtnClicked);
  // Mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Constants
  const columns: IColumn[] = [
    {
      field: 'check',
      title: '',
      sort: false,
      render: (row) => (
        <Checkbox
          onClick={(e) => e.stopPropagation()}
          checked={selectedRows.some((item) => item.id === row.id)}
          onChange={() => handleChangeChecked(false, row)}
        />
      )
    },
    {
      title: '',
      render: (row) => (
        <>
          {isMobile ? (
            <></>
          ) : (
            <IconButton onClick={(e) => handleSatrred(e, row)} sx={{ padding: 0 }}>
              {row.status === TICKET_STATUS.STARRED ? <StarredFavorite /> : <StarredIcon />}
            </IconButton>
          )}
        </>
      ),
      sort: false
    },
    {
      field: 'subject',
      title: 'Subject'
    },
    {
      field: 'category',
      title: 'Category'
    },
    {
      title: 'Date',
      align: 'right',
      render: (row) => (
        <S.DateColumn>
          <Box>{moment(row.createdAt).format('YYYY.MM.DD - hh:mm:ss')}</Box>
          {isMobile ? (
            <></>
          ) : (
            <Box className="actions">
              <IconButton onClick={(e) => handleArchive(e, row)}>
                <ArchiveListIcon />
              </IconButton>
              <IconButton onClick={(e) => handleDelete(e, row.id)}>
                <RecycleIcon />
              </IconButton>
            </Box>
          )}
        </S.DateColumn>
      )
    }
  ];

  // Fetch data
  const fetchData = () => {
    return TicketApi.readAll({
      query: {
        status: ticketStatus
      },
      options: {
        limit: PAGE_LIMIT,
        skip: pageNumber * PAGE_LIMIT,
        sort: {
          [orderBy]: order
        }
      }
    })
      .then((res) => {
        setTickets(res.tickets);
        setTotalPage(res.pagination.total);
        setSelectedRows([]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Delete confirm handler
  const handleDeleteConfirmed = () => {
    TicketApi.remove(selectedProductId as string)
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  // Delete handler
  const handleDelete = (e, id: string) => {
    if (e) e.stopPropagation();
    setSelectedProductId(id);
    setVisibleDeleteConfirmDialog(true);
  };

  // Delete handler
  const handleMobileDelete = (id: string) => {
    setSelectedProductId(id);
    setVisibleDeleteConfirmDialog(true);
  };

  // change status handler
  const handleChangeStatus = (status, data) => {
    const newTicket = new FormData();
    Object.entries(data).forEach(([key, value]) => {
      if (key === 'status') {
        newTicket.append(key, status);
      } else {
        // @ts-ignore
        newTicket.append(key, value);
      }
    });

    TicketApi.update(data.id, newTicket)
      .then(() => fetchData())
      .catch((err) => console.log(err));
  };

  // Archive handler
  const handleArchive = (e, row) => {
    e.stopPropagation();
    handleChangeStatus(TICKET_STATUS.ARCHIVE, row);
  };

  // Archive handler
  const handleSatrred = (e, row) => {
    e.stopPropagation();
    const status = row.status != TICKET_STATUS.STARRED ? TICKET_STATUS.STARRED : TICKET_STATUS.PROCESSING;
    handleChangeStatus(status, row);
  };

  // Constants
  const handleChange = (event, newValue: TICKET_STATUS) => {
    setTicketStatus(newValue);
  };

  // Sort
  const handleSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? Order.Desc : Order.Asc);
    setOrderBy(property);
  };

  // Page change handler
  const handlePageChange = (value: number) => {
    setPageNumber(value);
  };
  const handleChangeChecked = (isChangeCheckedAll = false, row) => {
    const rows = [...selectedRows];
    let checkedCnt = numSelected;
    if (isChangeCheckedAll) {
      setCheckedAll(!isCheckedAll);
      if (!isCheckedAll) {
        setSelectedRows(tickets);
        checkedCnt = tickets.length;
      } else {
        setSelectedRows([]);
        checkedCnt = 0;
      }
    } else {
      if (selectedRows.some((item) => item.id === row.id)) {
        setCheckedAll(!isCheckedAll);
        setSelectedRows(rows.filter((v) => v !== row));
        checkedCnt--;
      } else {
        checkedCnt++;
        rows.push(row);
        setSelectedRows(rows);
      }
    }
    setNumSelected(checkedCnt);
  };

  // Row click handler
  const handleRowClick = (id: string) => {
    navigate(ROUTES.DOCUMENT.TICKET.DETAIL.replace(':id', id));
  };

  // On mounted
  useEffect(() => {
    fetchData();
  }, [pageNumber, order, orderBy]);

  // On ticket status changed
  useEffect(() => {
    fetchData();
  }, [ticketStatus]);

  useEffect(() => {
    const ticketsIdArray = selectedRows.map((item) => item.id);
    if (favBtnClicked) {
      if (ticketsIdArray) {
        // API call
        TicketApi.favoriteTickets({ ids: ticketsIdArray })
          .then(() => {
            fetchData();
          })
          .catch((err) => console.log(err));
      }
      dispatch(setFavTicketBtnClicked(false));
    }
    if (archieveBtnClicked) {
      if (ticketsIdArray) {
        // API call
        TicketApi.archieveTickets({ ids: ticketsIdArray })
          .then(() => {
            fetchData();
          })
          .catch((err) => console.log(err));
      }
      dispatch(setArchieveTicketBtnClicked(false));
    }
    if (delBtnClicked) {
      if (ticketsIdArray) {
        // API call
        TicketApi.deleteTickets({ ids: ticketsIdArray })
          .then(() => {
            fetchData();
          })
          .catch((err) => console.log(err));
      }
      dispatch(setDelTicketBtnClicked(false));
    }
  }, [favBtnClicked, archieveBtnClicked, delBtnClicked]);

  return (
    <>
      <Tabs value={ticketStatus} onChange={handleChange}>
        {TICKET_TABS.map((item, index) => (
          <Tab
            key={index}
            label={isMobile ? '' : item.label}
            value={item.status}
            icon={item.status === ticketStatus ? item.icon.active : item.icon.default}
          />
        ))}
      </Tabs>
      <S.TableWrapper>
        <Table
          title="Ticket"
          data={tickets}
          columns={columns}
          pageNumber={pageNumber}
          totalPage={totalPage}
          onPageChange={handlePageChange}
          order={order}
          orderBy={orderBy}
          onSort={handleSort}
          onRowClick={handleRowClick}
          onArchive={isMobile ? handleArchive : undefined}
          onDelete={isMobile ? handleMobileDelete : undefined}
          onView={isMobile ? handleRowClick : undefined}
          onFavorite={isMobile ? handleSatrred : undefined}
          selectedRows={selectedRows}
          numSelected={numSelected}
          OnChangeChecked={handleChangeChecked}
        />
        <ConfirmDialog
          description="Are you sure to delete?"
          visible={visibleDeleteConfirmDialog}
          setVisible={setVisibleDeleteConfirmDialog}
          onConfirmed={handleDeleteConfirmed}
        />
      </S.TableWrapper>
    </>
  );
};
