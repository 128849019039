export const SET_ACCOUNT = 'SET_ACCOUNT';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_FAIL = 'GET_ORDERS_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const SET_DASHBOARD_PERIOD = 'SET_DASHBOARD_PERIOD';
export const SET_TICKET_HEADER_FAV_BTN_CLICKED = 'SET_TICKET_HEADER_FAV_BTN_CLICKED';
export const SET_TICKET_HEADER_ARCHIEVE_BTN_CLICK = 'SET_TICKET_HEADER_ARCHIEVE_BTN_CLICK';
export const SET_TICKET_HEADER_DELETE_BTN_CLICK = 'SET_TICKET_HEADER_DELETE_BTN_CLICK';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
