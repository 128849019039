// Dependencies
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Card, CardHeader, Grid, Stack, Typography, useTheme } from '@mui/material';

const width = 360;
const height = 360;
const defaultSize = 360;
const defaultStrokeWidth = 100;
const defaultLineWidth1 = 55;
const defaultLineWidth2 = 65;

interface PaymentsTypeProps {
  paymentsTypeData: any[]
}

// Create payments type
const PaymentsType: FC<PaymentsTypeProps> = ({ paymentsTypeData }) => {
  // States
  const [dimension, setDimension] = useState({
    width,
    height
  });

  // Refs
  const chartRef = useRef<SVGSVGElement>(null);

  // Theme
  const theme = useTheme();

  // Calc ratio from svg dimension
  const ratio = useMemo(() => dimension.width / width, [dimension]);

  // Calc chart dimensions from ratio
  const size = useMemo(() => ratio * defaultSize, [ratio]);
  const strokeWidth = useMemo(() => ratio * defaultStrokeWidth, [ratio]);
  const lineWidth1 = useMemo(() => ratio * defaultLineWidth1, [ratio]);
  const lineWidth2 = useMemo(() => ratio * defaultLineWidth2, [ratio]);

  // Calc coordinate from svg dimension
  const coordinate = useMemo(
    () => ({
      x: dimension.width / 2,
      y: (height * ratio) / 2
    }),
    [dimension, ratio]
  );

  // Calc chart radius
  const outerRadius = size / 2;
  const innerRadius = outerRadius - strokeWidth;

  const percentData = paymentsTypeData.map(({ percent, ...rest }, index) => {
    const alpha = 359.95 * percent * (Math.PI / 180);
    const startAlpha =
      index === 0
        ? 0
        : 359.95 *
        paymentsTypeData
            .map(({ percent }) => percent)
            .filter((_, i) => i < index)
            .reduce((t = 0, n) => t + n) *
          (Math.PI / 180);
    const endAlpha = alpha + startAlpha;
    const middleAlpha = alpha / 2 + startAlpha;

    const startCoordinate1 = {
      x: coordinate.x + outerRadius * Math.sin(startAlpha),
      y: coordinate.y - outerRadius * Math.cos(startAlpha)
    };

    const endCoordinate1 = {
      x: coordinate.x + outerRadius * Math.sin(endAlpha),
      y: coordinate.y - outerRadius * Math.cos(endAlpha)
    };

    const startCoordinate2 = {
      x: coordinate.x + innerRadius * Math.sin(startAlpha),
      y: coordinate.y - innerRadius * Math.cos(startAlpha)
    };

    const endCoordinate2 = {
      x: coordinate.x + innerRadius * Math.sin(endAlpha),
      y: coordinate.y - innerRadius * Math.cos(endAlpha)
    };

    const lineStartCoordinate = {
      x: coordinate.x + outerRadius * Math.sin(middleAlpha),
      y: coordinate.y - outerRadius * Math.cos(middleAlpha)
    };

    const lineMiddleCoordinate = {
      x: coordinate.x + (outerRadius + lineWidth1 * Math.abs(Math.cos(middleAlpha))) * Math.sin(middleAlpha),
      y: coordinate.y - (outerRadius + lineWidth1 * Math.abs(Math.cos(middleAlpha))) * Math.cos(middleAlpha)
    };

    const isRight = lineMiddleCoordinate.x > coordinate.x;

    const lineEndCoordinate = {
      x: lineMiddleCoordinate.x + (isRight ? 1 : -1) * lineWidth2,
      y: lineMiddleCoordinate.y
    };

    return {
      alpha,
      startCoordinate1,
      endCoordinate1,
      startCoordinate2,
      endCoordinate2,
      lineStartCoordinate,
      lineMiddleCoordinate,
      lineEndCoordinate,
      isRight,
      ...rest
    };
  });

  // Init chart dimension
  const initializeSize = () => {
    if (chartRef.current) {
      const parent = chartRef.current.parentElement;

      if (parent) {
        setDimension({
          width: parent.clientWidth,
          height: parent.clientHeight
        });
      }
    }
  };

  // On mounted
  useEffect(() => {
    initializeSize();

    window.onresize = () => {
      initializeSize();
    };

    return () => {
      window.onresize = null;
    };
  }, []);

  // Return payments type
  return (
    <Card>
      <CardHeader title="Payments type" sx={{ mb: 24 }} />

      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flexDirection: 'column',

          [theme.breakpoints.up('sm')]: {
            flexDirection: 'row'
          }
        })}
      >
        <Grid container spacing={12}>
          {paymentsTypeData.map(({ label, percent, color }, index) => (
            <Grid item xs={12} lg={index % 2 === 0 ? 4 : 8} key={index}>
              <Stack spacing={4}>
                <Typography variant="overline" color={`${color}.main`}>
                  {label}
                </Typography>
                <Typography variant="headline" color={`${color}.main`}>
                  {percent ? percent.toFixed(2) * 100 : '0'}%
                </Typography>
              </Stack>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ flex: 1 }}>
          <svg ref={chartRef} width={dimension.width} height={height * ratio}>
            {percentData.map(
              ({ color, alpha, startCoordinate1, endCoordinate1, startCoordinate2, endCoordinate2 }, index) => (
                <path
                  key={index}
                  d={`M ${startCoordinate1.x} ${startCoordinate1.y} A ${outerRadius} ${outerRadius} 0, ${
                    alpha > Math.PI ? 1 : 0
                  }, 1, ${endCoordinate1.x} ${endCoordinate1.y} L ${endCoordinate2.x} ${
                    endCoordinate2.y
                  } A ${innerRadius} ${innerRadius} 0, ${alpha > Math.PI ? 1 : 0}, 0, ${startCoordinate2.x} ${
                    startCoordinate2.y
                  } L ${startCoordinate1.x} ${startCoordinate1.y} Z`}
                  strokeWidth={0}
                  stroke="red"
                  fill={theme.palette[color].main}
                />
              )
            )}
          </svg>
        </Box>
      </Box>
    </Card>
  );
};

// Export payments type
export default PaymentsType;
