// Dependencies
import React, { FC, useEffect, useState } from 'react';
import { Button, Card, Grid, IconButton, Stack, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

// Components
import { ConfirmDialog, IColumn, Table } from '../../../../components';

// Icons
import { DeleteIcon, EyeIcon, PencilIcon, DotIcon, PlusIcon } from '../../../../assets/icons';

// Constants
import { ROUTES } from '../../../../constants';

// Apis
import { FaqApi } from '../../../../apis';

// Types
import { IFaqCategory, IFaqQuestion } from '../../../../shared/types';

// Styles
import * as S from './styles';
import { DetailDialog } from '../../../../components/Dialog';

// Export faq page
export const FaqDashboardPage: FC = () => {
  // States
  const [categoryId, setCategoryId] = useState<string>('');
  const [categories, setCategories] = useState<IFaqCategory[]>([]);
  const [qsId, setQsId] = useState<string | undefined>();
  const [visibleDeleteConfirmDialog, setVisibleDeleteConfirmDialog] = useState<boolean>(false);
  const [questions, setQuestions] = useState<IFaqQuestion[]>([]);
  const [previewQuestion, setPreviewQuestion] = useState<IFaqQuestion>();
  const [curCategoryName, setCurCategoryName] = useState<string>();
  const [visiblePreviewDialog, setVisiblePreviewDialog] = useState(false);

  // Get navigate from hook
  const navigate = useNavigate();

  // Mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const category = categories.find(({ id }) => id === categoryId);
    if (category) {
      setQuestions(category.questions);
    }
  }, [categories, categoryId]);

  // Fetch categories
  const fetchCategories = () => {
    FaqApi.readCategories()
      .then((res) => {
        setCategories(res.faqCategories);
        setCategoryId(res.faqCategories[0].id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // Category click handler
  const handleClickCategory = (id: string) => () => {
    setCategoryId(id);
  };

  // Delete handler
  const handleDeleteConfirmed = () => {
    FaqApi.removeQuestion(qsId as string)
      .then(() => {
        setQuestions(questions.filter((item) => item.id !== qsId));
        setQsId(undefined);
      })
      .catch((err) => console.log(err));
  };

  // Delete handler
  const handleDelete = (id: string) => {
    setQsId(id);
    setVisibleDeleteConfirmDialog(true);
  };

  // Edit handler
  const handleEditQuestion = (id = '') => {
    navigate(ROUTES.DOCUMENT.FAQ.EDIT_QUESTION.replace(':id', id));
  };
  // Edit handler
  const handlePreviewQuestion = (id: string) => {
    questions.map((item) => {
      if (item.id === id) {
        setPreviewQuestion(item);
      }
    });
    categories.map((item) => {
      if (item.id == categoryId) {
        setCurCategoryName(item?.title);
      }
      return item;
    });
    setVisiblePreviewDialog(true);
  };

  const handleEditCategory = (id: string) => {
    navigate(ROUTES.DOCUMENT.FAQ.EDIT_CATEGORY.replace(':id', id));
  };

  // Define columns
  const categoryColumns: IColumn[] = [
    {
      title: 'Category',
      render: (row) => (
        <S.Category active={row.id === categoryId} onClick={handleClickCategory(row.id)}>
          <S.Circle />
          {row.title}
          <IconButton
            sx={{
              display: row.id == categoryId ? 'flex' : 'none',
              ml: 'auto',
              alignItems: 'center'
            }}
            onClick={() => handleEditCategory(row.id)}
          >
            <PencilIcon />
          </IconButton>
        </S.Category>
      )
    }
  ];

  const questionColumns: IColumn[] = [
    {
      title: 'Question',
      render: (row) => (
        <S.Question>
          <DotIcon />
          {row.title}
          {!isMobile && (
            <S.Actions direction="row" spacing={4}>
              <IconButton onClick={() => handleDelete(row.id)}>
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={() => handleEditQuestion(row.id)}>
                <PencilIcon />
              </IconButton>
              <IconButton onClick={() => handlePreviewQuestion(row)}>
                <EyeIcon />
              </IconButton>
            </S.Actions>
          )}
        </S.Question>
      )
    }
  ];

  // On mounted
  useEffect(() => {
    fetchCategories();
  }, []);

  // Return faq page
  return (
    <Card>
      <S.Header>
        <Typography variant="title" color="text.secondary">
          FAQ
        </Typography>
        <Stack direction="row" spacing={16}>
          <Button variant="outlined" startIcon={<PlusIcon />} component={Link} to={ROUTES.DOCUMENT.FAQ.NEW_CATEGORY}>
            Add new category
          </Button>
          <Button startIcon={<PlusIcon />} component={Link} to={ROUTES.DOCUMENT.FAQ.NEW_QUESTION}>
            Add new question
          </Button>
        </Stack>
      </S.Header>
      <Grid container spacing={16}>
        <Grid item xs={12} md={4}>
          <Table data={categories} pageLimit={5} isMobileDisabled={true} columns={categoryColumns} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Table
            data={questions}
            onView={isMobile ? handlePreviewQuestion : undefined}
            onDelete={isMobile ? handleDelete : undefined}
            onEdit={isMobile ? handleEditQuestion : undefined}
            columns={questionColumns}
          />
        </Grid>
      </Grid>
      <ConfirmDialog
        description="Are you sure to delete?"
        visible={visibleDeleteConfirmDialog}
        setVisible={setVisibleDeleteConfirmDialog}
        onConfirmed={handleDeleteConfirmed}
      />
      <DetailDialog
        title="Question"
        open={visiblePreviewDialog}
        onClose={() => setVisiblePreviewDialog(false)}
        actions={
          <>
            <Button variant="text" size="medium" onClick={() => setVisiblePreviewDialog(false)}>
              Cancel
            </Button>
            <Button variant="contained" size="medium" onClick={() => handleEditQuestion(previewQuestion?.id)}>
              Edit
            </Button>
          </>
        }
      >
        <>
          <Grid xs={6} spacing={12}>
            <S.PreviewContentItem>
              <Typography sx={{ fontWeight: 'bold' }} variant="h5">
                CategoryType:
              </Typography>
              <Typography sx={{ ml: 6 }} variant="h5">
                {curCategoryName}
              </Typography>
            </S.PreviewContentItem>
          </Grid>
          <Grid xs={12} spacing={12}>
            <S.PreviewContentItem>
              <Typography sx={{ fontWeight: 'bold' }} variant="h5">
                Title:
              </Typography>
              <Typography variant="h5" sx={{ ml: 6 }}>
                {previewQuestion?.title}
              </Typography>
            </S.PreviewContentItem>
          </Grid>
          <Grid xs={12}>
            <S.PreviewContentItem>
              <TextField
                fullWidth
                multiline
                minRows={5}
                name="description"
                label="Article"
                value={previewQuestion?.answer}
                disabled
              />
            </S.PreviewContentItem>
          </Grid>
        </>
      </DetailDialog>
    </Card>
  );
};
