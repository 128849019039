import { REACT_APP_API_ASSETS_SERVER } from '../constants';

// @ts-ignore
export const getThumbnailImage = (values: any) => {
  return `${REACT_APP_API_ASSETS_SERVER}/${values.thumbnail?.fieldname}/${values.thumbnail?.filename}`;
};

// @ts-ignore
export const getUserAvatar = (values: any) => {
  return `${REACT_APP_API_ASSETS_SERVER}/${values?.avatar?.fieldname}/${values?.avatar?.filename}`;
};

// @ts-ignore
export const getObjectUrl = (file: any) => {
  if (file) {
    const url = URL.createObjectURL(file);
    return url.toString();
  }
};
