import React, { FC, useState } from 'react';
import { Button, Card, CardHeader, Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import {
  GalleryIcon,
  GalleryOutlinedIcon,
  PlusIcon,
  SatisIcon,
  SatisOutLinedIcon,
  TokenIcon,
  TokenOutlinedIcon
} from '../../../../assets/icons';
import { ArtistDetailGalleryListPage } from './List';
import { CreateContractDialog } from '../SmartContract/Create';
import { useNavigate, useParams } from 'react-router-dom';

const profile_Tabs = [
  {
    label: 'NFTs',
    status: 'PROFILE_NFTS',
    icon: {
      default: <TokenOutlinedIcon />,
      active: <TokenIcon />
    }
  },
  {
    label: 'Galleries',
    status: 'PROFILE_GALLERY',
    icon: {
      default: <GalleryIcon />,
      active: <GalleryOutlinedIcon />
    }
  },
  {
    label: 'Smart Contracts',
    status: 'Smart_Contracts',
    icon: {
      default: <SatisIcon />,
      active: <SatisOutLinedIcon />
    }
  }
];

interface IArtistDetailGalleryProps {
  contractExecuted: () => void;
  isContractCreated: boolean | undefined;
}

export const ArtistDetailGalleryPage: FC<IArtistDetailGalleryProps> = ({ contractExecuted, isContractCreated }) => {
  // Mobile
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [profileState, setProfileStatus] = useState('PROFILE_NFTS');
  const [showContractModal, setShowContractModal] = useState<boolean>(false);


  // Get navigate from hook
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  // Constants
  const handleChange = (event, newValue) => {
    setProfileStatus(newValue);
  };
  // handle routing
  const handleNew = () => {
    if (profileState == 'Smart_Contracts') {
      setShowContractModal(true);
    }
    if (profileState == 'PROFILE_GALLERY') {
      navigate(`/artists/${id}/gallery/new`);
    }
    if (profileState == 'PROFILE_NFTS') {
      navigate(`/songs/new?artistId=${id}`);
    }
  };

  const handleClickOpen = () => {
    setShowContractModal(true);
  };

  const handleClose = () => {
    setShowContractModal(false);
  };

  // Constants
  // const handleClickNewGallery = () => {
  //   if (profileState == 'PROFILE_GALLERY') {
  //     navigate(ROUTES.ARTIST.NEW_GALLERY);
  //   } else {
  //     navigate(ROUTES.SONG.NEW);
  //   }
  // };

  return (
    <Card>
      <CardHeader
        title={
          <Tabs value={profileState} onChange={handleChange}>
            {profile_Tabs.map((item, index) => (
              <Tab
                key={index}
                label={isMobile ? '' : item.label}
                value={item.status}
                icon={item.status === profileState ? item.icon.active : item.icon.default}
              />
            ))}
          </Tabs>
        }
        action={
          <Button
            variant="contained"
            type="submit"
            startIcon={<PlusIcon />}
            onClick={handleNew}
          >
            Add new
          </Button>
        }
      />
      <ArtistDetailGalleryListPage isContractCreated={isContractCreated} galleryState={profileState} />
      {showContractModal && <CreateContractDialog showContractModal={showContractModal} contractExecuted={contractExecuted} handleClickOpen={handleClickOpen} handleClose={handleClose} />}
    </Card>
  );
};
