import { Box } from '@mui/material';
import styled from 'styled-components';

export const DRPContent = styled(Box)`
  background: white;
  border: 2px, solid, ${(props) => props.theme.palette.success.main};
`;

export const DateColumn = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const DateRangeContainer = styled(Box)``;

export const PanelContainer = styled(Box)`
  position: relative;
`;

export const MonthSelectContent = styled(Box)`
  padding: 24px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  svg {
    color: ${(props) => props.theme.palette.success.main};
  }
`;

export const TimeRangeContent = styled(Box)`
  display: flex;
  justify-content: space-between;
  &:first-child {
    border-right: 1px solid ${(props) => props.theme.palette.text.disabled};
  }
  width: 100%;
  padding: 0 24px;
  padding-top: 48px;
  input {
    text-align: center;
  }
`;

export const ActionContainter = styled(Box)`
  margin-top: 24px;
  padding-top: 24px;
  background: white;
  border-top: 1px solid ${(props) => props.theme.palette.text.disabled};
  width: 100%;
`;

export const PanelWrapper = styled(Box)`
  position: absolute;
  top: 10px;
  left: 0;
  padding: 30px;
  background: white;
  z-index: 999;
  box-shadow: 0 0.125em 0.25em rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: 2px solid ${(props) => props.theme.palette.success.main};
  width: 100%;

}
`;

export const DateRangePickerContent = styled(Box)`
  display: flex;
  justify-content: center;

  .rdrCalendarWrapper {
    width: 100%;
  }

  .rdrDateRangeWrapper {
    width: 100%;
  }
  .date-range-picker-calendar1 {
    &:first-child {
      border-right: 1px solid ${(props) => props.theme.palette.text.disabled}!important;
    }
  }

  .rdrMonth {
    width: 100%;
    height: 100%;
    padding: 0 24px;
    .rdrMonthName {
      display: none;
    }
    .rdrWeekDays {
      background: ${(props) => props.theme.palette.action.hover};
      border-radius: 8px;
    }
    .rdrDays {
      .rdrDay {
        padding: 0 5px !important;
        .rdrInRange {
          background: ${(props) => props.theme.palette.action.hover};
          border-radius: 28px;
          & ~ .rdrDayNumber span {
            color: ${(props) => props.theme.palette.common.black};
          }
          & ~ .rdrDayNumber span:after {
            background: ${(props) => props.theme.palette.common.black};
          }
        }
      }
      .rdrDayToday {
        .rdrDayNumber span:after {
          background: ${(props) => props.theme.palette.success.main};
        }
      }

      .rdrDayHovered {
        .rdrDayNumber span {
          color: ${(props) => props.theme.palette.common.white}!important;
        }
        .rdrDayNumber span:after {
          background: ${(props) => props.theme.palette.common.white}!important;
        }
      }
      .rdrDayInPreview {
        border-radius: 50px;
        background: ${(props) => props.theme.palette.action.hover};
        border-color: transparent;
        z-index: 0;
        & ~ .rdrDayNumber span {
          color: ${(props) => props.theme.palette.common.black}!important;
        }
        & ~ .rdrDayNumber span:after {
          background: ${(props) => props.theme.palette.common.black};
        }
      }
      .rdrStartEdge,
      .rdrEndEdge,
      .rdrDayStartPreview,
      .rdrDayEndPreview {
        background: ${(props) => props.theme.palette.success.main};
        border-radius: 50px;
        border-color: transparent;
        z-index: 0;
        color: ${(props) => props.theme.palette.common.white}!important;
        & ~ .rdrDayNumber span:after {
          background: ${(props) => props.theme.palette.common.white};
        }
      }
    }
  }
  .rdrMonthAndYearWrapper {
    display: none;
  }
  .rdrDateDisplayWrapper {
    display: none;
  }
`;
