// Dependencies
import { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Layouts
import { AuthLayout, FullLayout } from '../layouts';

// Constants
import { MAIN_ROUTES, AUTH_ROUTES, ROUTES } from '../constants';

// Store
import { getAccount } from '../store/selectors';
import { setAccount } from '../store/actions/auth.actions';

// Apis
import { AuthApi } from '../apis';

// Create app routes
const AppRoutes = () => {
  // States
  const [isLoading, setIsLoading] = useState<boolean>(true);

  // Get dispatch from hook
  const dispatch = useDispatch();

  // Get account from store
  const account = useSelector(getAccount);

  if (!account) {
    // Call me
    AuthApi.me()
      .then((res) => {
        if (res.user) {
          dispatch(setAccount(res.user));
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setIsLoading(false));
  }

  // Return app routes
  return isLoading ? (
    <Box flex={1} height="100vh" display="flex" alignItems="center" justifyContent="center">
      <CircularProgress />
    </Box>
  ) : (
    <Router>
      {!account ? (
        <Routes>
          {AUTH_ROUTES.map(({ path, Component }) => (
            <Route
              path={path}
              key={path}
              element={
                <AuthLayout>
                  <Component />
                </AuthLayout>
              }
            />
          ))}
          <Route path="*" element={<Navigate to={ROUTES.AUTH.SIGN_IN} />} />
        </Routes>
      ) : (
        <Routes>
          {MAIN_ROUTES.map(({ path, Component }) => (
            <Route
              path={path}
              key={path}
              element={
                <FullLayout>
                  <Component />
                </FullLayout>
              }
            />
          ))}
          <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
        </Routes>
      )}
    </Router>
  );
};

// Export app routes
export default AppRoutes;
