import { blue, grey } from '@mui/material/colors';

const darkVariant = {
  name: 'Dark',
  palette: {
    primary: {
      main: blue[800],
      contrastText: '#FFF'
    }
  },
  header: {
    color: '#FFF',
    background: '#FFF',
    search: {
      color: grey[800]
    }
  },
  sidebar: {
    color: '#FFF',
    background: '#0a1929',
    header: {
      color: '#FFF',
      background: '#0a1929',
      brand: {
        color: '#FFFFFF'
      }
    }
  },
  body: {
    background: '#F9F9FC'
  }
};

const lightVariant = {
  name: 'Light',
  palette: {
    error: {
      main: '#FC5454'
    },
    warning: {
      main: '#FBBD2D'
    },
    info: {
      main: '#30419B'
    },
    success: {
      main: '#02C58D'
    },
    text: {
      primary: '#000000',
      secondary: '#707070',
      disabled: '#E1E1E1'
    },
    action: {
      accent: '#C2E7FF',
      active: '#D3E3FD',
      hover: '#F2F6FC'
    },
    background: {
      default: '#F6F8FC'
    }
  },
  header: {
    color: '#FFF',
    background: '#013e35',
    search: {
      color: grey[800]
    }
  },
  sidebar: {
    color: '#FFF',
    background: '#013e35',
    header: {
      color: '#FFF',
      background: '#013e35',
      brand: {
        color: '#FFFFFF'
      }
    }
  },
  body: {
    background: '#F9F9FC'
  }
};

const variants = [lightVariant, darkVariant];

export default variants;
