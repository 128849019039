import styled from 'styled-components';
import { alpha, Box, Dialog } from '@mui/material';

export const PreviewDialog = styled(Dialog)`
  && {
    .MuiPaper-root {
      background-color: transparent;
      box-shadow: none;
    }
  }
`;

export const Content = styled(Box)`
  border-radius: 16px;
  min-height: 742px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => alpha(props.theme.palette.common.black, 0.8)};
`;

export const Song = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing(97)};
`;

export const Summary = styled(Box)`
  position: relative;
  height: 372px;
  z-index: 1;
  padding: 0 0 60px 175px;
  background-color: ${(props) => props.theme.palette.common.black};

  &::before {
    position: absolute;
    top: -97px;
    right: 102px;
    width: 408px;
    height: 408px;
    content: '';
    border-radius: 50%;
    z-index: -2;
    background-image: linear-gradient(to right, yellow, cyan);
  }

  &::after {
    position: absolute;
    top: -27.5px;
    right: 162px;
    width: 287px;
    height: 287px;
    content: '';
    border-radius: 50%;
    z-index: 1;
    background-image: linear-gradient(to right, cyan, magenta);
  }

  .audio-player {
    position: absolute;
    top: 46.5px;
    margin-left: ${(props) => props.theme.spacing(362)};
    width: calc(100% - 362px - 420px);
    z-index: 0;
  }
`;

export const SongName = styled.h2`
  font-family: 'GemunuLibre';
  color: lime;
  font-size: 53px;
  line-height: 70px;
  font-weight: bold;
  margin-top: ${(props) => props.theme.spacing(44)};
  padding-bottom: ${(props) => props.theme.spacing(16)};
  border-bottom: 1px solid ${(props) => props.theme.palette.common.white};
`;

export const Backdrop = styled(Box)`
  backdrop-filter: blur(30px);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const Thumbnail = styled.img`
  position: absolute;
  right: 142px;
  bottom: 0;
  z-index: 2;
  max-width: 475px;
`;

export const Category = styled.span`
  font-family: 'Segoe UI';
  color: white;
`;

export const Statement = styled.p`
  font-family: 'Segoe UI';
  color: ${(props) => props.theme.palette.common.white};
  font-size: 25px;
  line-height: 33px;
  margin: ${(props) => props.theme.spacing(0)};
`;

export const Information = styled(Box)`
  width: 100%;
  padding-left: ${(props) => props.theme.spacing(454)};
  padding-top: ${(props) => props.theme.spacing(40)};
`;

export const Sign = styled.img`
  margin-left: ${(props) => props.theme.spacing(478)};
  margin-top: ${(props) => props.theme.spacing(20)};
`;

export const Label = styled.h3`
  font-family: 'Segoe UI';
  font-weight: bold;
  font-size: 29px;
  line-height: 38px;
  color: ${(props) => props.theme.palette.common.white};
  margin: 0;
  padding-bottom: ${(props) => props.theme.spacing(12)};
  border-bottom: 1px solid ${(props) => props.theme.palette.common.white};
`;

export const Event = styled(Box)`
  background-image: url('/images/event-ticket-bg.png');
  background-repeat: no-repeat;
  max-width: 1118px;
  width: 100%;
  height: 279px;
  font-family: Teko;
  position: relative;
  color: ${(props) => props.theme.palette.common.white};
`;

export const EventName = styled.h2`
  font-size: 78px;
  line-height: 65px;
  width: 39%;
  margin: 10px 0 0 21%;
`;

export const EventDate = styled.span`
  font-size: 24px;
  line-height: 20px;
  margin: auto 0 0 21%;
  border-bottom: 15px;
  position: absolute;
  bottom: ${(props) => props.theme.spacing(15)};
`;

export const EventLocation = styled.span`
  writing-mode: vertical-lr;
  position: absolute;
  font-size: 22px;
  line-height: 30px;
  bottom: 0;
  left: 62.2%;
  transform: rotate(180deg);
  white-space: nowrap;
  height: 278px;
  overflow: hidden;
`;

export const EventThumbnail = styled.img`
  max-width: 231px;
  max-height: 231px;
  width: 100%;
  height: 100%;
  position: absolute;
  right: 6.2%;
  top: 8.25%;
`;
